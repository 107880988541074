import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useTrackingCategoryQuery = () => {
    const apiRequest = useApiRequest();

	//
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get_tracking_category}`);
        return data;
    }

	//
    const query = useQuery({
        queryKey: ['get_tracking_category'],
        queryFn: fetchQuery,
        onSuccess: (queryData) => queryData?.response?.results?.sort((a, b) => a.name?.localeCompare(b.name, 'en', {numeric: true}))
    });

	//
    return query;
}

export { useTrackingCategoryQuery };
