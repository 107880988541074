import { Button, InputNumber } from "antd";
import { useCardAutofundQuery } from "Hooks/Queries";
import { useCardAutofundCreateMutation, useCardAutofundUpdateMutation, useCardAutofundDelMutation } from "Hooks/Mutations";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { useCardAutofundState } from "Hooks/Store";
import CustomTable from "App/Components/CustomTable";
import { useQueryClient } from "@tanstack/react-query";
import { accountingFormat, numberFormat } from "Utils";

function CardAutoFund({ cardId, disabled = false }) {
  const editMode = useCardAutofundState(state => state.editMode);
  const setEditMode = useCardAutofundState(state => state.setEditMode);
  const setState = useCardAutofundState(state => state.setState);
  const amountBelow = useCardAutofundState(state => state.amountBelow);
  const amountLoad = useCardAutofundState(state => state.amountLoad);
  const amountLoadTo = useCardAutofundState(state => state.amountLoadTo);
  const amountBelowTxt = useCardAutofundState(state => state.amountBelowTxt());
  const amountLoadTxt = useCardAutofundState(state => state.amountLoadTxt());
  const amountLoadToTxt = useCardAutofundState(state => state.amountLoadToTxt());
  const autoloadType = useCardAutofundState(state => state.autoloadType);
  const initialData = useCardAutofundState(state => state.initialData);
  const validate = useCardAutofundState(state => state.validate);
  const resetCardAutofundState = useCardAutofundState(state => state.reset);

  const cardAutofundQuery = useCardAutofundQuery(cardId);

  const cardAutofundCreateMutation = useCardAutofundCreateMutation(payload => payload);
  const cardAutofundUpdateMutation = useCardAutofundUpdateMutation(payload => payload);
  const cardAutofundDelMutation = useCardAutofundDelMutation(payload => payload);

  const queryClient = useQueryClient();

  //
  useEffect(() => {
    if (cardAutofundQuery.data) {
      setState({
        amountBelow: cardAutofundQuery.data?.amountBelow,
        amountLoad: cardAutofundQuery.data?.amountLoad,
        amountLoadTo: cardAutofundQuery.data?.amountLoadTo,
        initialData: {
          amountBelow: cardAutofundQuery.data?.amountBelow,
          amountLoad: cardAutofundQuery.data?.amountLoad,
          amountLoadTo: cardAutofundQuery.data?.amountLoadTo,
        }
      });
    }

    return () => resetCardAutofundState();
    // eslint-disable-next-line
  }, [cardAutofundQuery.data]);

  //
  const initStateChanged = () => {
    return !isEqual(
      initialData,
      {
        amountBelow,
        amountLoad,
        amountLoadTo,
      }
    );
  }

  //
  const handleOnClick = () => {

    if (!validate()) {
      setState({
        amountBelow: initialData.amountBelow,
        amountLoad: initialData.amountLoad,
        amountLoadTo: initialData.amountLoadTo,
        autoloadType: initialData.autoloadType,
      });
    }

    if (initStateChanged()) {
      if (cardAutofundQuery?.data?.notSet) {
        create();
      } else {
        update();
      }
    }

    setEditMode(!editMode);
  }

  //
  const update = () => {
    if (validate()) {
      cardAutofundUpdateMutation.mutateAsync({
        autoloadId: cardAutofundQuery?.data?.id,
        cardId,
        payload: {
          autoloadTypeId: autoloadType,
          amountBelow: amountBelow,
          amountLoad: autoloadType === 1 ? amountLoad : amountLoadTo,
        }
      },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
          }
        });
    }
  }

  //
  const create = () => {
    if (validate()) {
      cardAutofundCreateMutation.mutateAsync({
        prepaid_card_id: cardId,
        autoload_type_id: autoloadType,
        amount_below: amountBelow,
        amount_load: autoloadType === 1 ? amountLoad : amountLoadTo,
      },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
          }
        });
    }
  }

  const destroy = () => {
    if (cardAutofundQuery?.data?.id) {
      cardAutofundDelMutation.mutateAsync({
        autoloadId: cardAutofundQuery?.data?.id,
        cardId,
      },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
          }
        });
    }
  }

  //
  const handleOnRemove = () => {
    setState({
      amountBelow: null,
      amountLoad: null,
      amountLoadTo: null,
    });

    if (!cardAutofundQuery?.data?.notSet)
      destroy();
  }

  //
  const handleOnInputChange = (val, key) => {
    if (key === 'amountLoadTo') {
      setState({ amountLoad: 0, autoloadType: 2 });
    }

    if (key === 'amountLoad') {
      setState({ amountLoadTo: 0, autoloadType: 1 });
    }

    setState({ [key]: val });
  };


  //
  const columns = [
    {
      title: "Trigger Amount",
      dataIndex: "triggerAmount",
      key: "triggerAmount",
    },
    {
      title: "Auto Fund by Amount",
      dataIndex: "autoFundByAmount",
      key: "autoFundByAmount",
    },
    {
      title: "Auto Fund to Total Value",
      dataIndex: "autoLoadToValue",
      key: "autoLoadToValue",
    },
    {
      title: "",
      dataIndex: "key_remove",
      key: "key_remove",
      render: () => (
        <Button type="danger" disabled={cardAutofundQuery?.data?.notSet || disabled} onClick={handleOnRemove}>
          Remove
        </Button>
      ),
      align: "right",
    },
    {
      title: "",
      dataIndex: "key",
      key: "action",
      render: () => (<>
        {editMode
          ? <Button type="primary" onClick={handleOnClick} disabled={disabled}>
            {editMode ? (!initStateChanged() || !validate() ? 'Cancel' : 'Save') : 'Edit'}
          </Button>
          : <Button type="primary" onClick={handleOnClick} disabled={disabled}>
            {editMode ? (!initStateChanged() || !validate() ? 'Cancel' : 'Save') : 'Edit'}
          </Button>
        }
      </>),
      align: "right",
    },
  ];

  //
  const dataSource = [
    {
      triggerAmount: !editMode
        ? <div style={{ width: '100%' }}>{amountBelowTxt || 'Not Set'}</div>
        : <InputNumber
          onChange={(val) => handleOnInputChange(numberFormat(val), 'amountBelow')}
          readOnly={!editMode}
          defaultValue={accountingFormat(amountBelow)}
          value={accountingFormat(amountBelow, false)}
          className="full-percent-width"
          placeholder="Enter Trigger Amount"
          formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
          parser={(val) => numberFormat(val)}
          min={0}
          controls={false}
        />,
      autoFundByAmount: !editMode
        ? <div style={{ width: '100%' }}>{amountLoadTxt || 'Not Set'}</div>
        : <InputNumber
          onChange={(val) => handleOnInputChange(numberFormat(val), 'amountLoad')}
          readOnly={!editMode}
          defaultValue={accountingFormat(amountLoad)}
          value={accountingFormat(amountLoad, false)}
          className="full-percent-width"
          placeholder="Enter Fund by Amount"
          formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
          parser={(val) => numberFormat(val)}
          min={0}
          controls={false}
        />,
      autoLoadToValue: !editMode
        ? <div style={{ width: '100%' }}>{amountLoadToTxt || 'Not Set'}</div>
        : <InputNumber
          onChange={(val) => handleOnInputChange(numberFormat(val), 'amountLoadTo')}
          readOnly={!editMode}
          defaultValue={accountingFormat(amountLoadTo)}
          value={accountingFormat(amountLoadTo, false)}
          className="full-percent-width"
          placeholder="Enter GBP Value"
          formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
          parser={(val) => numberFormat(val)}
          min={0}
          controls={false}
        />,
      key: 0,
    },
  ];

  //
  return (
    <CustomTable columns={columns} dataSource={dataSource} />
  );
}

export default CardAutoFund;
