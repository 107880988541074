import create from "zustand";

export const useStatementsReportsState = create((set, get) => ({
	selected_account_statement_ids: [],
	selected_expense_report_ids: [],
	modalVisible: false,
	selectedFilter: null,
	selectedFromMonth: null,
	selectedToMonth: null,
	loading: false,
	downloadModal: false,
	downloadUrl: null,
	clearFilters: false,
	currentPage: 1,
	limit: 10,
	offset: 0,
	setState: (data) => set(data),
}));
