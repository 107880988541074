import { Button, Col, Form, Image, Row, Spin, Typography, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logo from 'Assets/Images/logo.png';
import one from '../store/images/one.png';
import two from '../store/images/two.png';
import three from '../store/images/three.png';
import URLS from "Routes/constants";
import { useEffect, useMemo } from "react";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useLogout } from "../store";
import { useEmailConfirmedState } from "./store";
import { BsXCircle } from "react-icons/bs"

function EmailConfirmation() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const logout = useLogout();
  const loading = useEmailConfirmedState(state => state.loading);
  const success = useEmailConfirmedState(state => state.success);
  const setState = useEmailConfirmedState(state => state.setState);
  const apiRequest = useApiRequest();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const onLoad = async () => {
    try{
    if(query.get('key')){
      setState({ loading: true})
      const response = await apiRequest(APIROUTES.put.change_email_confirm, 'PUT', { key: query.get('key')});
      if(response?.response?.data?.error) {
        setState({ success: false})
        notification.error({ message: 'Something Went Wrong!', placement: 'top'})
      } else {
        setState({ success: true })
      }
    } else {
      navigate(URLS.Login)
    }
  } catch {
      notification.error({ message: 'Something Went Wrong!', placement: 'top'})
  } finally {
    setState({ loading: false})
  }
  }
  useEffect(() => {
    onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    loading ? (
    <Row justify="center" className="m-t-20">
      <Col>
          <Spin size="large" spinning />
      </Col>
  </Row>) :
    <Row className="full-height" align="middle" justify="center" style={{ overflow: 'hidden' }}>
      <Col xxl={6} xl={9} lg={12} md={12} sm={18} xs={22}>
        <img src={one} className='one' alt="1" />
        <img src={two} className='two' alt="2" />
        <img src={three} className='three' alt="3" />
          <Row justify="center" className="m-t-100">
            <Col className="m-t-100">
              <Image src={logo} alt='logo' preview={false} width={190} />
            </Col>
          </Row>
          <Row className="m-t-30" justify="center">
            <Col span={21}>
                  
                  {success ? <Row>
                    <Col>
                      <Form.Item>
                        <Typography.Text className='dark-green medium fs-15px'>Your New email address has now been confirmed. <br />
                        Pleaes click here to login to your Volopa account</Typography.Text>
                      </Form.Item>
                    </Col>
                  </Row>: <Row justify="center">
                    <Col>
                      <Form.Item>
                        <div style={{ textAlign : 'center', marginBottom: '10px', color: 'red'}}>
                        <BsXCircle fontSize={48} />
                        </div>
                        <Typography.Text className='dark-green medium fs-15px'>
                          Email Confirmation Failed! <br />
                        </Typography.Text>
                      </Form.Item>
                    </Col>
                  </Row>}
                <Row justify="center">
                  <Col>
                    <Button type="primary"
                        onClick={() => {
                          logout()
                          navigate(URLS.Login)
                        }}
                        className="right-align-text"
                      >
                        Login
                      </Button>
                  </Col>
                </Row>
            </Col>
          </Row>
      </Col>
    </Row>
  );
}

export default EmailConfirmation;
