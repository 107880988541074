import {
	Button,
	Col,
	Row,
	Typography,
} from "antd";

function UnFreezeCard({ onCancel = () => { }, onConfirm = () => { }, cardNumber }) {

	return (
		<>
			<Row>
				<Col>
					<Typography.Text className="muli semi-bold fs-18px dark-green">
						UNFREEZE CARD
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20">
				<Col>
					<Typography.Text className="muli semi-bold fs-16px dark-green">
						Are you sure you want to unfreeze Card *{cardNumber}?
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20" justify="space-between">
				<Col>
					<Button type="primary" onClick={onCancel}>
						Close
					</Button>
				</Col>
				<Col>
					<Button onClick={() => onConfirm(false)} type="danger">Unfreeze</Button>
				</Col>
			</Row>
		</>
	)
}

export default UnFreezeCard;
