/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, Image, Input, Row, Space, Typography, Popconfirm, notification, Pagination, Tooltip } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FaRegCalendarAlt, FaSyncAlt } from 'react-icons/fa';
import CustomTable from "App/Components/CustomTable";
import { FLAGS } from "Constants/Images";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useApiRequest } from "Hooks/API";
import { usePaymentHistoryState } from "App/Pages/Payments/PaymentHistory/store";
import { useEffect, useRef } from "react";
import debounce from 'lodash/debounce'
import moment from 'moment';
import { accountingFormat } from "Utils";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";
import { FORMATS } from "Constants/Formats";
import { useLoginStore } from "App/Pages/Login/store";
import { useCheckAccountSuspensionQuery } from "Hooks/Queries";

function PaymentHistory(props) {
    const setState = usePaymentHistoryState((state) => state.setState);
    const setnewPaymentState = useNewPaymentState((state) => state.setState);
    const paymentData = usePaymentHistoryState((state) => state.paymentData);
    const searchTermRef = useRef(null);
    const dateRef = useRef(null);
    const paymentsLoading = usePaymentHistoryState((state) => state.paymentsLoading);
    const currentPage = useRef(1);
    const totalPages = usePaymentHistoryState(state => state.totalPages);
    const limit = usePaymentHistoryState(state => state.limit);
    const apiRequest = useApiRequest();
    const config = useLoginStore(s => s.config);
    const navigate = useNavigate();
    const { data: accountStatus, isFetching: accountStatusFetching } = useCheckAccountSuspensionQuery();

    useEffect(() => {
        if (!!accountStatus?.suspended_accounts?.payments) {
            notification.destroy();
            notification.error({
                description: 'This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed.',
                placement: 'top'
            })
        }
    }, [accountStatus]);

    const datepickerChange = (date, dateString) => {
        // setState({ date: dateString });
        dateRef.current = dateString;
        getPaymentHistory();
    }

    const paginationStyles = {
        marginTop: '30px',
        marginBottom: '20px',
        padding: '8px 18px',
        background: '#eee',
        borderRadius: '8px'
    };


    const columns = [
        {
            title: <DatePicker bordered={false} className="pointer" suffixIcon={<FaRegCalendarAlt className="dark-green" size={18} />} style={{ width: '20px', padding: '0px' }} inputReadOnly onChange={datepickerChange} />,
            width: 20
        },
        {
            title: 'Submission Date',
            dataIndex: 'submissionDate',
            key: 'submissionDate',
            render: (text, obj) => <Typography.Text className="pointer" onClick={() => goToSpecificPaymentHistory(obj)}>{moment(text, 'DD-MM-YYYY').format(FORMATS.date)}</Typography.Text>
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            render: (text, obj) => <Typography.Text className="pointer" onClick={() => goToSpecificPaymentHistory(obj)} type={(text === 'Payment Complete' || text === 'Completed' || text === 'Payment Sent' || text === 'Funds Received - Pending Release') ? 'success' : 'danger'}>{text}</Typography.Text>
        },
        {
            title: 'Recipient',
            dataIndex: 'recipient',
            key: 'recipient',
            render: (text, obj) => <Typography.Text className="pointer" onClick={() => goToSpecificPaymentHistory(obj)}>{text}</Typography.Text>
        },
        {
            title: 'Amount sent to Volopa',
            dataIndex: 'amountSent',
            key: 'amountSent',
            align: 'left',
            render: (text, obj) => <Space onClick={() => goToSpecificPaymentHistory(obj)} className="pointer m-r-5">
                <div style={{ width: '20px' }}></div>
                {FLAGS[text.currency] && <Image src={FLAGS[text.currency]} alt={text.currency} preview={false} width={36} />}
                {accountingFormat(text.amount)}
                {text.currency}
            </Space>
        },
        {
            title: 'Amount paid to Recipient',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
            align: 'left',
            render: (text, obj) => <Space className="pointer" onClick={() => goToSpecificPaymentHistory(obj)} >
                <div style={{ width: '20px' }}></div>
                {FLAGS[text.currency] && <Image src={FLAGS[text.currency]} alt={text.currency} preview={false} width={36} />}
                {accountingFormat(text.amount)}
                {text.currency}
            </Space>
        },
        {
            dataIndex: 'key',
            key: 'action',
            render: (key, obj) =>
                <Tooltip
                    title="This account is currently frozen for administrative reasons. Our team will get in touch with you soon, if needed."
                    {...(!accountStatus?.suspended_accounts?.payments && { open: false })}
                >
                    <Popconfirm
                        title="Are you sure you want to repeat this transaction?"
                        placement="topRight"
                        onConfirm={() => confirmSubmit(obj)}
                        okText="Yes"
                        cancelText="No"
                        disabled={!!accountStatus?.suspended_accounts?.payments}
                    >
                        <Button
                            type='primary'
                            disabled={!!accountStatus?.suspended_accounts?.payments}
                        >
                            <Space align='start'>
                                <FaSyncAlt size={18} className='m-t-2' />
                                Repeat
                            </Space>
                        </Button>
                    </Popconfirm>
                </Tooltip>,
            align: 'right'
        }
    ];

    useEffect(() => {
        getPaymentHistory(limit);
    }, []);

    const confirmSubmit = (payment) => {
        setnewPaymentState({ recipient: payment.paymentData.tcc_beneficiary_full, resetRecipient: false, presetPayment: payment.paymentData, repeat: true, repeatFundingMethod: payment?.paymentData?.tcc_quote_b_b_self?.tcc_quote_b_b_self_beneficiary_map[0]?.fund_method_id });
        navigate(URLS.NewPayment);
    };

    const searchInputChanged = (event) => {
        // setState({ searchTerm: event.target.value });
        searchTermRef.current = event.target.value;
        getPaymentHistory(limit);
    };

    const getPaymentHistory = async (limit = 20) => {
        setState({ paymentsLoading: true });
        let requestUrl = 'tcc/payment/history?$with=All&accepted=1';
        if (dateRef.current) {
            requestUrl += '&fromDate=' + dateRef.current + ' 00:00:00&toDate=' + dateRef.current + ' 23:59:59';
        }
        if (searchTermRef.current) {
            requestUrl += '&search=' + searchTermRef.current;
        }
        if (props.recipientId) {
            requestUrl += '&recipientId=' + props.recipientId;
        }
        requestUrl += '&$pageCount&$skip=' + (currentPage.current - 1) * limit + '&$limit=' + limit;
        let response = await apiRequest(requestUrl);
        setState({ totalPages: Math.ceil(response.pager.count) });
        if (response && response.data) {
            response = response.data.map((val, key) => {
                let status = val?.client_facing_status ?? val.payment_status.option.slice(0, 1).toUpperCase() + val.payment_status.option.slice(1, val.payment_status.option.length);
                let temp = {
                    paymentData: val,
                    submissionDate: moment.utc(val.tcc_quote_b_b_self.accept_time).format('DD-MM-YYYY'),
                    paymentStatus: status === 'Paid' ? 'Payment Complete' : status,
                    recipient: val?.tcc_beneficiary?.name === 'undefined undefined' ? val?.tcc_beneficiary_full?.beneficiary_company_name : val?.tcc_beneficiary?.name,
                    amountSent: {
                        amount: val.tcc_quote_b_b_self.calc_client_send,
                        currency: val.tcc_quote_b_b_self.sell_currency
                    },
                    amountPaid: {
                        amount: val.beneficiary_amt,
                        currency: val.paidToRecipientCurrency = val.beneficiary_ccy
                    },
                    key: key
                };

                return temp;
            })
            setState({ paymentData: response });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading your payments history', placement: 'top' });
        }
        setState({ paymentsLoading: false });
    }

    const handlePageChange = (page, pageSize) => {
        currentPage.current = page;
        getPaymentHistory(pageSize);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    const handlePageSizeChange = (current, size) => {
        setState({
            limit: size,
            page: current
        });
        getPaymentHistory(size);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    const goToSpecificPaymentHistory = (record) => {
        navigate(`${URLS.SpecificPaymentHistory}/${record?.paymentData?.id}`);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Payment History' text='- Overview of previous International Payments' />
                </Col>
            </Row>
            <Row className="m-t-25" justify="space-between">
                <Col xxl={4}>
                    <Input.Search placeholder="Search" onChange={debounce(searchInputChanged, 1000)} />
                </Col>
                <Col>
                    <Row gutter={12}>
                        <Col>
                            <Button type="primary" onClick={() => navigate(URLS.DraftPayments)}>Draft Payments</Button>
                        </Col>
                        {config?.manual_payment_status === 1 &&
                            <Col>
                                <Button type="primary" onClick={() => navigate(URLS.ManualTradeHistory)}>
                                    Manual Trade/Payment History
                                </Button>
                            </Col>}
                        <Col>
                            <Button onClick={() => navigate(`${URLS.PaymentReports}`)} type="primary"> Payment Reports</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col span={24}>
                    <CustomTable
                        columns={columns}
                        dataSource={paymentData}
                        loading={paymentsLoading || accountStatusFetching}
                        rowClassName='medium fs-18px'
                    // onRow={(record, rowIndex) => ({ onClick: () => goToSpecificPaymentHistory(record) })}
                    />
                    <Pagination
                        style={paginationStyles}
                        onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                        onShowSizeChange={(current, size) => handlePageSizeChange(current, size)}
                        pageSize={limit}
                        total={totalPages}
                        current={currentPage.current}
                    />
                </Col>
            </Row>
        </>
    )
}

export default PaymentHistory;
