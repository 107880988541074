import { Button, Select, Col, Row, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelectSearch } from "Hooks/Search";
import { CODAT_ACCOUNTING_PLATFORMS } from "Constants/Common";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";

function MappingRow({
    trackingCodes = [],
    trackingCode = null,
    trackingCategories = [],
    trackingCategory = null,
    loading = true,
    mapId,
    onChange = () => {},
    onDelete = () => {}
}) {
    const filterSelect = useSelectSearch();
    const platform_type = useConfigurationState(state => state.platform_type);
    const handleOnChange = (key, val) => {
        onChange({key, val, mapId});
    }

    return (
        <Row gutter={16} >
            <Col span={7} className="m-t-10">
                <Row gutter={11} align='middle'>
                    <Col span={23}>
                        <Select
                            status={!trackingCode ? 'error' : null}
                            loading={loading}
                            size="large"
                            defaultValue={trackingCode}
                            placeholder="Tracking Code"
                            name="tracking_code_uuid"
                            onChange={(val) => handleOnChange('tracking_code_uuid', val)}
                            className="dark-green select-b-g full-percent-width"
                            showSearch
                            filterOption={filterSelect}
                        >
                            {trackingCodes.length &&
								trackingCodes.map(cat =>
									<Select.Option key={cat.uuid} value={cat.uuid}>
										{cat.description}
									</Select.Option>
                            )}
                        </Select>
                    </Col>
                    <Col span={1}>
                        <Typography.Text className="bold fs-18px">=</Typography.Text>
                    </Col>
                </Row>
            </Col>
            <Col span={7} className="m-t-10">
                <Row>
                    <Select
                        loading={loading}
                        size="large"
                        status={!trackingCategory ? 'error' : null}
                        defaultValue={trackingCategory}
                        placeholder="Select Tracking Category"
                        name="codat_tracking_category_id"
                        onChange={(val) => handleOnChange('codat_tracking_category_id', val)}
                        className="dark-green select-b-g full-percent-width"
                    >
                     {/* -- MSDyn ------ */}
                        {platform_type === CODAT_ACCOUNTING_PLATFORMS.msdyn ? (
                            trackingCategories?.length && trackingCategories.filter(item => item.status === 'Active' && item?.hasChildren !== true && !item?.name?.toLowerCase()?.includes('total'))?.map(track => (
                                <Select.Option key={track.id} value={track.id}>
                                    {track.name}
                                </Select.Option>
                            ))
                        ) : null}

                        {/* -- QB ------ */}
                        {/* On dev/sandbox Codat uses diff paltform_type name than live/UAT */}
                        {(platform_type === CODAT_ACCOUNTING_PLATFORMS.qb || platform_type === CODAT_ACCOUNTING_PLATFORMS.qb_sandbox) ? (
                            trackingCategories?.length && trackingCategories.filter(item => !!item?.parentId && item.status === 'Active')?.map(track => (
                                <Select.Option key={track.id} value={track.id}>
                                    {track.name}
                                </Select.Option>
                            ))
                        ) : null}

                        {/* -- Xero ------ */}
                        {platform_type === CODAT_ACCOUNTING_PLATFORMS.xero ? (
                            trackingCategories?.length && trackingCategories.filter(item => item.status === 'Active' && item?.hasChildren !== true)?.map(track => (
                                <Select.Option key={track.id} value={track.id}>
                                    {track.name}
                                </Select.Option>
                            ))
                        ) : null}
                    </Select>
                </Row>
            </Col>
            <Col span={3} className="m-t-10">
                <Button danger size="large" onClick={() => onDelete(mapId)} icon={<DeleteOutlined />} shape="circle" />
            </Col>
        </Row>
    )
}

export default MappingRow;
