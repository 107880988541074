import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Typography,
	Space,
	Image,
	Divider,
	Spin,
	notification,
} from "antd";

import { BsEyeFill } from "react-icons/bs";
import {
	FaRegSnowflake,
	FaExclamationTriangle,
	FaEdit,
} from "react-icons/fa";
import CircularCard from "App/Components/CircularCard";
import URLS from "Routes/constants";
import CardDetails from "App/Components/CardDetails";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import CardBalanceListing from "App/Components/CardBalanceListing";
import FullPrepaidCardDetailsModal from "App/Components/FullPrepaidCardDetailsModal";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { AiOutlineLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { accountingFormat } from "Utils";
import { FLAGS } from "Constants/Images";
import moment from 'moment';
import CustomTable from "App/Components/CustomTable";
import { useTransactionsHistoryQuery } from "App/Pages/Cards/TransactionHistory/query";
import AlertsCarousel from "App/Components/AlertsCarousel";
import { ErrorView } from "App/Components/ErrorView";
import { FORMATS } from "Constants/Formats";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { usePersonalCardsDashState } from "./store";

function ManageSpecificCard() {
	const currentCardIdx = usePersonalCardsDashState((state) => state.currentCardIdx);
	const currentCard = usePersonalCardsDashState((state) => state.currentCard);
	const fullScreenModalOpen = usePersonalCardsDashState((state) => state.fullScreenModalOpen);
	const modalHeading = usePersonalCardsDashState((state) => state.modalHeading);
	const modalDescription = usePersonalCardsDashState((state) => state.modalDescription);
	const modalBoldText = usePersonalCardsDashState((state) => state.modalBoldText);
	const modalType = usePersonalCardsDashState((state) => state.modalType);
	const setState = usePersonalCardsDashState((state) => state.setState);
	const activateLoading = usePersonalCardsDashState((state) => state.activateLoading);
	const { data: cards, isLoading, refetch } = usePrepaidCardsQuery(true, true);
	const { data: txHistory, isLoading: txHistoryLoading } = useTransactionsHistoryQuery();
	const [form] = Form.useForm();
	const apiRequest = useApiRequest();
	const txData = txHistory?.data?.slice(0, 5) ?? [];

	useEffect(() => {
		if (currentCard?.card_status_id === 1) {
			toggleModal('activateCard')
		} else if (currentCard?.card_status_id === 7) {
			toggleModal('return', 'CARD CANCELLED', 'The Card has been', 'Cancelled')
		} else if (currentCard?.card_status_id === 9) {
			toggleModal('return', 'CARD LOST/STOLEN', 'The Card has been Reported', 'Lost/Stolen')
		}
		// eslint-disable-next-line
	}, [currentCard])

	//
	const txColumns = [
		{
			title: 'Date',
			dataIndex: 'transaction_time',
			key: 'transaction_time',
			align: 'center',
			render: (value) => moment(value).format(FORMATS.date)
		},
		{
			title: 'User',
			dataIndex: ['extended_prepaid_card', 'embossed_name'],
			key: 'extended_prepaid_card_embossed_name',
			align: 'center',
		},
		{
			title: 'Currency',
			dataIndex: ['currency', 'currency'],
			key: 'currency',
			align: 'center',
			render: (value) => <Space size={4}>
				{FLAGS[value] && <Image src={FLAGS[value]} alt={value} width={24} preview={false} />}
				{value}
			</Space>
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			align: 'center',
			sorter: (a, b) => (a.amount && b.amount) && a.amount - b.amount,
			render: (value, record) => <Typography.Text strong type={record.debit_credit === 1 ? 'success' : 'danger'}>
				{record.debit_credit === 1 ? '+' : '-'}{accountingFormat(value)}
			</Typography.Text>
		},
		{
			title: 'Type',
			dataIndex: ['opt_transaction_type', 'option'],
			key: 'opt_transaction_type_option',
			align: 'center',
		},
	];

	//
	useEffect(() => {
		if (cards?.data) {
			setState({
				currentCard: cards?.data?.[currentCardIdx],
			});
		}
		//eslint-disable-next-line
	}, [cards?.data, currentCardIdx]);

	//
	const toggleFullScreenModal = () => {
		setState({ fullScreenModalOpen: !fullScreenModalOpen });
	};

	//
	const handleFreezeCard = async (block) => {
		setState({ activateLoading: true });
		try {
			const url = block ? `${APIROUTES.post.gps_blockcard}` : `${APIROUTES.post.gps_unblockcard}`;
			const response = await apiRequest(url, 'POST', {
				prepaid_card_id: parseInt(currentCard?.prepaid_card_id),
				additional_info: 'web-app',
				ip: '1.1.1.1'
			});
			if (response?.response?.data?.error) {
				toggleModal()
				notification.error({ message: response?.response?.data?.error?.message, placement: 'top' })
			} else {
				refetch()
				toggleModal('return', `CARD ${block ? 'FROZEN' : 'UNFROZEN'}`, 'You card is now', block ? 'Frozen' : 'Unfrozen')
			}
		} catch (err) {
			notification.error({
				message: 'Something Went Wrong',
				description: 'There was a problem with your request, please try again later and if the problem persist contact our support team.',
				placement: 'top'
			});
			toggleModal('cardActivated')
		}
		setState({ activateLoading: false })
	}

	const handleActivateCard = async (block) => {
		setState({ activateLoading: true });
		try {
			const response = await apiRequest(APIROUTES.put.activate_card, 'PUT', { prepaidCardId: currentCard?.prepaid_card_id, additional_info: 'web-app', ip: '1.1.1.1' });
			if (response?.response?.data?.error) {
				notification.error({ message: response?.response?.data?.error?.message, placement: 'top' });
			} else {
				refetch();
				toggleModal('cardActivated')
			}
		} catch (err) {
			notification.error({
				message: 'Something Went Wrong',
				description: 'There was a problem with your request, please try again later and if the problem persist contact our support team.',
				placement: 'top'
			});
		} finally {
			setState({ activateLoading: false })
		}
	}

	//
	const handleReportStolenCard = async () => {
		setState({ activateLoading: true });
		try {
			const response = await apiRequest(`${APIROUTES.post.gps_stolencard}`, 'POST', {
				prepaid_card_id: parseInt(currentCard?.prepaid_card_id),
				additional_info: 'web-app',
				ip: '1.1.1.1',
			});
			if (response && response?.response?.data?.error) {
				notification.error({ message: response?.response?.data?.error?.message, placement: 'top' })
			} else {
				refetch()
				toggleModal('return', `CARD REPORTED`, 'Your card has been reported as LOST/STOLEN and is no longer usable', '');
			}
		} catch (err) {
			notification.error({
				message: 'Something Went Wrong',
				description: 'There was a problem with your request, please try again later and if the problem persist contact our support team.',
				placement: 'top'
			});
		}
		setState({ activateLoading: false })
	}

	const handleViewPin = async (data) => {
		try {
			setState({ activateLoading: true })
			const response = await apiRequest(`${APIROUTES.post.postCardPin}?prepaidCardId=${currentCard?.prepaid_card_id}`, 'POST', {
				$password: data?.password,
				prepaidCardId: currentCard?.prepaid_card_id
			});

			if (response?.response?.data?.error) {
				notification.error({ message: response?.response?.data?.error?.message, placement: 'top' });
			} else {
				toggleModal('return', 'CARD PIN', 'Your PIN number is', response?.data?.data?.CurrentPin);
			}
		} catch (err) {
			notification.error({ message: 'Something went wrong!', placement: 'top' });
		} finally {
			setState({ activateLoading: false })
		}
	}

	const handleManulPayment = async () => {
		try {
			setState({ activateLoading: true })
			const method = currentCard?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'POST' : 'DELETE'
			const url = method !== 'DELETE' ? `${APIROUTES.post.prepaidcard_user_pan_features}` : `${APIROUTES.del.prepaidcard_user_pan_features}`;

			const response = await apiRequest(url, method, {
				prepaid_card_id: parseInt(currentCard?.prepaid_card_id),
				card_feature_id: 7
			});
			if (response?.response?.data?.error) {
				toggleModal();
				notification.error({ message: response?.response?.data?.error?.message, placement: 'top' });
			} else {
				refetch();
				toggleModal('return', `CARD ${method === 'DELETE' ? 'DISABLE' : 'ENABLE'}`, `Manual Payment has been ${method === 'DELETE' ? 'disabled' : 'enabled'} on CARD *${currentCard?.card_number}`, '');
			}
		} catch (err) {
			notification.error({ message: 'Something went wrong!', placement: 'top' });
		} finally {
			setState({ activateLoading: false })
		}
	}

	//
	const toggleModal = (type, heading, description, boldText) => {
		setState({
			modalType: type,
			modalHeading: heading,
			modalDescription: description,
			modalBoldText: boldText
		});
		form?.resetFields();
	};

	//
	const pageCards = (dir) => {
		setState({
			currentCardIdx: currentCardIdx + (
				dir === 'prev'
					? (currentCardIdx === 0 ? cards?.data.length - 1 : -1)
					: (cards?.data.length - 1 === currentCardIdx ? -currentCardIdx : 1)
			)
		});
	}

	const handleSubmit = (values) => {
		if (modalType === 'viewPin') {
			handleViewPin(values);
		}
	}

	//
	return (
		<Spin size="large" spinning={isLoading || activateLoading}>
			{cards?.data?.length ? <>
				<Modal
					footer={false}
					open={!!modalType}
					onCancel={() => toggleModal()}
					closable={true}
				>
					<Form
						layout="vertical"
						onFinish={handleSubmit}
						form={form}
						requiredMark={false}
					>
						{modalType === 'viewPin' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">
											CARD PIN
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20">
									<Col span={24}>
										<Form.Item name="password" rules={[
											{
												required: true,
												message: "Please Enter Password",
											},
										]}>
											<Input.Password placeholder="Enter Password" />
										</Form.Item>
									</Col>
								</Row>
								<Row justify="space-between">
									<Col>
										<Button type="primary" onClick={() => toggleModal()}>
											Close
										</Button>
									</Col>
									<Col>
										<Button type="primary" htmlType="submit" loading={activateLoading}>
											Submit
										</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'activateCard' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">Activate Card</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-30" justify='center'>
									<Col>
										<Typography.Text className="muli fs-16px semi-bold dark-green">
											Please only activate card <Typography.Text className="muli fs-16px extra-bold" type="danger">
												once </Typography.Text>
											you have received it
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20" justify="space-around">
									<Col>
										<Button type="primary" danger onClick={() => {
											pageCards('prev')
											toggleModal()
										}
										}>Back</Button>
									</Col>
									<Col>
										<Button type="primary" onClick={handleActivateCard} loading={activateLoading}>Activate Card</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'freeze' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">
											CARD FREEZE
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20">
									<Col>
										<Typography.Text className="muli semi-bold fs-16px dark-green">
											Are you sure you want to freeze Card ID ****{currentCard?.card_number}?
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20" justify="space-between">
									<Col>
										<Button type="primary" onClick={() => toggleModal()}>
											Close
										</Button>
									</Col>
									<Col>
										<Button onClick={() => handleFreezeCard(true)} loading={activateLoading} type="danger">Freeze</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'unfreeze' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">
											UNFREEZE CARD
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20">
									<Col>
										<Typography.Text className="muli semi-bold fs-16px dark-green">
											Are you sure you want to unfreeze Card ****{currentCard?.card_number}?
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20" justify="space-between">
									<Col>
										<Button type="primary" onClick={() => toggleModal()}>
											Close
										</Button>
									</Col>
									<Col>
										<Button onClick={() => handleFreezeCard(false)} loading={activateLoading} type="danger">Unfreeze</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'report' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">
											CARD LOST/STOLEN
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20">
									<Col>
										<Typography.Paragraph className="muli semi-bold fs-16px dark-green">
											Please ensure that all balances are transferred onto the company wallet before reporting the card as lost/stolen. Reporting it as such will automatically cancel the card; this action CANNOT be reversed.
										</Typography.Paragraph>
										<Typography.Paragraph className="muli semi-bold fs-16px dark-green">
											Do you want to mark the prepaid card as lost / stolen?
										</Typography.Paragraph>
									</Col>
								</Row>
								<Row className="m-t-20" justify="space-between">
									<Col>
										<Button type="primary" onClick={() => toggleModal()}>
											Close
										</Button>
									</Col>
									<Col>
										<Button onClick={handleReportStolenCard} type="danger">Report</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'manualPayment' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">
											{(currentCard?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'ENABLE ' : 'DISABLE ')} PAYMENT
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20">
									<Col>
										<Typography.Text className="muli semi-bold fs-16px dark-green">
											Are you sure you want to {(currentCard?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'Enable ' : 'Disable ')} Manual Payments on Card ID
											****{currentCard?.card_number}?
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20" justify="space-between">
									<Col>
										<Button type="primary" onClick={() => toggleModal()}>
											Close
										</Button>
									</Col>
									<Col>
										<Button type="primary" onClick={handleManulPayment}>{(currentCard?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'Enable ' : 'Disable ')}</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'cardActivated' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">Card Activated</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-30" justify='center'>
									<Col>
										<Typography.Text className="muli fs-16px semi-bold dark-green">
											Card successfully Activated
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20" justify="space-around">
									<Col>
										<Button type="primary" danger onClick={() => {
											pageCards('prev')
											toggleModal()
										}
										}>Back</Button>
									</Col>
									<Col>
										<Button type="primary" onClick={() => setState({ modalContent: null, modalOpen: false })}>Manage Card</Button>
									</Col>
								</Row>
							</>}

						{modalType === 'return' &&
							<>
								<Row>
									<Col>
										<Typography.Text className="muli semi-bold fs-18px dark-green">
											{modalHeading}
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20 center-align-text">
									<Col>
										<Typography.Text className="muli fs-16px dark-green">
											{modalDescription} {' '}
											<Typography.Text className="extra-bold">{modalBoldText}</Typography.Text>
										</Typography.Text>
									</Col>
								</Row>
								<Row className="m-t-20" justify="center">
									<Col>
										<Button type="primary" onClick={() => toggleModal()}>
											Return to Dashboard
										</Button>
									</Col>
								</Row>
							</>}
					</Form>
				</Modal>

				{/* Card full details modal ==================================== */}
				<FullPrepaidCardDetailsModal open={!!(fullScreenModalOpen && currentCard)} cardId={currentCard?.prepaid_card_id} onCancel={toggleFullScreenModal} />

				<Row
					className="m-t-10"
					justify="space-between"
				>
					<Col xxl={9} xl={12} lg={14} md={20} sm={24} xs={24}>
						{!!cards?.data?.length > 1 &&
							<Row>
								<Col span={24} className='right-align-text p-10'>
									<Space size='middle' className='dark-green' style={{ fontSize: '15px' }}>
										<AiOutlineLeftCircle style={{ cursor: 'pointer' }} size={20} onClick={() => pageCards('prev')} />
										<AiFillRightCircle style={{ cursor: 'pointer' }} size={20} onClick={() => pageCards('next')} />
									</Space>
								</Col>
							</Row>
						}
						<CardDetails
							arrows={cards?.data?.length > 1}
							onArrowClick={(type) => pageCards(type)}
							cardHolderName={`${currentCard?.card_holder?.name}`}
							cardNumber={`**** **** **** ${currentCard?.card_number}`}
							expiryDate={`${currentCard?.expiry_date}`}
							cardType={currentCard?.card_program_id || currentCard?.card_type}
							footer={
								<Row justify="end">
									<Col>
										<Typography.Text
											className="fs-18px medium dark-green pointer"
											onClick={toggleFullScreenModal}
										>
											View Full Details
										</Typography.Text>
									</Col>
								</Row>
							}
						/>
					</Col>

					<Col xxl={1} xl={0} lg={0} ms={0} sm={0} xs={0}>
						<Divider type='vertical' style={{ height: '100%', margin: '0 50%' }} />
					</Col>
					<Col xxl={7} xl={9} lg={11} md={14} sm={18} xs={24}>
						<AlertsCarousel />
					</Col>
				</Row>

				<Row
					className="m-t-40"
					gutter={[48, 36]}
					align="bottom"
				>
					<Col flex='auto'>
						<Row align='top' gutter={[48, 36]}>
							{currentCard?.card_status_id === 2 && (<>
								{!!currentCard?.registered && (
									<Col>
										<CircularCard
											onClick={() => toggleModal("viewPin")}
											footer="View Pin"
										>
											<BsEyeFill size={42} />
										</CircularCard>
									</Col>
								)}
								<Col>
									<CircularCard
										onClick={() => toggleModal("freeze")}
										footer="Freeze"
									>
										<FaRegSnowflake size={42} />
									</CircularCard>
								</Col>
								<Col>
									<CircularCard
										onClick={() => toggleModal("report")}
										footer="Report"
										danger
									>
										<FaExclamationTriangle size={42} style={{ marginLeft: '0px' }} />
									</CircularCard>
								</Col>
								{currentCard.prepaid_card_features.map(item => item.card_feature_id).indexOf(8) !== -1 &&
									<Col flex='175px'>
										<CircularCard
											danger={!(currentCard?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1)}
											footer={(currentCard?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'Enable ' : 'Disable ') + "Manual Payment"}
											onClick={() => toggleModal("manualPayment")}
										>
											<FaEdit style={{ transform: 'translate(3px, -2px)' }} size={42} />
										</CircularCard>
									</Col>}
							</>)}
							{currentCard?.card_status_id === 3 && (
								<>
									<Col>
										<CircularCard
											footer="Unfreeze"
											onClick={() => toggleModal("unfreeze")}
											danger
										>
											<FaRegSnowflake size={42} />
										</CircularCard>
									</Col>
									<Col>
										<CircularCard
											onClick={() => toggleModal("report")}
											footer="Report"
											danger
										>
											<FaExclamationTriangle size={42} style={{ marginLeft: '0px', transform: 'translateY(-2px)' }} />
										</CircularCard>
									</Col>
								</>
							)}
						</Row>
					</Col>
				</Row>

				<Row gutter={40} className="m-t-20" justify="space-between">
					{/* Card balance =========================================== */}
					<Col xxl={12} xl={12} lg={18} md={22} sm={24} xs={24}>
						<Typography.Text className="fs-25px medium dark-green">
							Card Balance
						</Typography.Text>
						<CardBalanceListing cardId={currentCard?.prepaid_card_id} />
					</Col>

					<Col xxl={10} xl={12} lg={16} md={20} sm={24} xs={24}>
						<Row align="bottom" justify='space-between' className="m-t-30">
							<Col className="m-b-30">
								<Typography.Text className="fs-25px medium dark-green m-b-30">Card Transaction History</Typography.Text>
							</Col>
							<Col>
								<Link to={URLS.TransactionHistory} className="fs-18px medium">View All</Link>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<CustomTable
									loading={txHistoryLoading}
									columns={txColumns}
									dataSource={txData}
									showHeader={false}
									rowKey="id"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</> : <ErrorView
				title='Cards User Access:'
				message={[
					'Your User Profile has not been permitted access to Cards.',
					'Please contact your Administrator to grant you the necessary permissions.'
				]}
				loading={isLoading}
			/>}
		</Spin>
	);
}

export default ManageSpecificCard;
