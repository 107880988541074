import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";

const useRefreshCodatCompanyData = () => {
    const apiRequest = useApiRequest();
    const setState = useConfigurationState(state => state.setState);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => {
            return apiRequest(APIROUTES.post.refresh_codat_company_data, 'POST');
        },
        mutationKey: '_refresh_codat_company_data',
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_config_all'], exact: true});
            setState({checkingRefreshState: true})
        },
    });

    return mutation;
}

export { useRefreshCodatCompanyData };