import { Button, Col, Row, Space, Typography, Select, DatePicker, Modal, notification, Spin } from "antd";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import {
	AiOutlineFilePdf,
} from "react-icons/ai";
import { FaFileCsv, FaCalendarAlt, FaFileDownload } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoDocumentTextOutline } from "react-icons/io5";
import URLS from "Routes/constants";
import { useNavigate } from "react-router-dom";
import FullScreenModal from "App/Components/FullScreenModal";
import { useStatementsAndReportsQuery } from "Hooks/Queries";
import moment from "moment";
import useReportsApiRequest from "Hooks/ReportsAPI";
import { useEffect } from "react";
import { useStatementsReportsState } from "Hooks/Store";
import { HiOutlineDocumentText, HiXCircle } from "react-icons/hi";
// import CustomPagination from "App/Components/CustomPagination";
import { REPORTS_ROUTES } from "Constants/ApiRoutes";
import { urlObjectCreator } from "Utils";
import { FORMATS } from "Constants/Formats";


function StatementsAndReports() {
	const navigate = useNavigate();
	// const selected_account_statement_ids = useStatementsReportsState(s => s.selected_account_statement_ids);
	// const selected_expense_report_ids = useStatementsReportsState(s => s.selected_expense_report_ids);
	const selectedFilter = useStatementsReportsState(s => s.selectedFilter);
	const selectedFromMonth = useStatementsReportsState(s => s.selectedFromMonth);
	const selectedToMonth = useStatementsReportsState(s => s.selectedToMonth);
	const modalVisible = useStatementsReportsState(s => s.modalVisible);
	const downloadModal = useStatementsReportsState(s => s.downloadModal);
	const downloadUrl = useStatementsReportsState(s => s.downloadUrl);
	const clearFilters = useStatementsReportsState(s => s.clearFilters);
	const loading = useStatementsReportsState(s => s.loading);
	const setState = useStatementsReportsState(s => s.setState);
	const reportsApiRequest = useReportsApiRequest();

	const { data: reportsData, isLoading, refetch: refetchDocs, isFetching } = useStatementsAndReportsQuery();

	//
	useEffect(() => {
		return () => {
			setState({
				selected: [],
				modalVisible: false,
				selectedFilter: null,
				selectedFromMonth: null,
				selectedToMonth: null,
			});
		}
		//eslint-disable-next-line
	}, []);

	//
	useEffect(() => {
		refetchDocs();

		// eslint-disable-next-line
	}, [clearFilters]);

	//
	const handleSelectItem = (record) => {
		let selected_account_statement_ids = [];
		let selected_expense_report_ids = [];
		if (record.report_type === 'account-statement') {
			selected_account_statement_ids = selected_account_statement_ids?.includes(record.id)
				? selected_account_statement_ids?.filter(r => r !== record.id)
				: [...selected_account_statement_ids, record.id]
		}

		if (record.report_type === 'expense-report') {
			selected_expense_report_ids = selected_expense_report_ids?.includes(record.id)
				? selected_expense_report_ids?.filter(r => r !== record.id)
				: [...selected_expense_report_ids, record.id]
		}
		setState({ selected_account_statement_ids, selected_expense_report_ids })
		handleDownloadSelected(selected_account_statement_ids, selected_expense_report_ids);
	}

	//
	// const handleSelectAll = (evt) => {
	// 	setState({
	// 		selected_account_statement_ids: evt.target.checked ? reportsData?.data?.filter(r => r.report_type === REPORT_TYPES.account_statement)?.map(r => r.id) || [] : [],
	// 		selected_expense_report_ids: evt.target.checked ? reportsData?.data?.filter(r => r.report_type === REPORT_TYPES.expense_report)?.map(r => r.id) || [] : [],
	// 	})
	// }

	//
	const getDocumentFormatIcon = (type) => {
		if (type === 'pdf') {
			return <AiOutlineFilePdf style={{ fontSize: '32px', color: '#0f99a3' }} />;
		} else if (type === 'csv') {
			return <FaFileCsv style={{ fontSize: '32px', color: '#0f99a3' }} />;
		} else if (type === 'xls' || type === 'xlsx') {
			return <SiMicrosoftexcel style={{ fontSize: '32px', color: '#0f99a3' }} />;
		} else {
			return <IoDocumentTextOutline style={{ fontSize: '32px', color: '#0f99a3' }} />;
		}
	}

	//
	const handleMonthFromSelect = (date, datestring) => {
		const toMonth = (selectedToMonth?.date?.isBefore(date) || selectedToMonth?.datestring === datestring) ? null : selectedToMonth;
		setState({
			selectedFromMonth: { date, datestring },
			selectedToMonth: toMonth,
		});
	}

	//
	const handleMonthToSelect = (date, datestring) => {
		const fromMonth = (selectedFromMonth?.date?.isAfter(date) || selectedFromMonth?.datestring === datestring) ? null : selectedFromMonth;
		setState({
			selectedToMonth: { date, datestring },
			selectedFromMonth: fromMonth,
		});
	}

	//
	const handleFilterModalClose = () => {
		setState({
			modalVisible: false,
		});
	}

	//
	const handleFilterChange = (val) => {
		// eslint-disable-next-line
		if (val != 'month') {
			setState({
				selectedFromMonth: null,
				selectedToMonth: null,
				selectedFilter: val,
			});
		}
		else {
			setState({ selectedFilter: val });
		}
	}

	//
	const handleConfirmFilters = async () => {
		await refetchDocs();
		handleFilterModalClose();
	}

	const clearAllFilters = () => {
        setState({
            selectedFilter: null,
            selectedFromMonth: null,
            selectedToMonth: null,
            clearFilters: !clearFilters,
        });
    }

	//
	const renderFilterInfo = () => {
		let filterInfo = '';
		if (selectedFilter) {
			if (selectedToMonth && selectedFromMonth) {
				filterInfo = `From ${selectedFromMonth.datestring}-01 to ${moment(`${selectedToMonth.datestring}-01`).format('YYYY-MM-DD')}`;
			} else {
				if (selectedFilter === 'account-statement') {
					filterInfo = `Account statements`;
				} else if (selectedFilter === 'expense-report') {
					filterInfo = `Expense Reports`;
				} else {
					filterInfo = `Account Statements`;
				}
			}

			return <>
				<Typography>{filterInfo}</Typography>
				<Button
					onClick={() => {
						setState({
							selectedFilter: null,
							selectedFromMonth: null,
							selectedToMonth: null,
							clearFilters: !clearFilters,
						});
					}}
					style={{ transform: 'translateY(3px)' }} type="link"
				>
					<HiXCircle />
				</Button>
			</>
		}

		return null;
	}

	//
	const handleDownloadSelected = async (selected_account_statement_ids, selected_expense_report_ids) => {

		setState({
			loading: true,
		});

		try {
			const response = await reportsApiRequest({
				path: REPORTS_ROUTES.get.get_reports_download,
				method: 'POST',
				data: {
					file_ids: [],
					expense_report_ids: selected_expense_report_ids,
					account_statement_ids: selected_account_statement_ids,
				},
				config: {
					responseType: "blob",
				},
				fullResponse: true,
			});

			if (response.headers['content-type'] === "application/zip") {
				setState({
					loading: false,
					downloadModal: true,
					downloadUrl: urlObjectCreator(response.data),
				});
			}
			else {
				throw new Error('Something went wrong genarating reports');
			}
		}
		catch (err) {
			notification.error({ placement: 'top', message: 'Something went wrong', description: 'Please try again.' });
		} finally {
			setState({
				loading: false,
				selected_account_statement_ids: [],
				selected_expense_report_ids: [],
			});
		}
	}

	//
	const handleDownloadModalClose = () => {
		setState({
			loading: false,
			downloadModal: false,
			downloadUrl: null,
		});
	}

	//
	// const handlePageChange = (page) => {
	//     setState({ currentPage: page });
	// }

	//
	// const handlePageSizeChange = (current, size) => {
	//     setState({ limit: size, currentPage: 1 });
	// }

	// const areAllReportsSelected = () => {
	// 	return selected_account_statement_ids?.length + selected_expense_report_ids?.length === reportsData?.data?.length;
	// }

	// const isReportItemSelected = (record) => {
	// 	if (record.report_type === REPORT_TYPES.account_statement) {
	// 		return selected_account_statement_ids?.includes(record.id);
	// 	}
	// 	else if (record.report_type === REPORT_TYPES.expense_report) {
	// 		return selected_expense_report_ids?.includes(record.id);
	// 	}
	// 	else {
	// 		return false;
	// 	}
	// }

	//
	const columns = [
		{
			title: 'Date',
			dataIndex: 'date_from',
			key: 'date',
			align: 'left',
			sorter: (a, b) => moment(a?.date_from).unix() - moment(b?.date_from).unix(),
			render: (value, record) => {
				const docDate = moment(value);
				// const yesterday = moment().subtract(1, 'day');
				return <Space size="small">
					<HiOutlineDocumentText style={{ fontSize: '32px', color: '#0f99a3' }} />
					<Typography>
						{record.report_type === 'expense-report' ? (
							moment().format('M-YYYY') === moment(docDate).format('M-YYYY') ? 'Current Month' : docDate.format(FORMATS.expenseReportDate)
						) : (
							// docDate.isBefore(yesterday) ? docDate.format(FORMATS.date) : docDate.fromNow()
							docDate.format(FORMATS.expenseReportDate)
						)}
					</Typography>
				</Space>
			}
		},
		{
			title: 'Document name',
			dataIndex: 'report_name',
			key: 'doc_name',
			align: 'left',
			// sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time, 'DD-MM-YYYY').diff(moment(b.transaction_time, 'DD-MM-YYYY')),
			render: (value) => <Typography>{value}</Typography>
		},
		{
			title: 'Format',
			dataIndex: 'file_type',
			key: 'format',
			align: 'left',
			// sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time, 'DD-MM-YYYY').diff(moment(b.transaction_time, 'DD-MM-YYYY')),
			render: (value) => getDocumentFormatIcon(value)
		},
		{
			// title: <Space>Select All {<Checkbox onChange={(evt) => handleSelectAll(evt)} checked={areAllReportsSelected()} />}</Space>,
			title: 'Download',
			dataIndex: 'select_all',
			key: 'select_all',
			// sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time, 'DD-MM-YYYY').diff(moment(b.transaction_time, 'DD-MM-YYYY')),
			// render: (value, record) => <CheckDotButton checked={isReportItemSelected(record)} onClick={() => handleSelectItem(record)} />
			render: (_, record) => <FaFileDownload onClick={() => handleSelectItem(record)} size={32} style={{ marginRight: '10px' }} className="light-green pointer" />
		},
	];

	//
	const disabledDate = (current) => {
		// Can not select days before today
		if (current && current <= moment().subtract(1, 'years').startOf('month')) {
			return true;
		}

		if (current && current > moment().endOf('month')) {
			return true;
		}
	};

	//
	const disabledDateTo = (current) => {
		if (selectedFromMonth?.date) {
			if (current && current > moment().add(2, 'months').startOf('month')) {
				return true;
			}

			if (current && current < moment(selectedFromMonth.date).endOf('month')) {
				return true;
			}
		} else {
			if (current && current > moment().add(2, 'months').startOf('month')) {
				return true;
			}

			if (current && current <= moment().subtract(1, 'years').startOf('month')) {
				return true;
			}
		}

		if (current && current <= moment().subtract(1, 'years').startOf('monts')) {
			return true;
		}
	};

	//
	const onPaginationChange = (pagination) => {
		setState({
			selected_account_statement_ids: [],
			selected_expense_report_ids: [],
		});
	}

	//
	return (
		<Spin
			size='large'
			spinning={loading}
			tip={<Typography.Text className="medium fs-24px dark-green">Your document is being generated. This could take a few  minutes. Please do not close this page.</Typography.Text>}
		>
			<FullScreenModal
				open={modalVisible}
				onCancel={() => {
					clearAllFilters();
					handleFilterModalClose();
				}}
			>
				<Row style={{ height: 'calc(100vh - 120px)' }} align="stretch" justify="space-between">
					<Col span={24} style={{ marginTop: '100px' }}>
						<Row>
							<Col span={24}>
								<Select
									bordered={true}
									value={selectedFilter}
									onChange={(val) => handleFilterChange(val)}
									placeholder="Select filter..."
									style={{
										width: 200,
									}}
									options={[
										{
											value: 'month',
											label: 'Month',
										},
										{
											value: 'account-statement',
											label: 'Account Statement',
										},
										{
											value: 'expense-report',
											label: 'Expense Report',
										},
									]}
								/>
							</Col>

							{selectedFilter === 'month' && <Col span={24} className="m-t-30">
								<Row gutter={[50, 10]}>
									<Col>
										<Typography>From <FaCalendarAlt color="#0f99a3" /></Typography>
										<DatePicker disabledDate={disabledDate} value={selectedFromMonth?.date} onChange={handleMonthFromSelect} picker="month" />
									</Col>
									<Col>
										<Typography>To <FaCalendarAlt color="#0f99a3" /></Typography>
										<DatePicker disabledDate={disabledDateTo} value={selectedToMonth?.date} onChange={handleMonthToSelect} picker="month" />
									</Col>
								</Row>
							</Col>}
						</Row>

					</Col>

					<Col span={24}>
						<Row className="m-t-20" justify="end" align="stretch">
							<Col>
								<Space>
									<Button type="primary" danger onClick={ () => {
										clearAllFilters();
										handleFilterModalClose();
									}}>Cancel</Button>
									<Button type="primary" disabled={selectedFilter === 'month' && (!selectedFromMonth || !selectedToMonth)} onClick={handleConfirmFilters}>Confirm</Button>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
			</FullScreenModal>

			<Modal
				title="Reports and Statements Export"
				open={downloadModal}
				onCancel={handleDownloadModalClose}
				footer={[
					<Button key="back" onClick={handleDownloadModalClose}>
						Cancel
					</Button>,
				]}
			>
				{(!!downloadUrl) &&
					<Row align="middle" justify="center" wrap gutter={[0, 20]}>
						<Col span={24} align="middle" justify="center">
							<Typography.Text className="muli semi-bold fs-18px dark-green">Your report / statement is ready for download</Typography.Text>
							<br />
							{/* <Typography.Text>{'transactions-statement-' + moment().format('MMMM-Do-YYYY_Hmmss') + exportStatementModal.extension}</Typography.Text> */}
						</Col>
						<Col span={24} align="middle" justify="center">
							<Button
								type="primary"
								href={downloadUrl}
								download={'volopa_reports_statements_' + moment().format('YYYY_MM_D_HHmmSSS') + '.zip'}
								onClick={() => handleDownloadModalClose()}
								size="lg"
							>
								<FaFileDownload size={14} style={{ marginRight: '10px' }} /> Download
							</Button>
						</Col>
					</Row>
				}
			</Modal>

			<Row>
				<Col span={24}>
					<PageDescription title='Statements and Reports' />
				</Col>
			</Row>

			<Row className="m-t-30" justify="space-between" gutter={[12, 12]}>
				<Col xxl={8}>
					<Space>
						<Button onClick={() => setState({ modalVisible: true })} type="primary">Filter</Button>
						{renderFilterInfo()}
					</Space>

				</Col>
				<Col>
					<Space wrap>
						{/* <Button
							onClick={handleDownloadSelected}
							type="primary"
							loading={loading}
							disabled={!(selected_account_statement_ids?.length || selected_expense_report_ids?.length || loading)}
						>
							<FaFileDownload size={14} style={{ marginRight: '10px' }} /> Download Selected
						</Button> */}
						<Button onClick={() => navigate(`${URLS.TransactionHistory}`)} >Return to Transactions</Button>
					</Space>
				</Col>
			</Row>

			<Row className="m-t-30">
				<Col span={24}>
					{/* {reportsData?.total >= reportsData?.data?.length &&
						<CustomPagination
							loading={isLoading || isFetching}
							total={reportsData?.total}
							pageSize={limit}
							current={currentPage}
							onPageChange={handlePageChange}
							onPageSizeChange={handlePageSizeChange}
							pageSizeOptions={[
								10, 20, 50, 100, 500
							]}
						/>
					} */}
					<CustomTable
						loading={isLoading || isFetching}
						columns={columns}
						dataSource={reportsData?.data || []}
						className='spaced-rows'
						headerColor='green'
						rowClassName='fs-18px regular'
						styleAllRows
						rowKey="id"
						pagination={true}
						onChange={(pagination) => onPaginationChange(pagination)}
					/>
				</Col>
			</Row>
		</Spin>
	);
}

export default StatementsAndReports;
