import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";

const useAccountingTrackingCodes = () => {
    const statusId = useConfigurationState(state => state.statusId);
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.get_All_volopa_tracking_codes}?$with=All`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_get_volopa_accounting_tracking_codes'],
        queryFn: fetchQuery,
        enabled: statusId === 3,
    });

    return query;
}

export { useAccountingTrackingCodes };
