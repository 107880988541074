import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useGetPaymentCutOff = () => {

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const res = await axios.get('https://volopa.com/wp-json/wp/v2/pages/20619');
    return res;
  }

  return useQuery({
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    queryKey: ['_payment_cutoff'],
    queryFn: fetchQuery,
    select: res => res
  });
}

export { useGetPaymentCutOff };