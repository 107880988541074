import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { FLAGS } from "Constants/Images";
import { useConvertBalancesState } from "App/Pages/Wallet/ConvertBalances/store";
import { useLoginStore } from "App/Pages/Login/store";

const useCurrenciesQuery = (payments = false) => {
    const apiRequest = useApiRequest();
    const config = useLoginStore(s => s.config);
    const setConvertBalancesState = useConvertBalancesState((state) => state.setState);


    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.currency}?$limit=500&active=1`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_currency'],
        queryFn: fetchQuery,
        onSuccess: (resData) => {
            setConvertBalancesState({ currencies: resData });
        },
        select: (resData) => {
            const currArray = config.is_eea === 1 ?
                ['BHD', 'BGN', 'CNY', 'ILS', 'KWD', 'OMR', 'QAR', 'RUB', 'THB', 'UGX', 'AED', 'INR', 'KES', 'RON', 'SAR', 'TRY', 'MXN', 'CZK'] :
                ['BHD', 'BGN', 'CNY', 'ILS', 'KWD', 'OMR', 'QAR', 'RUB', 'UGX', 'AED', 'INR', 'KES', 'RON', 'SAR', 'TRY', 'MXN', 'CZK'];
            const res = payments ?
                resData
                    .filter((data) => (!currArray.includes(data.currency)))
                    .sort((a, b) => a.currency.localeCompare(b.currency))
                    .map((val) => {
                        return ({
                            ...val,
                            flag: FLAGS[val.currency]
                        });
                    }) :
                resData
                    .filter((data) => data.active_card === 1)
                    .sort((a, b) => a.currency.localeCompare(b.currency))
                    .map((val) => {
                        return ({
                            ...val,
                            flag: FLAGS[val.currency]
                        });
                    });

            return res;
        }
    });

    return query;
}

export { useCurrenciesQuery };
