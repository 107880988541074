import { Card, Row, Col, Typography, Space, Grid, Spin } from 'antd';
import CardImage from 'App/Components/CardImage';
import { AiFillRightCircle, AiOutlineLeftCircle } from 'react-icons/ai';
import { BiRadioCircleMarked } from 'react-icons/bi'

const { useBreakpoint } = Grid;

function CardDetails({
    cardHolderName = '',
    cardNumber = '',
    expiryDate,
    cardType,
    footer,
    cardStatus = '',
    isLoading = false,
    arrows = false,
    onArrowClick = (type) => { }
}) {
    const breakpoint = useBreakpoint();

    return (
        <Spin spinning={isLoading}>
            <Card className="bg-gradient big-rounded" bodyStyle={{ paddingLeft: '4.28%', paddingRight: '4.28%' }}>
                <Row gutter={[36, 24]}>
                    <Col span={12} xs={11} className=''>
                        <Card className="b-2-g" bodyStyle={{ padding: '0' }}>
                            <Row justify='center' style={{ marginTop: '16%', marginBottom: '16%' }}>
                                <Col xxl={16} xl={16} lg={18} md={18} sm={18} xs={24}>
                                    <CardImage cardProgram={cardType} />
                                </Col>
                            </Row>
                            <Row justify='end'>
                                {cardStatus && (<Space size='small'>
                                    <BiRadioCircleMarked className={cardStatus === 'Active' ? 'success'
                                        : (cardStatus === 'Cancelled' || cardStatus === 'Lost/Stolen') ? 'error'
                                            : cardStatus === 'Expired' ? 'error' : ''} size={23} />
                                    <Typography className={cardStatus === 'Active' ? 'success'
                                        : (cardStatus === 'Cancelled' || cardStatus === 'Lost/Stolen') ? 'error'
                                            : cardStatus === 'Expired' ? 'error' : ''} style={{ marginTop: '-0.4em', marginLeft: '-0.4em', marginRight: '0.9em' }} >{cardStatus}</Typography>
                                </Space>)}
                            </Row>
                        </Card>
                    </Col>
                    {!isLoading && (
                        <Col span={12} xs={13} className='line-height-18px'>
                            <Row justify='space-between'>
                                <Col>
                                    <Typography.Text className={(breakpoint.xs === false ? "fs-18px " : "") + "regular dark-green"}>Card Holder Name</Typography.Text>
                                </Col>
                                {arrows && <Col>
                                    <Space size='middle' className='dark-green' style={{ fontSize: '15px' }}>
                                        <AiOutlineLeftCircle style={{ cursor: 'pointer' }} size={20} onClick={() => onArrowClick('prev')} />
                                        <AiFillRightCircle style={{ cursor: 'pointer' }} size={20} onClick={() => onArrowClick('next')} />
                                    </Space>
                                </Col>}
                            </Row>
                            <Row>
                                <Col>
                                    <Typography.Text className={(breakpoint.xs === false ? "fs-18px " : "") + "medium dark-green"}>{cardHolderName}</Typography.Text>
                                </Col>
                            </Row>
                            <Row className='m-t-12'>
                                <Col>
                                    <Space direction="vertical" size={12}>
                                        <Space direction="vertical" size={2}>
                                            <Typography.Text className={(breakpoint.xs === false ? "fs-18px " : "") + "regular dark-green"}>Card Number</Typography.Text>
                                            <Typography.Text className={(breakpoint.xs === false ? "fs-18px " : "") + "medium dark-green"}>{cardNumber}</Typography.Text>
                                        </Space>
                                        <Space direction="vertical" size={2}>
                                            <Typography.Text className={(breakpoint.xs === false ? "fs-18px " : "") + "regular dark-green"}>Expiry Date</Typography.Text>
                                            <Typography.Text className={(breakpoint.xs === false ? "fs-18px " : "") + "medium dark-green"}>{expiryDate}</Typography.Text>
                                        </Space>
                                    </Space>
                                </Col>
                            </Row>
                            {footer}
                        </Col>
                    )}
                </Row>
            </Card>
        </Spin>
    )
}

export default CardDetails;
