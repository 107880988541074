import { Card, Col, Row, Typography } from "antd";
import PageDescription from "App/Components/PageDescription";
// import squareLogo from 'Assets/Images/squareLogo.png';
// import bills from 'Assets/Images/bills.png';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";

function ExpenseTags() {
    const navigate = useNavigate();
    const cards = [
        {
            image: <FaSearch size={120} className='dark-green' />,
            text: 'Tracking Codes',
            onClick: () => navigate(URLS.TrackingCodes)
        },
    ]
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Expense Tags' />
                </Col>
            </Row>
            <Row className="m-t-20">
                {cards && cards.length > 0 && cards.map((val, key) => (
                    <Col xxl={6} xl={8} lg={10} md={12} sm={16} xs={24} key={key}>
                        <Card hoverable className="b-g p-10" onClick={val.onClick}>
                            <Row justify='center'>
                                <Col>
                                    {val.image && val.image}
                                </Col>
                            </Row>
                            <Row justify='center' className="m-t-20">
                                <Col>
                                    <Typography.Text className="muli semi-bold fs-18px">{val.text}</Typography.Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default ExpenseTags;