const OPT_DISABLED_PAYMENT_TYPES = {
	contactless: 1,
	atm: 2,
	pos: 3,
	atmWithdraw: 4,
	onlineTx: 5,
	ecom: 6,
	mailtel: 7,
}

const OPT_TTC_SELF_QUOTE_STATUS_ID = {
	INSTANT: 1,
	APPROVAL_REQUIRED: 2,
	DRAFTED: 3,
	APPROVED: 4,
	ACCEPTED: 5,
	SCHEDULED: 6,
	PROCESSED: 7,
	EDITED: 8,
	EXPIRED: 9,
	CANCELLED: 10,
	REQUOTED: 11,
	INFO_ONLY: 12,
	TEMPORARY_QUOTE: 13,
	SCHEDULED_EXPIRED: 14
}

const OPT_NOTES_TYPE_ID = {
	TYPE_APPROVAL_NOTE: 3,
	TYPE_APPROVAL_INVOICE_REF: 4
}

export const OPT_FUNDING_METHOD = {
	MANUAL_PUSH_FUNDS: 1,
	EASY_TRANSFER: 2,
	VOLOPA_COLLECTION_ACCOUNT: 3
}

export const OPT_FEATURES = {
	sharedBalances: 1,
	deliverCardsToResidentialAddress: 2,
	separateInvoiceForCardFx: 3,
	sharedBalanceBaseCurrencyZeroSweep: 4,
	tierPricing: 5,
	codatInegration: 6,
	oldIntlPayment: 7,
	downloadAttachmentZip: 8,
	fundWalletReminder: 9,
	cardFrozenReminder: 10,
	fxForwards: 11,
	baseCurrency: 12,
	collections: 13,
	collectionsPilot: 14
}

export {
	OPT_DISABLED_PAYMENT_TYPES,
	OPT_TTC_SELF_QUOTE_STATUS_ID,
	OPT_NOTES_TYPE_ID
}
