/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Col, Layout, Row } from "antd";
import NavBar from "App/Components/NavBar";
import { useLoginStore } from "App/Pages/Login/store";
import { useServiceNotificationQuery } from "Hooks/Queries";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
    const outageAlert = useLoginStore(store => store.outageAlert);
    const { data: serviceNotifications } = useServiceNotificationQuery();

    return (
        <Layout>
            <Layout.Header
                style={{
                    position: 'sticky',
                    zIndex: 5,
                    width: '100%',
                    padding: 0,
                    top: 0
                }}
            >
                <Row style={{
                    paddingLeft: '2.08%',
                    paddingRight: '2.08%'
                }}>
                    <Col span={24}>
                        <NavBar />
                    </Col>
                </Row>
            </Layout.Header>
            {(outageAlert || serviceNotifications?.length > 0) &&
                <Row style={{
                    position: "sticky",
                    top: '64px',
                    zIndex: 3
                }}>
                    <Col span={24}>
                        <Alert
                            banner
                            className="outage"
                            message={serviceNotifications?.length > 1 || (serviceNotifications?.length > 0 && outageAlert) ?
                                <ul style={{ marginBottom: '0px' }}>
                                    {serviceNotifications?.map((notification, key) => <li key={key}>{notification?.service_notification?.content}</li>)}
                                    {outageAlert && <li>{outageAlert}</li>}
                                </ul> :
                                <>
                                    {serviceNotifications?.[0]?.service_notification?.content}
                                    {outageAlert}
                                </>}
                            showIcon={false}
                            type='error'
                            closable
                        />
                    </Col>
                </Row>
            }
            <Layout.Content
                className="content"
                style={{
                    minHeight: `calc(100vh - 100px)`,
                    marginTop: '10px'
                }}
            >
                <Outlet />
            </Layout.Content>
        </Layout>
    )
}

export default AppLayout;
