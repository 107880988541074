import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import reportWebVitals from 'reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from 'App';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number(process.env.REACT_APP_REACT_QUERY_STALE_TIME || 0),
      retry: false,
      cacheTime: Number(process.env.REACT_APP_REACT_QUERY_CACHE_TIME || 0),
      refetchOnMount: true,
      refetchOnWindowFocus: 'always',
      refetchOnReconnect: true,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Warning: findDOMNode is deprecated in StrictMode
  // @see https://github.com/ant-design/ant-design/issues/31805
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>

    {process.env.REACT_APP_REACT_QUERY_DEVTOOLS === "1" && <ReactQueryDevtools position="bottom-right" />}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
