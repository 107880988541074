import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useAddNewUserState } from "../../AddNewUser/store";
import { useNavigate } from "react-router-dom";

const useAddNewUser = () => {
    const apiRequest = useApiRequest();
    const queryClient= useQueryClient()
    const setState = useAddNewUserState(state => state.setState);
    const navigate = useNavigate();

    const mutation = useMutation({
        mutationFn: (user) => {

            return apiRequest(APIROUTES.post.business_user_create, 'POST', user);
        },
        mutationKey: ['_business_user_create'],
        onMutate: () => {},
        onSuccess: (data) => {
            if(data?.response?.data?.error) {
                notification.error({ placement: 'top', message: data?.response?.data?.error?.message });
                return;
            }
            queryClient.invalidateQueries(['_users_list'])
            notification.success({ placement: 'top', message: 'User Created Successfully!', description: 'Activation Code Resent Successfully!' });
            setState({
                resetForm: true,
            })
            navigate('/settings/company-account/manage-users');
        },
        onError: (error) => {
            notification.error({ placement: 'top', message: 'Something went wrong', description: 'Please try again.' });
        },
    });

    return mutation;
}

export { useAddNewUser };