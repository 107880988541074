import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useSyncExpensesMutation = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (body) => {
            return apiRequest(APIROUTES.post_accounting_synch_expense, 'POST', body);
        },
        mutationKey: '_post_accounting_synch_expense',
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_expenses_notsynced'], exact: false });
        }
    });

    return mutation;
}

export { useSyncExpensesMutation };
