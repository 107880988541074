import { useNavigate, useMatch } from "react-router-dom";
import { Space, Button } from "antd";
import URLS from "Routes/constants";

function BulkCardControlsNav() {
	const navigate = useNavigate();

    return (
		<Space wrap>
			<Button
				type='primary'
				onClick={() => navigate(URLS.BulkCardControlsDailyLimits)}
				ghost={!useMatch(URLS.BulkCardControlsDailyLimits)}>Daily Limits</Button>
			<Button
				type='primary'
				onClick={() => navigate(URLS.BulkCardControlsAutoFund)}
				ghost={!useMatch(URLS.BulkCardControlsAutoFund)}>Card Auto Funding</Button>
			<Button
				type='primary'
				onClick={() => navigate(URLS.BulkCardControlsTxLimits)}
				ghost={!useMatch(URLS.BulkCardControlsTxLimits)}>Transaction Limits</Button>
			<Button
				type='primary'
				onClick={() => navigate(URLS.BulkCardControlsPaymentTypes)}
				ghost={!useMatch(URLS.BulkCardControlsPaymentTypes)}>Authorised Payment Types</Button>
		</Space>
    )
}

export default BulkCardControlsNav;
