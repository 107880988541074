import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useManageSpecificUserState } from "App/Pages/Settings/CompanyAccount/ManageUsers/ManageSpecificUser/store";
import { notification } from "antd";

// txId is specific transaction_id
const useSpecificUserMutation = (userId) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setState = useManageSpecificUserState(state => state.setState);

    const mutation = useMutation({
        mutationFn: ({targetUserId, roleId, userEmail, telephoneMobile}) => {
            const payload = {
                target_user_id: targetUserId,
                role_id: roleId,
                user_email: userEmail,
                telephone_mobile: telephoneMobile
            }
            return apiRequest(`${APIROUTES.post.user_update}`, 'POST', payload);
        },
        mutationKey: ['_edit_users_manage', userId],
        onMutate: () => {},
        onSuccess: (data, context) => {
            if(data?.data?.success) {
                queryClient.invalidateQueries({ queryKey: ['_edit_users_manage', context.targetUserId.toString()]});
                notification.success({ message: 'User Updated!', description: 'user updated successfully!', placement: "top"})
                setState({ editRole: false, editEmail: false, editTelephoneMobile: false})
            } else {
                notification.error({ message: data?.response?.data?.error?.message, description: data?.response?.data?.error?.details, placement: "top"})
            }
        },
        onError: (error) => {},
    });

    return mutation;
}

export { useSpecificUserMutation };
