import create from "zustand";

const useManualTradeHistoryState = create(set => ({
    paymentData: undefined,
    paymentsLoading: false,
    limit: 20,
    currentPage: 1,
    totalPages: 1,
    setState: obj => set(obj)
}));

export { useManualTradeHistoryState };