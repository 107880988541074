import { Space, Row, Col, Button, Checkbox, Input, Typography, Image, InputNumber, notification, Modal } from "antd";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import create from "zustand";
import { useEffect } from "react";
import { accountingFormat, numberFormat, pluralize } from "Utils";
import { debounce, isEqual } from "lodash";
import {
	useBulkCardAutoFundMutation,
	useBulkDelCardAutoFundMutation
} from "Hooks/Mutations";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useCardsState } from "../../Cards/store";
import CustomPagination from "App/Components/CustomPagination";

const AMOUNT_LOAD_MIN = 1;

const useBulkCardAutoFundState = create((set, get) => ({
	selectedCards: [],
	editCardId: null,
	selectAll: false,
	loading: false,
	autoLoadType: null,
	amountBelow: '',
	amountLoad: '',
	confirmModal: false,
	singleCard: {
		autoLoadType: null,
		amountBelow: '',
		amountLoad: '',
	},
	setState: (data) => set(data),
	resetState: () => set({
		selectedCards: [],
		editCardId: null,
		selectAll: false,
		loading: false,
		autoLoadType: null,
		amountBelow: '',
		amountLoad: '',
		confirmModal: false,
		singleCard: {
			autoLoadType: null,
			amountBelow: '',
			amountLoad: '',
		}
	}),
}));

function BulkAutoFund() {
	const selectedCards = useBulkCardAutoFundState(state => state.selectedCards);
	const selectAll = useBulkCardAutoFundState(state => state.selectAll);
	const editCardId = useBulkCardAutoFundState(state => state.editCardId);
	const loading = useBulkCardAutoFundState(state => state.loading);
	const autoLoadType = useBulkCardAutoFundState(state => state.autoLoadType);
	const amountBelow = useBulkCardAutoFundState(state => state.amountBelow);
	const amountLoad = useBulkCardAutoFundState(state => state.amountLoad);
	const confirmModal = useBulkCardAutoFundState(state => state.confirmModal);
	const singleCard = useBulkCardAutoFundState(state => state.singleCard);
	const setState = useBulkCardAutoFundState(state => state.setState);
	const resetState = useBulkCardAutoFundState(state => state.resetState);
	const setCardsState = useCardsState(state => state.setState);
	const currentPage = useCardsState(state => state.currentPage);
	const limit = useCardsState(state => state.limit);
	const cards = useCardsState(state => state.prepaidCardsQueryData);

	const { isLoading: cardsQueryIsFetching, data: prepaidCards } = usePrepaidCardsQuery();

	const bulkCardAutoFundMutation = useBulkCardAutoFundMutation(payload => payload);
	const bulkDelCardAutoFundMutation = useBulkDelCardAutoFundMutation(payload => payload);



	//
	useEffect(() => {

		return () => {
			resetState();
			setCardsState({
				cardSearchFilter: null,
				currentPage: 1,
				limit: 10,
				totalPages: 0,
			});
		}
		// eslint-disable-next-line
	}, []);

	//
	useEffect(() => {
		if (cards) {
			setState({ selectAll: selectedCards.length === cards.length });
		}

		// eslint-disable-next-line
	}, [selectedCards, cards]);

	//
	const cardAutoloadDataChanged = () => {
		const card = cards.find((card) => card.prepaid_card_id === editCardId);
		if (!card) return false;

		return !isEqual(
			singleCard,
			{
				autoLoadType: card?.prepaid_card_auto_load?.autoload_type_id,
				amountBelow: card?.prepaid_card_auto_load?.amount_below,
				amountLoad: card?.prepaid_card_auto_load?.amount_load,
			}
		);
	}

	//
	const handleSelectAllCards = (evt) => {
		setState({
			selectedCards: evt.target.checked
				? cards.reduce((acc, card) => {
					acc.push({
						card_id: card.prepaid_card_id,
						autoload_id: card?.prepaid_card_auto_load?.id || null
					}
					);
					return acc;
				}, [])
				: [],
		});
	}

	const isActivated = (record) => record?.opt_card_status?.id === 1 ? true : false;

	//
	const handleSelectCard = (evt, record) => {
		setState({
			selectedCards: evt.target.checked
				? [...selectedCards, {
					card_id: record.prepaid_card_id,
					autoload_id: record?.prepaid_card_auto_load?.id || null
				}]
				: selectedCards.filter(item => item.card_id !== record.prepaid_card_id),
		});
	}

	//
	const handleEditClick = (card) => {
		setState({
			editCardId: card.prepaid_card_id,
			selectedCards: [],
			singleCard: {
				autoLoadType: card?.prepaid_card_auto_load?.autoload_type_id,
				amountBelow: card?.prepaid_card_auto_load?.amount_below || '',
				amountLoad: card?.prepaid_card_auto_load?.amount_load || '',
			}
		});
	}

	//
	const cancelEditMode = () => {
		resetState();
	}


	//
	const handleSingleAmountLoadChange = (val, type) => {
		setState({
			singleCard: {
				...singleCard,
				autoLoadType: type,
				amountLoad: val,
			}
		});
	}

	//
	const handleSingleAmountBelowChange = (val) => {
		setState({
			singleCard: {
				...singleCard,
				amountBelow: val,
			}
		});
	}

	//
	const handleSingleCardAutoFundMutation = async (singleCardAutoloadId) => {
		setState({ loading: true });

		await bulkCardAutoFundMutation.mutateAsync({
			prepaid_card_autoloads: [
				{
					card_id: editCardId,
					autoload_id: singleCardAutoloadId || null
				}
			],
			autoload_type_id: singleCard.autoLoadType,
			amount_below: singleCard.amountBelow || 0,
			amount_load: singleCard.amountLoad,
		}, {
			onSettled: () => resetState()
		});
	}

	//
	const handleSingleCardDelAutoFundMutation = async (singleCardAutoloadId) => {
		setState({ loading: true });

		await bulkDelCardAutoFundMutation.mutateAsync({
			prepaid_card_autoloads: [
				{
					card_id: editCardId,
					autoload_id: singleCardAutoloadId || null
				}
			],
		}, {
			onSettled: () => resetState()
		});
	}

	//
	const handleSaveSingleCardAutoFund = async (singleCardAutoloadId) => {
		if (singleCard.amountLoad < AMOUNT_LOAD_MIN) {
			handleSingleCardDelAutoFundMutation(singleCardAutoloadId);
			return;
		}

		handleSingleCardAutoFundMutation(singleCardAutoloadId);
	}

	//
	const handleAmountLoadChange = (val, type) => {
		setState({
			autoLoadType: type,
			amountLoad: val,
		});
	}

	//
	const handleBulkAutoloadMutation = async () => {
		setState({ loading: true });

		await bulkCardAutoFundMutation.mutateAsync({
			prepaid_card_autoloads: selectedCards,
			autoload_type_id: autoLoadType,
			amount_below: amountBelow || 0,
			amount_load: amountLoad,
		}, {
			onSettled: () => setState({
				selectedCards: [],
				autoLoadType: null,
				amountBelow: '',
				amountLoad: '',
				loading: false,
			})
		});
	}

	//
	const handleBulkDelAutoloadMutation = async () => {
		setState({ loading: true });

		await bulkDelCardAutoFundMutation.mutateAsync({
			prepaid_card_autoloads: selectedCards,
		}, {
			onSettled: () => resetState()
		});
	}

	//
	const confirmBulkAutoloadDelete = () => {
		setState({ confirmModal: false });
		handleBulkDelAutoloadMutation();
	}

	//
	const cancelBulkAutoloadDelete = () => {
		setState({ confirmModal: false });
	}

	//
	const handleApplyToSelected = () => {
		if (!selectedCards.length) {
			notification.warning({ message: 'No cards currently selected.', placement: 'top' });
			return;
		}

		if (amountLoad < AMOUNT_LOAD_MIN) {
			setState({ confirmModal: true });
			return;
		}

		handleBulkAutoloadMutation();
	}

	//
	const handlePageChange = (page) => {
		setCardsState({ currentPage: page });
	}

	//
	const handleSearch = debounce((evt) => {
		if (evt.target.value?.length > 2) {
			setCardsState({ cardSearchFilter: evt.target.value.toLowerCase() });
		} else {
			setCardsState({ cardSearchFilter: null });
		}

		setCardsState({ currentPage: 1 });
	}, 400);

	//
	const handlePageSizeChange = (current, size) => {
		setCardsState({ limit: size, currentPage: 1 });
	}

	//
	const columns = [
		{
			title: <Space>
				Select All
				<Checkbox checked={selectAll} onChange={handleSelectAllCards} />
			</Space>,
			dataIndex: 'prepaid_card_id',
			key: 'selectAll',
			align: 'center',
			render: (value, record) => <Checkbox
				checked={!!selectedCards.find(item => item.card_id === value)}
				onChange={(evt) => handleSelectCard(evt, record)}
			/>,
			width: 150,
		},
		{
			title: 'Card',
			dataIndex: 'card_number',
			key: 'card',
			render: (value, record) => <Space direction='vertical' size={0}>
				{record?.card_holder?.name}
				{`**** **** **** ${value}`}
			</Space>
		},
		{
			title: 'Trigger Amount',
			dataIndex: ['prepaid_card_auto_load', 'amount_below'],
			key: 'triggerAmount',
			render: (value, record) => <>
				{editCardId === record.prepaid_card_id
					? <InputNumber
						min={0}
						controls={false}
						disabled={isActivated(record)}
						value={singleCard.amountBelow}
						onChange={handleSingleAmountBelowChange}
						formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
						parser={(val) => numberFormat(val)}
					/>
					: value ? accountingFormat(value) : '-'
				}
			</>
		},
		{
			title: 'Auto Fund By Amount',
			dataIndex: ['prepaid_card_auto_load', 'amount_load'],
			key: 'autoFundByAmount',
			render: (value, record) => <>
				{editCardId === record.prepaid_card_id
					? <InputNumber
						min={0}
						controls={false}
						disabled={isActivated(record)}
						value={singleCard.autoLoadType === 1 ? singleCard.amountLoad : ''}
						onChange={(val) => handleSingleAmountLoadChange(val, 1)}
						formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
						parser={(val) => numberFormat(val)}
					/>
					: record?.prepaid_card_auto_load?.autoload_type_id === 1 ? accountingFormat(value) : '-'
				}
			</>
		},
		{
			title: 'Auto Load Up To Total Value',
			dataIndex: ['prepaid_card_auto_load', 'amount_load'],
			key: 'autoLoadUpToTotalValue',
			render: (value, record) => <>
				{editCardId === record.prepaid_card_id
					? <InputNumber
						min={0}
						controls={false}
						value={singleCard.autoLoadType === 2 ? singleCard.amountLoad : ''}
						onChange={(val) => handleSingleAmountLoadChange(val, 2)}
						formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
						parser={(val) => numberFormat(val)}
					/>
					: record?.prepaid_card_auto_load?.autoload_type_id === 2 ? accountingFormat(value) : '-'
				}
			</>
		},
		{
			title: 'Currency',
			dataIndex: 'base_currency',
			key: 'currency',
			render: (value) => <Space size={4}>
				{FLAGS[value] && <Image src={FLAGS[value]} alt={value} preview={false} width={36} />}
				{value}
			</Space>
		},
		{
			title: '',
			key: 'cancel',
			render: (v, record) => <>
				{editCardId === record?.prepaid_card_id ? <Button onClick={() => cancelEditMode()}>Cancel</Button> : ''}
			</>,
			align: 'right'
		},
		{
			title: '',
			key: 'action',
			render: (v, record) => (<>
				{editCardId === record?.prepaid_card_id
					? <Button
						style={{ width: '62px' }}
						type='primary'
						onClick={() => handleSaveSingleCardAutoFund(record?.prepaid_card_auto_load?.id)}
						disabled={!cardAutoloadDataChanged() || loading}
					>
						Save
					</Button>
					: <Button
						style={{ width: '62px' }}
						type='primary'
						onClick={() => handleEditClick(record)}
						disabled={loading}
					>
						Edit
					</Button>
				}
			</>),
			align: 'right'
		}
	];

	return (
		<>
			<Modal
				title={<><ExclamationCircleOutlined style={{ color: 'red' }} /> Remove autofund from selected?</>}
				open={confirmModal}
				onOk={confirmBulkAutoloadDelete}
				onCancel={cancelBulkAutoloadDelete}
				okText="Confirm"
				cancelText="CANCEL"
				okButtonProps={{ disabled: loading }}
				cancelButtonProps={{ disabled: loading }}
			>
				<p>Not setting a 'Fund By Amount' and 'Up To Total Value' or setting them to 0 (zero) will delete the autofund for selected cards.</p>
			</Modal>

			<Row justify="center" className="m-t-30" gutter={[30]}>
				<Col xs={24} lg={4}>
					<div style={{ textAlign: 'center' }}>
						<Typography.Text className="fs-18px medium">Trigger Amount</Typography.Text>
						<InputNumber
							value={amountBelow}
							className="full-percent-width"
							min={0}
							precision={2}
							step={1}
							onChange={(val) => setState({ amountBelow: val })}
							size="large"
							placeholder="Enter Limit Value"
							controls={false}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
					</div>
				</Col>
				<Col xs={24} lg={4}>
					<div style={{ textAlign: 'center' }}>
						<Typography.Text className="fs-18px medium">Auto Fund By Amount</Typography.Text>
						<InputNumber
							className="full-percent-width"
							value={autoLoadType === 1 ? amountLoad : ''}
							min={0}
							onChange={(val) => handleAmountLoadChange(val, 1)}
							size="large"
							placeholder="Enter Limit Value"
							controls={false}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
					</div>
				</Col>
				<Col xs={28} lg={6}>
					<div style={{ textAlign: 'center' }}>
						<Typography.Text className="fs-18px medium">Auto Load Up To Total Value</Typography.Text>
						<InputNumber
							className="full-percent-width"
							value={autoLoadType === 2 ? amountLoad : ''}
							min={0}
							onChange={(val) => handleAmountLoadChange(val, 2)}
							size="large"
							placeholder="Enter Limit Value"
							controls={false}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
					</div>
				</Col>
			</Row>

			<Row justify="center" className="m-t-30" >
				<Col xs={24} lg={8} style={{ textAlign: 'center' }}>
					<Button disabled={!selectedCards.length} onClick={() => handleApplyToSelected()} type="primary">Apply to Selected</Button>
				</Col>
			</Row>

			<Row className="m-t-20">
				<Col xxl={4}>
					<Input.Search onChange={handleSearch} placeholder="Search By Card Holder" />
				</Col>
			</Row>

			<Row className="m-t-10">
				<Col span={24}>
					<CustomPagination
						loading={cardsQueryIsFetching || loading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
					<CustomTable
						columns={columns}
						loading={loading}
						dataSource={prepaidCards?.data || []}
						rowKey="prepaid_card_id"
						styleAllRows
						className="spaced-rows"
						headerColor="green"
					/>
					<CustomPagination
						loading={cardsQueryIsFetching || loading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
				</Col>
			</Row>
		</>
	)
}

export default BulkAutoFund;
