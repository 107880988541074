import { useQuery } from "@tanstack/react-query";
import { APIROUTES } from "Constants/ApiRoutes";
import { usePaymentReportsState } from "../store";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";

const usePaymentReportsQuery = () => {
    const extendedApiRequest = useExtendedApiRequest();
    const selectedToMonth = usePaymentReportsState(state => state.selectedToMonth);
    const selectedFromMonth = usePaymentReportsState(state => state.selectedFromMonth);
    const setState = usePaymentReportsState(state => state.setState);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
		let qString = '';
		if(!!selectedFromMonth && !!selectedToMonth) {
			qString += `?from=${encodeURIComponent(selectedFromMonth?.date?.format('YYYY-MM-DD'))}`;
			qString += `&to=${encodeURIComponent(selectedToMonth?.date?.format('YYYY-MM-DD'))}`;
		}

        let url = `${APIROUTES.get.monthly_payment_reports}${qString}`;
        const data = await extendedApiRequest({
            baseUrl: process.env.REACT_APP_AUX_API_BASE_URL,
            path: url,
            method: 'GET',
            config: {
                responseType: "application/json",
            },
        });
        return data;
    }

    const query = useQuery({
        queryKey: ['_monthly_payment_reports', {selectedFromMonth, selectedToMonth}],
        queryFn: fetchQuery,
        onSuccess: (resData) => {
            setState({ totalPages: resData?.data?.pager?.count ?? 0 });
        },
    });

    return query;
}

export { usePaymentReportsQuery };