import create from "zustand";
import { apiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { makeAccessTokenExpiryTime } from "Utils";
import { useQueryClient } from "@tanstack/react-query";

// @TODO: remove auth state from login responsibility
// @TODO: possibly refactor to react context provider
const useLoginState = create((set, get) => ({
	loading: false,
	accessToken: undefined,
	getRefreshToken: () => localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY),
	setRefreshToken: (token) => localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY, token),
	roleId: null,
	isRefreshing: false,
	subscribers: [],
	history: [],
	setState: (data) => set({ ...data }),

	fetchConfig: async (auth) => {
		try {
			const res = await apiRequest('configuration', 'GET', undefined, auth);
			useLoginStore.setState({
				config: res?.data || undefined
			});

		} catch (error) {
			get().clearAuth();
			return Promise.reject(error);
		}
	},

	addSubscriber: (callback) => {
		set((state) => ({ subscribers: [...state.subscribers, callback] }));
	},

	notifySubscribers: (authHeader) => {
		get().subscribers.forEach((callback) => callback(authHeader));
	},

	refreshToken: async () => {
		set({ isRefreshing: true });

		const payload = {
			grant_type: 'refresh_token',
			refresh_token: get().getRefreshToken(),
			client_id: useLoginStore.getState().getTokenClientId()
		}

		// @TODO: Refactor to react-query
		const response = await apiRequest('oauth2/token', 'POST', payload, undefined);

		if (!response?.access_token && !response?.refresh_token) {
			return Promise.reject(response);
		}

		set({
			accessToken: {
				token: response?.access_token,
				type: response?.token_type,
				expires: makeAccessTokenExpiryTime(response.expires_in),
			},
			roleId: response?.role_id,
			isRefreshing: false,
		});

		get().setRefreshToken(response?.refresh_token);

		let authHeader = response?.token_type + ' ' + response?.access_token;

		get().notifySubscribers(authHeader);
	},

	getAuthHeader: async () => {
		const refreshToken = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY);

		if (!refreshToken) {
			get().clearAuth();
			return Promise.reject();
		}

		if (
			!get().accessToken ||
			get().accessToken.expires.getTime() <= Date.now()
		) {
			if (get().isRefreshing) {
				return new Promise((resolve) => get().addSubscriber(resolve));
			} else {
				try {
					await get().refreshToken();
				} catch (error) {
					get().clearAuth();
					return Promise.reject(error);
				}
			}
		}

		set({ isRefreshing: false });
		return get().accessToken.type + ' ' + get().accessToken.token;
	},

	clearAuth: () => {
		set({
			accessToken: undefined,
			subscribers: [],
		});

		localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY);
		localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY);
	}
}));

const useLoginStore = create((set) => ({
	getTokenClientId: () => localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY),
	config: undefined,
	setStore: (data) => set(data),
}));

// Use only for click events.
function useLogout() {
	const queryClient = useQueryClient();

	return (() => {
		useLoginState.setState({ accessToken: undefined });
		localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY);
		localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY);
		// Clear query cache
		queryClient.cancelQueries();
		queryClient.clear();
		// Manual javascript rediredt causes reload which will clear all app memory
		// Technical debt, need to find a better solution to clear web app memory
		window.location.href = URLS.Login;
	})
}

export { useLoginState, useLoginStore, useLogout };

