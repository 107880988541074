import { Button, Col, Form, Input, Row, Typography, notification } from "antd";
import { useApiRequest } from "Hooks/API";
import { useManageSpecificCardState } from "../store";
import { APIROUTES } from "Constants/ApiRoutes";

function ViewCardPin({onCancel = () => {}, cardId = null}) {
    const setManageCardState = useManageSpecificCardState((s) => s.setState);
    const activateLoading = useManageSpecificCardState((s) => s.activateLoading);
    const [form] = Form.useForm();
    const apiRequest = useApiRequest();

    const verifyPassword = async () => {
        try {
            setManageCardState({ activateLoading: true });
            // eslint-disable-next-line
            const data = await apiRequest(`${APIROUTES.post.postCardPin}?prepaidCard=${cardId}`, 'POST', {
				$password: form.getFieldValue('password'),
				prepaidCardId: cardId
			});
            
            if (data?.data?.success) {
                setManageCardState({
                    modalContent: 'returnToDashboard',
                    modalOpen: true,
                    returnToDashboardCardHeading: 'CARD PIN',
                    returnToDashboardCardDescription: 'Your PIN number is',
                    returnToDashboardCardBold: data?.data?.data?.CurrentPin,
                });
            } else {
                notification.error({ message: data?.response?.data?.error?.message, placement: 'top' });
            }
        } catch (err) {
            notification.error({ message: 'Something went wrong!', placement: 'top' });
        } finally {
            setManageCardState({
                activateLoading: false
            })
        }
    }

    return (<>
        <Row>
            <Col>
                <Typography.Text className="muli semi-bold fs-18px dark-green">CARD PIN</Typography.Text>
            </Col>
        </Row>
        <Form
				layout="vertical"
				onFinish={verifyPassword}
                form={form}
				requiredMark={false}
			>
        <Row className="m-t-20">
            <Col span={24}>
                    <Form.Item name='password' rules={[
							{
							required: true,
							message: "Please Enter Password",
							},
						]}>
                        <Input.Password
							placeholder="Enter Password"
						/>
                    </Form.Item>
            </Col>
        </Row>
        <Row justify="space-between">
            <Col>
                <Button type="primary" onClick={onCancel}>Close</Button>
            </Col>
            <Col>
                <Button type="primary" htmlType="submit" loading={activateLoading}>Submit</Button>
            </Col>
        </Row>
        </Form>
    </>)
}

export default ViewCardPin;
