import { Grid, Button, Checkbox, Col, Image, Row, Select, Space, Tooltip, Typography, Pagination, notification, Spin } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FaSyncAlt, FaRedoAlt } from "react-icons/fa";
import moment from "moment";
import { FLAGS } from "Constants/Images";
import { AiFillExclamationCircle, AiOutlinePaperClip, AiFillCheckCircle } from "react-icons/ai"
import CustomTable from "App/Components/CustomTable";
import { colors } from "Constants/Style";
import { useTransactionsBankFeedsState } from "App/Pages/Accounting/Transactions/BankFeeds/store";
import { useAccountingBankFeedsQuery, useResetXeroBankFeeds } from "App/Pages/Accounting/Transactions/BankFeeds/query";
import Spinner from "App/Components/Spinner";
import { TransactionsPageNav } from "App/Pages/Accounting/Transactions/components";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { firstAndLast4CardNumber, accountingFormat, pluralize } from "Utils";
import { useQueryClient } from "@tanstack/react-query";
import { FORMATS } from "Constants/Formats";
import { useSyncBankFeedsMutation } from "./query/useSyncBankFeedsMutation";
import { useEffect } from "react";
import { useBankAccountsQuery } from "App/Pages/Accounting/Configuration/query";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";

const { useBreakpoint } = Grid;
const paginationStyles = {
    marginTop: '30px',
    marginBottom: '20px',
    padding: '8px 18px',
    background: '#eee',
    borderRadius: '8px'
};

function AccountingBankFeedTransactions() {
    const breakpoint = useBreakpoint();

    const accountingLogo = useConfigurationState(state => state.accountingLogo);
    const codatBankAccountId = useConfigurationState(state => state.codatBankAccountId);
    const loading = useTransactionsBankFeedsState(state => state.loading);
    const selectedToSync = useTransactionsBankFeedsState(state => state.selectedToSync);
    const currentPage = useTransactionsBankFeedsState(state => state.page);
    const totalPages = useTransactionsBankFeedsState(state => state.totalPages);
    const limit = useTransactionsBankFeedsState(state => state.limit);
    const syncStatus = useTransactionsBankFeedsState(state => state.syncStatus);
    const syncStatuses = useTransactionsBankFeedsState(state => state.syncStatuses);
    const isSyncing = useTransactionsBankFeedsState(state => state.isSyncing);
    const setState = useTransactionsBankFeedsState(state => state.setState);
    const bankFeedsQuery = useAccountingBankFeedsQuery();
    const resetRefreshFeed = useResetXeroBankFeeds();
    const syncFeedsMutation = useSyncBankFeedsMutation(id => id);
    const queryClient = useQueryClient();
    const bankAccountsQuery = useBankAccountsQuery();

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            setState({ page: 1, limit: 10, })
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if( !codatBankAccountId ) {
            if(syncStatus === 3) {
                notification.info({ message: 'Please select the account you wish to sync your Volopa transactions with.', placement: 'top' });
            }
            
            navigate(URLS.Configuration);
        }
    // eslint-disable-next-line
    }, [codatBankAccountId]);

    useEffect(() => {
        if( !!bankAccountsQuery.data?.response?.error ) {
            if(syncStatus === 3) {
                notification.info({ message: 'Please wait while accounts are being synchronized.', placement: 'top' });
            }
            
            navigate(URLS.Configuration);
        }
    // eslint-disable-next-line
    }, [bankAccountsQuery.data]);

    //
    const handleSelectAllItems = (evt) => {
        if (!evt.target.checked) {
            setState({ selectedToSync: [] });
            return;
        }

        setState({
            selectedToSync: bankFeedsQuery.data.data.map(item => {
                return item.id;
                // return item.transaction.id;
            })
        });
    }

    //
    const handleSelectItemToSync = (evt, id) => {
        if (!evt.target.checked) {
            setState({ selectedToSync: selectedToSync.filter(item => item !== id) });
            return;
        }

        let item = selectedToSync.find(item => item === id);

        if (!item) {
            setState({ selectedToSync: [...selectedToSync, id] });
        }
    }

    //
    const handleSyncSelected = async () => {

        if (!selectedToSync.length || !selectedToSync) {
            return;
        }
        setState({ isSyncing: true });
        let mutations = [];
        selectedToSync.forEach(id => {
            mutations.push(
                syncFeedsMutation.mutateAsync(id)
            )
        });

        try {
            await Promise.all(mutations);
            setState({ selectedToSync: [] });
            notification.success({ message: 'Transactions have been submitted for syncing!', placement: 'top' });
        } catch (err) {
            notification.error({ message: 'Something went wrong while submitting transactions for syncing.', placement: 'top' });
        } finally {
            setState({ isSyncing: false })
        }

    }

    //
    const handlePageChange = (page, pageSize) => {
        setState({
            page,
        });
    }

    //
    const handlePageSizeChange = (current, size) => {
        setState({
            limit: size,
            page: current,
        });
    }

    //
    const columns = [
        {
            title: 'Date',
            dataIndex: ['transaction_time'],
            key: 'txn_date',
            sorter: (a, b) => a.update_time && b.update_time && moment(a.update_time, 'DD-MM-YYYY').diff(moment(b.update_time, 'DD-MM-YYYY')),
            render: (text, record, index) => moment(text).format('DD-MM-YYYY')
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
            sorter: (a, b) => {
                if (a?.transaction_id && b?.transaction_id) {
                    return Number(a?.transaction_id) -  Number(b?.transaction_id)
                }
                return 0;
            },
            render: (text, record, index) => `${text}`
        },
        {
            title: 'Card',
            dataIndex: ['transaction', 'prepaid_card'],
            key: 'card',
            render: (text, record, index) => record ?
                <Space direction="vertical">
                    <div>
                        {text?.card_holder?.title} {' '}
                        {text?.card_holder?.first_name} {' '}
                        {text?.card_holder?.last_name}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {firstAndLast4CardNumber(text.card_number)}
                    </div>
                </Space> : ''
        },
        {
            title: 'Currency',
            dataIndex: ['transaction', 'currency', 'currency'],
            key: 'currency',
            sorter: (a, b) => (a?.transaction?.currency?.currency && b?.transaction?.currency?.currency) && a?.transaction?.currency?.currency.localeCompare(b?.transaction?.currency?.currency),
            render: (text, record, index) => <Space size={12}>
                {FLAGS?.[text] ? <Image src={FLAGS[text]} alt={text} preview={false} width={30} /> : null}
                {text}
            </Space>
        },
        {
            title: 'Amount',
            dataIndex: ['transaction', 'amount'],
            key: 'amount',
            sorter: (a, b) => {
                if(a?.transaction?.amount && b?.transaction?.amount) {
                    const aValue = Number((a?.transaction.debit_credit === 1 ? '+' : '-') + a?.transaction?.amount); 
                    const bValue = Number((b?.transaction.debit_credit === 1 ? '+' : '-') + b?.transaction?.amount); 
                    return aValue - bValue
                }
            },
            render: (text, record) => <Typography.Text type={record.transaction.debit_credit === 1 ? 'success' : 'danger'}>
                {record.transaction.debit_credit === 1 ? '+' : '-'}{accountingFormat(text)}
            </Typography.Text>
        },
        {
            title: <Space align='center'>
                {syncStatus !== "1"
                    ? <> Select All
                        <Checkbox defaultChecked={false} onChange={handleSelectAllItems} />
                    </>
                    : <>Status</>
                }
            </Space>,
            dataIndex: 'synch_id',
            key: 'synch_id',
            align: 'center',
            render: (synch_id, record) => {
                if (synch_id === 1) {
                    return <AiFillCheckCircle size={24} color={colors.success} />
                } else if (synch_id === 5) {
                    return <Typography.Text type="success">Pending</Typography.Text>
                } else {
                    return <Space align='center'>
                        {synch_id === 2
                            ? <Tooltip title="Sync error" color={colors.danger} >
                                <AiFillExclamationCircle style={{ transform: 'translateY(5px)' }} size={24} color={colors.danger} />
                            </Tooltip>
                            : <span style={{ display: 'inline-block', width: '24px', height: '1px' }} aria-hidden="true"></span>
                        }
                        <Checkbox checked={selectedToSync.includes(record.id)} onChange={(evt) => handleSelectItemToSync(evt, record.id)} />
                    </Space>
                }
            }
        }
    ];

    const bankFeedsQueryLoading = bankFeedsQuery.isLoading || bankFeedsQuery.isFetching || bankFeedsQuery.isRefetching;
    //
    const TablePagination = () => {
        const placeholderStyles = {
            marginTop: '30px',
            marginBottom: '20px',
            padding: '8px 18px',
            background: 'rgb(238, 238, 238)',
            borderRadius: '8px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
        }
        return (
            bankFeedsQueryLoading ? 
            <div style={placeholderStyles}>
                    <Spin size="small" />
                </div> :
        <>
            {bankFeedsQuery.data?.pager?.count < 11
                ? <div
                    style={{
                        marginTop: '30px',
                        marginBottom: '20px',
                        padding: '8px 18px',
                        background: 'rgb(238, 238, 238)',
                        borderRadius: '8px',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {bankFeedsQuery.data?.pager?.count < 1 ? `No bank feeds synced.` : `${bankFeedsQuery.data?.pager?.count} bank ${pluralize(bankFeedsQuery.data?.pager?.count,'feed', 'feeds')} synced.`}
                </div>
                : <Pagination
                    disabled={totalPages < 11}
                    style={paginationStyles}
                    onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                    onShowSizeChange={(current, size) => handlePageSizeChange(current, size)}
                    pageSize={limit}
                    total={totalPages}
                    current={currentPage}
                    showSizeChanger
                />
            }
        </>)
    }

    // eslint-disable-next-line
    const _columns = {
        expenseInformation: [
            {
                title: 'Timestamp',
                dataIndex: 'timestamp',
                key: 'timestamp',
                sorter: (a, b) => a.timestamp && b.timestamp && moment(a.timestamp, 'DD-MM-YYYY').diff(moment(b.timestamp, 'DD-MM-YYYY'))
            },
            {
                title: 'Card',
                dataIndex: 'card',
                key: 'card',
                render: (text) => text ?
                    <Space direction="vertical">
                        {text.name}
                        {text.number}
                    </Space> :
                    ''
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => a.description && b.description && a.description.localeCompare(b.description)
            },
            {
                title: 'Account Code',
                dataIndex: 'accountCode',
                key: 'accountCode',
            },
            {
                title: 'Attachments',
                dataIndex: 'attachments',
                key: 'attachments',
                align: 'center',
                render: (text) => text ? <AiOutlinePaperClip size={24} /> : ''
            },
            {
                title: 'Card Currency',
                dataIndex: 'cardCurrency',
                key: 'cardCurrency',
                sorter: (a, b) => a.cardCurrency && b.cardCurrency && a.cardCurrency.localeCompare(b.cardCurrency),
                render: (text) => (
                    FLAGS[text] ?
                        <Space size={12}>
                            <Image src={FLAGS[text]} alt={text} preview={false} width={30} />
                            {text}
                        </Space> :
                        text
                )
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: (a, b) => a.amount - b.amount
            },
            {
                title: <Space align='center'>Select All <Checkbox /></Space>,
                dataIndex: 'selectAll',
                key: 'selectAll',
                align: 'center',
                render: (text) => (
                    text && text.error ?
                        <Tooltip title={text.error} color={colors.danger} >
                            <AiFillExclamationCircle size={24} color={colors.danger} />
                        </Tooltip> :
                        <Checkbox />
                )
            }
        ],
        bankFeeds: [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: text => moment(text, 'DD-MM-YYYY').format(FORMATS.date),
                sorter: (a, b) => a.date && b.date && moment(a.date, 'DD-MM-YYYY').diff(moment(b.date, 'DD-MM-YYYY'))
            },
            {
                title: 'Transacrion ID',
                dataIndex: 'transactionId',
                key: 'transactionId',
                sorter: (a, b) => a.transactionId && b.transactionId && a.transactionId - b.transactionId
            },
            {
                title: 'Card',
                dataIndex: 'card',
                key: 'card',
                sorter: (a, b) => a.card && b.card && a.card.localeCompare(b.card)
            },
            {
                title: 'Card Currency',
                dataIndex: 'cardCurrency',
                key: 'cardCurrency',
                sorter: (a, b) => a.cardCurrency && b.cardCurrency && a.cardCurrency.localeCompare(b.cardCurrency),
                render: text =>
                    <Space size={12}>
                        {FLAGS[text] ? <Image src={FLAGS[text]} alt={text} preview={false} width={30} /> : ''}
                        {text}
                    </Space>
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: (a, b) => a.amount && b.amount && a.amount - b.amount,
                render: text => <Typography.Text type={text >= 0 ? 'success' : 'danger'}>
                    {(text > 0 && '+') + text}
                </Typography.Text>
            },
            {
                title: <Space align='center'>Select All <Checkbox /></Space>,
                dataIndex: 'selectAll',
                key: 'selectAll',
                align: 'center',
                render: () => <Checkbox />
            }
        ]
    };

    // eslint-disable-next-line
    const _data = {
        expenseInformation: [
            {
                key: 0,
                timestamp: '01-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 124
            },
            {
                key: 1,
                timestamp: '02-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 125
            },
            {
                key: 2,
                timestamp: '02-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 125,
                selectAll: {
                    error: 'No Account Code Selected'
                }
            },
            {
                key: 3,
                timestamp: '02-03-2022',
                card: {
                    name: 'Mr. Murray Bagshaw',
                    number: '52627 **** **** 9303'
                },
                description: 'Linkedin Transactions',
                accountCode: 'Marketing',
                attachments: true,
                cardCurrency: 'GBP',
                amount: 125
            }
        ],
        bankFeeds: [
            {
                key: 0,
                date: '01-03-2022',
                transactionId: 791104242,
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -100
            },
            {
                key: 1,
                date: '01-03-2022',
                transactionId: 461104111,
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -250
            },
            {
                key: 2,
                date: '01-03-2022',
                transactionId: '061177801',
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -195
            },
            {
                key: 3,
                date: '01-03-2022',
                transactionId: 661105531,
                card: '52627 **** **** 9303',
                cardCurrency: 'GBP',
                amount: -109
            },
            {
                key: 4,
                date: '01-03-2022',
                transactionId: 5854104242,
                card: 'Company Wallet',
                cardCurrency: 'GBP',
                amount: 200
            }
        ]
    };

    //
    const handleSyncStatusChange = (val) => {
        setState({ syncStatus: val });
        queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_bank_feeds'], exact: false });
    }

    const handleReset = () => {
        resetRefreshFeed.mutateAsync()
    }

    //
    return (
        <>

            {loading ? <Spinner /> : (<>
                <Row>
                    <Col span={24}>
                        <PageDescription title="Review & Sync Your Latest Transactions" text='-' />
                    </Col>
                </Row>

                <Row className="m-t-30" justify="space-between">
                    <Col>
                        <Select
                            defaultValue={syncStatus}
                            placeholder="Select sync status"
                            onChange={(val) => handleSyncStatusChange(val)}
                            className="dark-green select-b-g full-percent-width"
                        >
                            {syncStatuses.map(stat =>
                                <Select.Option key={stat.id} value={stat.val}>
                                    {stat.name}
                                </Select.Option>
                            )}
                        </Select>
                    </Col>
                    <Col style={{ marginTop: breakpoint.xs ? '12px' : 0 }}>
                        <Image src={accountingLogo} preview={false} style={{ width: 80 }} />
                    </Col>
                </Row>

                <Row className="m-t-30" justify="space-between">
                    <Col>
                        <TransactionsPageNav />
                    </Col>

                    <Col style={{ marginTop: breakpoint.xs ? '12px' : 0 }}>
                        <Button style={{ visibility: syncStatus !== "1" ? 'visible' : 'hidden' }} disabled={syncStatus === "1" || selectedToSync.length} type="primary" size="large" className="m-r-12" onClick={handleReset}>
                            <Space align='start'>
                                <FaRedoAlt style={{ marginBottom: '-3px' }} />
                                Reset/Refresh
                            </Space>
                        </Button>
                        <Button
                            disabled={syncStatus === "1" || !selectedToSync.length}
                            type="primary"
                            size="large"
                            onClick={handleSyncSelected}
                            loading={isSyncing}
                        >
                            <Space align='start'>
                                <FaSyncAlt style={{ marginBottom: '-3px' }} />
                                Sync Selected
                            </Space>
                        </Button>
                    </Col>
                </Row>

                <Row className="m-t-10">
                    <Col span={24}>
                        <TablePagination />
                        <CustomTable
                            rowKey="id"
                            loading={bankFeedsQuery.isLoading || bankFeedsQuery.isFetching || bankFeedsQuery.isRefetching}
                            columns={columns}
                            dataSource={bankFeedsQuery.data?.data}
                            headerColor="green"
                            styleAllRows
                            className="spaced-rows"
                            rowClassName='medium fs-18px pointer'
                        />
                        {totalPages > 10 && <TablePagination />}
                    </Col>
                </Row>
            </>)}
        </>
    );
}

export default AccountingBankFeedTransactions;
