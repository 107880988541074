import FullScreenModal from "App/Components/FullScreenModal";
import {
    Card,
    Col,
    Row,
    Typography,
} from "antd";
import CustomTable from "App/Components/CustomTable";
import { usePrepaidCardQuery, usePrepaidCardTierStatusQuery } from "Hooks/Queries";
import { accountingFormat } from "Utils";
import CardImage from "App/Components/CardImage";

function FullPrepaidCardDetailsModal({
    open = false,
    onCancel = () => { },
    cardId,
}) {
    const { data: cardQuery, isFetched } = usePrepaidCardQuery(cardId, open);
    const { data, isLoading } = usePrepaidCardTierStatusQuery(cardId, isFetched);


    //
    const cardDetails = {
        rowKey: "id",
        columns: [
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
            },
            {
                title: "First Name",
                dataIndex: "firstName",
                key: "firstName",
            },
            {
                title: "Last Name",
                dataIndex: "lastName",
                key: "lastName",
            },
            {
                title: "Address",
                dataIndex: "address",
                key: "address",
            },
            {
                title: "City",
                dataIndex: "city",
                key: "city",
            },
            {
                title: "Postcode",
                dataIndex: "postcode",
                key: "postcode",
            },
            {
                title: "Country",
                dataIndex: "country",
                key: "country",
            },
            {
                title: "Embossed Name",
                dataIndex: "embossedName",
                key: "embossedName",
            },
        ],
        dataSource: [{
            id: 1,
            title: cardQuery?.card_holder?.title,
            firstName: cardQuery?.card_holder?.first_name,
            lastName: cardQuery?.card_holder?.last_name,
            address: `${cardQuery?.card_holder?.address_line_1} ${cardQuery?.card_holder?.address_line_2}`,
            city: cardQuery?.card_holder?.first_name,
            postcode: cardQuery?.card_holder?.postcode,
            country: cardQuery?.card_holder?.country_code,
            embossedName: cardQuery?.card_holder?.embossed_line_4,
        }],
    };

    //
    const generalLimits = {
        rowKey: "id",
        columns: [
            {
                title: "Minimum POS Amount",
                dataIndex: "minimumPOSAmount",
                key: "minimumPOSAmount",
            },
            {
                title: "Maximum POS Amount",
                dataIndex: "maximumPOSAmount",
                key: "maximumPOSAmount",
            },
        ],
        dataSource: [
            {
                id: 1,
                minimumPOSAmount: `GBP ${data?.tier_values?.min_pos_amount && accountingFormat(data?.tier_values?.min_pos_amount)}`,
                maximumPOSAmount: `GBP ${data?.tier_values?.max_pos_amount && accountingFormat(data?.tier_values?.max_pos_amount)}`,
            },
        ],
    };

    //
    const dailyLimits = {
        rowKey: "id",
        loading: isLoading,
        columns: [
            {
                title: "ATM Amount",
                dataIndex: "ATMAmount",
                key: "ATMAmount",
            },
            {
                title: "ATM Count",
                dataIndex: "ATMCount",
                key: "ATMCount",
            },
            {
                title: "POS Amount",
                dataIndex: "POSAmount",
                key: "POSAmount",
            },
            {
                title: "POS Count",
                dataIndex: "POSCount",
                key: "POSCount",
            },
        ],
        dataSource: [
            {
                id: 1,
                ATMAmount: `GBP ${data?.values?.atm_day_amount && accountingFormat(data?.values?.atm_day_amount)} of ${data?.tier_values?.max_atm_day_amount && accountingFormat(data?.tier_values?.max_atm_day_amount)}`,
                ATMCount: `${data?.values?.atm_day_count} of ${data?.tier_values?.max_atm_day_count}`,
                POSAmount: `GBP ${data?.values?.pos_day_amount && accountingFormat(data?.values?.pos_day_amount)} of ${data?.tier_values?.max_atm_day_amount && accountingFormat(data?.tier_values?.max_pos_day_amount)}`,
                POSCount: `${data?.values?.pos_day_count} of ${data?.tier_values?.max_pos_day_count}`,
            },
        ],
    };

    const fourDayLimits = {
        rowKey: "id",
        loading: isLoading,
        columns: [
            {
                title: "ATM Amount",
                dataIndex: "ATMAmount",
                key: "ATMAmount",
            },
            {
                title: "ATM Count",
                dataIndex: "ATMCount",
                key: "ATMCount",
            },
            {
                title: "POS Amount",
                dataIndex: "POSAmount",
                key: "POSAmount",
            },
            {
                title: "POS Count",
                dataIndex: "POSCount",
                key: "POSCount",
            },
        ],
        dataSource: [
            {
                id: 1,
                ATMAmount: `GBP ${data?.values?.atm_four_day_amount && accountingFormat(data?.values?.atm_four_day_amount)} of ${data?.tier_values?.max_atm_four_day_amount && accountingFormat(data?.tier_values?.max_atm_four_day_amount)}`,
                ATMCount: `${data?.values?.atm_four_day_count} of ${data?.tier_values?.max_atm_four_day_count}`,
                POSAmount: `GBP ${data?.values?.pos_four_day_amount && accountingFormat(data?.values?.pos_four_day_amount)} of ${data?.tier_values?.max_atm_four_day_amount && accountingFormat(data?.tier_values?.max_pos_four_day_amount)}`,
                POSCount: `${data?.values?.pos_four_day_amount} of ${data?.tier_values?.max_pos_four_day_count}`,
            },
        ],
    };

    //
    const yearLimits = {
        rowKey: "id",
        columns: [
            {
                title: "ATM Amount",
                dataIndex: "ATMAmount",
                key: "ATMAmount",
            },
            {
                title: "POS Amount",
                dataIndex: "POSAmount",
                key: "POSAmount",
            },
        ],
        dataSource: [
            {
                id: 1,
                ATMAmount: `GBP ${data?.values?.atm_year_amount && accountingFormat(data?.values?.atm_year_amount)} of ${data?.tier_values?.max_atm_year_amount && accountingFormat(data?.tier_values?.max_atm_year_amount)}`,
                POSAmount: `GBP ${data?.values?.pos_year_amount && accountingFormat(data?.values?.pos_year_amount)} of ${data?.tier_values?.max_pos_year_amount && accountingFormat(data?.tier_values?.max_pos_year_amount)}`,
            },
        ],
    };

    return (
        <FullScreenModal
            open={open}
            onCancel={onCancel}
        >
            <Row className="m-t-20" gutter={[16, 12]}>
                <Col xxl={4} xl={4} lg={6} md={8} sm={10} xs={12}>
                    <Card className="b-2-g">
                        <Row
                            justify="center"
                            style={{ marginTop: "12%", marginBottom: "12%" }}
                        >
                            <Col xxl={20} xl={20} lg={20} md={20}>
                                <CardImage cardProgram={cardQuery?.card_program_id} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xxl={20} xl={20} lg={24} md={24} sm={24} xs={24}>
                    <CustomTable
                        scroll={{ x: "866px" }}
                        {...cardDetails}
                        bodyColor="green"
                        headerColor="green"
                    />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col xxl={12} xl={14} lg={16} md={22} sm={24} xs={24}>
                    <Typography.Text className="dark-green fs-25px medium">
                        General Limits
                    </Typography.Text>
                    <CustomTable {...generalLimits} />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col xxl={12} xl={14} lg={16} md={22} sm={24} xs={24}>
                    <Typography.Text className="dark-green fs-25px medium">
                        Daily Limits
                    </Typography.Text>
                    <CustomTable {...dailyLimits} />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col xxl={12} xl={14} lg={16} md={22} sm={24} xs={24}>
                    <Typography.Text className="dark-green fs-25px medium">
                        Four Day Limits
                    </Typography.Text>
                    <CustomTable {...fourDayLimits} />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col xxl={12} xl={14} lg={16} md={22} sm={24} xs={24}>
                    <Typography.Text className="dark-green fs-25px medium">
                        Year Limits
                    </Typography.Text>
                    <CustomTable {...yearLimits} />
                </Col>
            </Row>
        </FullScreenModal>
    )
}

export default FullPrepaidCardDetailsModal;
