/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Image, Modal, Row, Space, Typography, Input, Alert } from "antd";
import React, { useState } from 'react'
import wallet from 'Assets/Images/wallet.png';
import payments from 'Assets/Images/payments.png';
import card from 'Assets/Images/card.png';
import { APIROUTES } from "Constants/ApiRoutes";
import accounting from 'Assets/Images/accounting.png';
import balances from 'Assets/Images/balances.png';
import fxForwards from 'Assets/Images/fx-forwards.svg';
import collections from 'Assets/Images/collections.png'; // Importing the Collections Icon
import { useApiRequest } from "Hooks/API";
import { useLoginStore } from "App/Pages/Login/store";
import PageDescription from "App/Components/PageDescription";
import CustomTable from "App/Components/CustomTable";
import { useMutation } from "@tanstack/react-query";
import { GoLinkExternal } from "react-icons/go"
import { IMAGES } from "Constants/Images";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";


function AdditionalServices() {

	const apiRequest = useApiRequest();
	const config = useLoginStore(s => s.config);
	const navigate = useNavigate();
	const [enableServiceModal, setEnableServiceModal] = useState({
		show: false,
		message: '',
		serviceTitle: '',
	});

	const [disableServiceModal, setDisableServiceModal] = useState({
		show: false,
		content: '',
		message: '',
		validate: '',
	});
	//
	const enableServiceMutation = useMutation({
		mutationFn: async (serviceTitle) => {
			setEnableServiceModal({
				...enableServiceModal,
				serviceTitle,
			});

			const res = await apiRequest(
				APIROUTES.post.enable_service,
				'POST',
				{ 'service_name': serviceTitle }
			);
			return res;
		},
		mutationKey: '_enable_services',
		onSuccess: (res) => {
			if (res?.success) {
				setEnableServiceModal({
					show: true,
					message: res?.message,
				});
			} else {
				setEnableServiceModal({
					show: true,
					message: 'Something went wrong. Please try again later.',
					serviceTitle: '',
				});
			}
		},
		onError: () => {
			setEnableServiceModal({
				show: true,
				message: 'Something went wrong. Please try again later.',
				serviceTitle: '',
			});
		}
	});

	const disableServiceMutation = useMutation({
		mutationFn: async () => {
			const res = await apiRequest(
				APIROUTES.post.disable_service,
				'POST',
				{ content: disableServiceModal.content }
			);
			return res;
		},
		mutationKey: '_disable_services',
		onSuccess: (res) => {
			if (res?.success) {
				setDisableServiceModal({
					show: true,
					message: res?.message,
				});
			} else {
				setDisableServiceModal({
					show: true,
					message: 'Something went wrong. Please try again later.',
					content: '',
				});
			}
		},
		onError: () => {
			setDisableServiceModal({
				show: true,
				message: 'Something went wrong. Please try again later.',
				content: '',
			});
		}
	});

	//
	const enableStatus = async (serviceTitle) => {
		if(serviceTitle === 'Collections')
			navigate(URLS.CollectionsDashboard) // Navigate to Collections Page
		else if (serviceTitle !== 'Accounting')
			await enableServiceMutation.mutateAsync(serviceTitle);
		else
			navigate(URLS.AccountingConnect);
	}

	//
	const postDisableService = async () => {
		if (!disableServiceModal.content?.length || disableServiceModal.content?.length < 4) {
			setDisableServiceModal({
				...disableServiceModal,
				show: true,
				validate: 'Please give a description of what service you would like to be disabled.',
				message: '',
			});

			return;
		}

		await disableServiceMutation.mutateAsync();
	}

	//
	const columns = [
		{
			title: 'Product',
			dataIndex: 'product',
			key: 'product',
			align: 'left',
			width: 400,
			render: value => <Space>
				{value.image}
				{value.text}
			</Space>
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			align: 'left',
			render: (text) => Array.isArray(text) ?
				<Space direction="vertical" size={0}>
					{text.map((val, key) =>
						<Typography.Text key={key}>
							{val}
						</Typography.Text>
					)}
				</Space> : text

		},
		{
			title: 'More Information',
			dataIndex: 'moreInformation',
			key: 'moreInformation',
			render: text => <Typography.Text className="dark-green">{text}</Typography.Text>
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, row) => (
				text === 'Enabled' ? <Typography.Text type="success" className="medium">{text}</Typography.Text> :
					<Button
						role="button"
						type="primary"
						loading={enableServiceMutation.isLoading && enableServiceModal.serviceTitle === row?.product?.text}
						onClick={() => enableStatus(text === 'EnablePayment' ? 'Payments' : row?.product?.text)} // If EnablePayment then Enable the Payment Service
					>Enable</Button>
			)
		}
	];

	//
	const data = [
		{
			product: {
				image: <Image src={wallet} alt='wallet' preview={false} width={36} />,
				text: 'Company Wallet'
			},
			description: ['The Company Wallet is your Volopa Account.', 'Fund your Company Wallet to convert balances and fund your employee cards'],
			moreInformation: <a href="https://volopa.com/company-wallet/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.company_wallet_status ? 'Enabled' : 'Enable',
			key: 0
		},
		{
			product: {
				image: <Image src={payments} alt='payments' preview={false} width={36} />,
				text: 'Payments'
			},
			description: 'Make international business payments using our Payments product',
			moreInformation: <a href="https://volopa.com/international-payments/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.payments_status ? 'Enabled' : 'Enable',
			key: 1
		},
		{
			product: {
				image: <Image src={collections} alt='collections' preview={false} width={36} />, // Icon for the Collection
				text: 'Collections' // Text label for the Collections
			},
			description: 'Collect funds from third parties in multiple currencies', // Description of the Collections functionality
			moreInformation: <a href="https://volopa.com/international-payments/collections" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>, // Link to more information
			status: !!config?.service_statuses?.payments_status
				? (!!config?.service_statuses?.collections_status ? 'Enabled' : 'Enable') 
			 	: 'EnablePayment',  // Status of the collections based on configuration
			key: 2 // Unique key for the collections item
		},
		{
			product: {
				image: <Image src={fxForwards} alt='payments' preview={false} width={36} />,
				text: 'FX Forwards'
			},
			description: 'Set FX rates for up to 24 months in advance, using Volopa’s Open & Fixed FX Forwards',
			moreInformation: <a href="https://volopa.com/fx-forwards/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.fx_forwards_status ? 'Enabled' : 'Enable',
			key: 3
		},
		{
			product: {
				image: <Image src={card} alt='card' preview={false} width={36} />,
				text: 'Cards'
			},
			description: 'Control employee expenses through the Volopa smart business cards',
			moreInformation: <a href="https://volopa.com/multi-currency-expense-cards/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.cards_status ? 'Enabled' : 'Enable',
			key: 4

		},
		{
			product: {
				image: <Image src={accounting} alt='accounting' preview={false} width={36} />,
				text: 'Accounting'
			},
			description: 'Sync your Volopa transactions with the accounting software of your choice',
			moreInformation: <a href="https://volopa.com/integrations/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.accounting_status ? 'Enabled' : 'Enable',
			key: 5
		},
		// {
		//     product: {
		//         image: <Image src={analytics} alt='analytics' preview={false} width={36} />,
		//         text: 'Analytics'
		//     },
		//     description: 'View detailed reports of your monthly spending',
		//     moreInformation: <a href="https://volopa.com/company-wallet/" rel="noreferrer"  target="_blank">More details <GoLinkExternal /></a>,
		//     status: !!config?.service_statuses?.analytics_status ? 'Enabled' : 'Enable',
		//     key: 4
		// },
		{
			product: {
				image: <Image src={IMAGES.currencyConversion} alt='currencyConversion' preview={false} width={36} />,
				text: 'Base Currency Conversion'
			},
			description: 'Automatically convert from the card base currency balance when the card is used, instead of maintaining balances in multiple currencies',
			moreInformation: <a href="https://volopa.com/company-wallet/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.base_currency_conversion_status ? 'Enabled' : 'Enable',
			key: 6
		},
		{
			product: {
				image: <Image src={balances} alt='wallet' preview={false} width={36} />,
				text: 'Shared Wallet Balance'
			},
			description: 'Auto fund your employee card directly from the Wallet when their card is being used',
			moreInformation: <a href="https://volopa.com/introducing-volopas-shared-company-wallet-balance/" rel="noreferrer" target="_blank">More details <GoLinkExternal /></a>,
			status: !!config?.service_statuses?.shared_wallet_balance_status ? 'Enabled' : 'Enable',
			key: 7
		},
	];

	//
	const cancelDisableServiceModal = () => {
		setDisableServiceModal({ show: false, message: '', content: '', validate: '' });
	}


	//
	return (
		<>
			{/* Enable service modal ============================================= */}
			<Modal
				destroyOnClose
				footer={false}
				open={enableServiceModal.show}
				onCancel={() => setEnableServiceModal({ show: false, message: '', serviceTitle: '' })}
				closable={false}
			>
				<Row>
					<Col>
						<Typography.Text data-testid="enable-service-message" className="muli fs-18px dark-green">{enableServiceModal?.message}</Typography.Text>
					</Col>
				</Row>
				<Row justify="space-between m-t-10">
					<Col>
						<Button type="primary" onClick={() => setEnableServiceModal(false)}>Dismiss</Button>
					</Col>
				</Row>
			</Modal>

			{/* Disable service modal ============================================= */}
			<Modal
				destroyOnClose
				footer={false}
				open={disableServiceModal.show}
				onCancel={cancelDisableServiceModal}
				closable={false}
			>
				<Row>
					<Col span={24}>
						{!!disableServiceModal?.message ? (
							<Typography.Text className="muli fs-16px dark-green">{disableServiceModal?.message}</Typography.Text>
						) : (
							<>
								<div className="m-b-10">
									<Typography.Text className="muli fs-18px dark-green">Please tell us which service you wish to disable</Typography.Text>
								</div>
								<Input.TextArea data-testid="disable-service-input" rows={4} value={disableServiceModal?.content || ''} onChange={(e) => setDisableServiceModal({ ...disableServiceModal, validate: '', content: e.target.value })} />
								{!!disableServiceModal?.validate && <Typography.Text className="muli fs-16px">{disableServiceModal?.validate}</Typography.Text>}
							</>
						)}
					</Col>
				</Row>
				<Row justify="space-between m-t-10">
					<Col>
						{!!disableServiceModal?.message ? (
							<Button role="button" type="primary" onClick={() => setDisableServiceModal({
								show: false,
								content: '',
								message: '',
							})}>Dismiss</Button>
						) : (
							<Row gutter={[10, 10]}>
								<Button role="button" type="primary" className="m-r-5" onClick={postDisableService} loading={disableServiceMutation.isLoading}>Send</Button>
								<Button role="button" onClick={cancelDisableServiceModal}>Cancel</Button>
							</Row>
						)}
					</Col>
				</Row>
			</Modal>

			<Row>
				<Col span={24}>
					<PageDescription title='Products' />
				</Col>
			</Row>
			{config?.client_status?.client_verification_status_id === 2 &&
				<Alert
					message='Your application is under review'
					showIcon
					type="warning"
					className="m-t-10"
				/>
			}
			{config?.client_status?.client_verification_status_id === 3 &&
				<Alert
					message='Your application was declined'
					showIcon
					type='error'
					className="m-t-10"
				/>
			}
			{config?.client_status?.client_verification_status_id === 4 &&
				<Alert
					message='Your application was cancelled'
					showIcon
					type="error"
					className="m-t-10"
				/>
			}
			<Row className="m-t-10">
				<Col span={24}>
					<CustomTable columns={columns} dataSource={data} styleAllRows className='spaced-rows' headerColor='green' rowClassName='fs-18px regular' />
				</Col>
			</Row>
			<Row className="m-t-10">
				<Col span={24}>
					<Typography.Text className="muli fs-18px light">If you wish to disable any of the products, please contact us</Typography.Text>
					<Button role="button" onClick={() => setDisableServiceModal({ ...disableServiceModal, show: true })} type="link" size='small' className="fs-18px muli semi-bold">here</Button>
				</Col>
			</Row>
		</>
	)
}

export default AdditionalServices;
