import create from 'zustand';

export const useCardsState = create((set, get) => ({
	modalVisible: false,
	modalType: undefined,
	prepaidCardsQueryData: null,
	cardSearchFilter: null,
	busy: false,
	currentPage: 1,
	limit: 10,
	totalPages: 0,
	setState: obj => set(obj),
}));
