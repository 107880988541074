import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

function useGetCurrencyRulesQuery() {
    const apiRequest = useApiRequest();

    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.get_currency_rules}`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_get_currency_rules'],
        queryFn: fetchQuery
    });

    return query;
}

export { useGetCurrencyRulesQuery };
