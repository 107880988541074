import { useLoginStore } from "App/Pages/Login/store";
import { isEmpty } from "lodash";
import { Navigate, Outlet } from "react-router-dom";
import URLS from "Routes/constants";

function ApprovedUserGuard({
  redirectTo = URLS.PersonalProfile,
}) {
  const config = useLoginStore(s => s.config);

  return config?.user_signup_status === null || isEmpty(config?.user_signup_status) ||
  config?.user_signup_status?.status_id === 4 || config?.user_signup_status?.status_id === 9
    ? <Outlet />
    : <Navigate to={redirectTo} replace />

}

export default ApprovedUserGuard;
