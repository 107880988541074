import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useTrackingCodesState } from "../store";

const useGetAllTrackingCodeQuery = () => {
    const apiRequest = useApiRequest();
    const currentPage = useTrackingCodesState(state => state.currentPage);
	const limit = useTrackingCodesState(state => state.limit);
	const setState = useTrackingCodesState(state => state.setState);

	//
    const fetchQuery = async () => {
        const  data = await apiRequest(`${APIROUTES.get.tracking_codes}?$with=All&$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}&$order=ASC`);
        return data;
    }

	//
    const query = useQuery({
        queryKey: ['_get_tracking_code_expense_tag', currentPage, limit],
        queryFn: fetchQuery,
        onSuccess: () => {
            setState({ loading: false })
        }
    });

	//
    return query;
}

export { useGetAllTrackingCodeQuery };
