import { Button, Col, Row, Space, Typography, Select, DatePicker, Modal, Spin, Radio, notification } from "antd";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import {
	AiOutlineFilePdf,
} from "react-icons/ai";
import { FaFileCsv, FaCalendarAlt, FaFileDownload } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoDocumentTextOutline } from "react-icons/io5";
import FullScreenModal from "App/Components/FullScreenModal";
import moment from "moment";
// import useReportsApiRequest from "Hooks/ReportsAPI";
import { useEffect } from "react";
import { HiOutlineDocumentText, HiXCircle } from "react-icons/hi";
import { FORMATS } from "Constants/Formats";
import { usePaymentReportsQuery } from "./query/usePaymentReportsQuery";
import { usePaymentReportsState } from "./store";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { APIROUTES } from "Constants/ApiRoutes";

function PaymentReports() {
	const selectedFilter = usePaymentReportsState(s => s.selectedFilter);
	const selectedFromMonth = usePaymentReportsState(s => s.selectedFromMonth);
	const selectedToMonth = usePaymentReportsState(s => s.selectedToMonth);
	const downloadModal = usePaymentReportsState(s => s.downloadModal);
	const downloadUrl = usePaymentReportsState(s => s.downloadUrl);
	const clearFilters = usePaymentReportsState(s => s.clearFilters);
	const loading = usePaymentReportsState(s => s.loading);
	const setState = usePaymentReportsState(s => s.setState);
	const openFilter = usePaymentReportsState(s => s.openFilter);
	const selectedReports = usePaymentReportsState(s => s.selectedReports);
	const format = usePaymentReportsState(s => s.format);
	const extendedApiRequest = useExtendedApiRequest();


	const { data: reportsData, isLoading, refetch: refetchDocs, isFetching } = usePaymentReportsQuery();

	const rowsData = JSON.parse(reportsData || '[]')?.data?.filter((item) => {
		const rowDate = moment(item?.month);
		const currentDate = moment();
		const previousMonth = currentDate.month() - 1 < 0 ? 11 : currentDate.month() - 1;
		if(previousMonth === rowDate.month() && currentDate.date() < 5) {
			return false;
		}
		return true;
	})
	//
	useEffect(() => {
		return () => {
			setState({
				selected: [],
				openFilter: false,
				selectedFilter: null,
				selectedFromMonth: null,
				selectedToMonth: null,
			});
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const close = (e) => {
		  if(e.keyCode === 27){
			clearAllFilters();
		  }
		}
		window.addEventListener('keydown', close)
	  return () => window.removeEventListener('keydown', close)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	//
	useEffect(() => {
		refetchDocs();

		// eslint-disable-next-line
	}, [clearFilters]);

	//
	const getDocumentFormatIcon = (type) => {
		if (type === 'pdf') {
			return <AiOutlineFilePdf style={{ fontSize: '32px', color: '#0f99a3' }} />;
		} else if (type === 'csv') {
			return <FaFileCsv style={{ fontSize: '32px', color: '#0f99a3' }} />;
		} else if (type === 'xls' || type === 'xlsx') {
			return <SiMicrosoftexcel style={{ fontSize: '32px', color: '#0f99a3' }} />;
		} else {
			return <IoDocumentTextOutline style={{ fontSize: '32px', color: '#0f99a3' }} />;
		}
	}

	//
	const handleMonthFromSelect = (date, datestring) => {
		const toMonth = (selectedToMonth?.date?.isBefore(date) || selectedToMonth?.datestring === datestring) ? null : selectedToMonth;
		setState({
			selectedFromMonth: { date, datestring },
			selectedToMonth: toMonth,
		});
	}

	//
	const handleMonthToSelect = (date, datestring) => {
		const fromMonth = (selectedFromMonth?.date?.isAfter(date) || selectedFromMonth?.datestring === datestring) ? null : selectedFromMonth;
		setState({
			selectedToMonth: { date, datestring },
			selectedFromMonth: fromMonth,
		});
	}

	//
	const handleFilterModalClose = () => {
		setState({
			openFilter: false,
		});
	}

	//
	const handleFilterChange = (val) => {
		// eslint-disable-next-line
		if (val != 'month') {
			setState({
				selectedFromMonth: null,
				selectedToMonth: null,
				selectedFilter: val,
			});
		}
		else {
			setState({ selectedFilter: val });
		}
	}

	//
	const handleConfirmFilters = async () => {
		await refetchDocs();
		handleFilterModalClose();
	}

	const clearAllFilters = () => {
        setState({
            selectedFilter: null,
            selectedFromMonth: null,
            selectedToMonth: null,
            clearFilters: !clearFilters,
        });
    }

	//
	const renderFilterInfo = () => {
		let filterInfo = '';
		if (selectedFilter) {
			if (selectedToMonth && selectedFromMonth) {
				filterInfo = `From ${selectedFromMonth.datestring}-01 to ${moment(`${selectedToMonth.datestring}-01`).format('YYYY-MM-DD')}`;
			return <>
				<Typography>{filterInfo}</Typography>
				<Button
					onClick={() => {
						setState({
							selectedFilter: null,
							selectedFromMonth: null,
							selectedToMonth: null,
							clearFilters: !clearFilters,
						});
					}}
					style={{ transform: 'translateY(3px)' }} type="link"
				>
					<HiXCircle />
				</Button>
			</>
			}

		}

		return null;
	}

	//
	async function downloadFile() {
            setState({ loading: true, downloadModal: false });
            try {
            const res = await extendedApiRequest({
                baseUrl: process.env.REACT_APP_AUX_API_BASE_URL,
                path: `${APIROUTES.get.download_payment_reports}?payment_report_id=${selectedReports?.id}&file_type=${format}`,
                method: 'POST',
                config: {
                responseType: "blob",
                },
                fullResponse: true,
            })

            if (res.status === 200) {
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
				const url = window.URL.createObjectURL(blob);

				// Create a link to trigger the download
				const link = document.createElement('a');
				link.href = url;
		  
				link.setAttribute('download', selectedReports?.report_name);
		  
				// Trigger the download
				link.click();
		  
				// Clean up
				window.URL.revokeObjectURL(url);
            }
            else {
                throw new Error('Something went wrong while fetching your attachment');
            }
            } catch (err) {
                notification.error({ placement: 'top', message: 'Something went wrong', description: 'Attachment not found' });
            } finally {
                setState({
                    loading: false
                });
            }
        
      }

	const handleDownloadClick = () => {
		setState({ downloadModal: true })
	}
	//
	const handleDownloadModalClose = () => {
		setState({
			loading: false,
			downloadModal: false,
			downloadUrl: null,
		});
	}
	
	const isReportItemSelected = (record) => {
		setState({ selectedReports: record })
	}

	//
	const columns = [
		{
			title: 'Date',
			dataIndex: 'month',
			key: 'date',
			align: 'left',
			sorter: (a, b) => moment(a?.month).unix() - moment(b?.month).unix(),
			render: (value, record) => {
				const docDate = moment(value);
				// const yesterday = moment().subtract(1, 'day');
				return <Space size="small">
					<HiOutlineDocumentText style={{ fontSize: '32px', color: '#0f99a3' }} />
					<Typography>
						{moment().format('M-YYYY') === moment(docDate).format('M-YYYY') ? 'Current Month' : docDate.format(FORMATS.expenseReportDate)}
					</Typography>
				</Space>
			}
		},
		{
			title: 'Document name',
			dataIndex: 'report_name',
			key: 'doc_name',
			align: 'left',
			// sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time, 'DD-MM-YYYY').diff(moment(b.transaction_time, 'DD-MM-YYYY')),
			render: (value) => <Typography>{value}</Typography>
		},
		{
			title: 'Format',
			dataIndex: 'file_type',
			key: 'format',
			align: 'left',
			// sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time, 'DD-MM-YYYY').diff(moment(b.transaction_time, 'DD-MM-YYYY')),
			render: (value) => getDocumentFormatIcon(value)
		},
		{
			// title: <Space>Select All {<Checkbox onChange={(evt) => handleSelectAll(evt)} checked={areAllReportsSelected()} />}</Space>,
			title: 'Download',
			dataIndex: 'select_all',
			key: 'select_all',
			// sorter: (a, b) => a.transaction_time && b.transaction_time && moment(a.transaction_time, 'DD-MM-YYYY').diff(moment(b.transaction_time, 'DD-MM-YYYY')),
			// render: (value, record) => <CheckDotButton checked={isReportItemSelected(record)} onClick={() => handleSelectItem(record)} />
			render: (_, record) => <Radio
			checked={selectedReports?.id === record?.id} onClick={() => isReportItemSelected(record)}
			className='b-2-grey big-rounded p-r-2 p-l-2'
		/>
		},
	];

	//
	const disabledDate = (current) => {
		// Can not select days before today
		if (current && current <= moment().subtract(1, 'years').startOf('month')) {
			return true;
		}

		if (current && current > moment().endOf('month')) {
			return true;
		}
	};

	//
	const disabledDateTo = (current) => {
		if (selectedFromMonth?.date) {
			if (current && current > moment().add(2, 'months').startOf('month')) {
				return true;
			}

			if (current && current < moment(selectedFromMonth.date).endOf('month')) {
				return true;
			}
		} else {
			if (current && current > moment().add(2, 'months').startOf('month')) {
				return true;
			}

			if (current && current <= moment().subtract(1, 'years').startOf('month')) {
				return true;
			}
		}

		if (current && current <= moment().subtract(1, 'years').startOf('monts')) {
			return true;
		}
	};

	const handleFilterOpen = () => {
		setState({ openFilter: true });
	}

	//
	return (
		<Spin
			size='large'
			spinning={loading}
			tip={<Typography.Text className="medium fs-24px dark-green">Your document is being generated. This could take a few  minutes. Please do not close this page.</Typography.Text>}
		>
			<FullScreenModal
				open={openFilter}
				onCancel={() => {
						clearAllFilters();
						handleFilterModalClose();
					}}
			>
				<Row style={{ height: 'calc(100vh - 120px)' }} align="stretch" justify="space-between">
					<Col span={24} style={{ marginTop: '100px' }}>
						<Row>
							<Col span={24}>
								<Select
									bordered={true}
									value={selectedFilter}
									onChange={(val) => handleFilterChange(val)}
									placeholder="Select filter..."
									style={{
										width: 200,
									}}
									options={[
										{
											value: 'month',
											label: 'Month',
										},
									]}
								/>
							</Col>

							{selectedFilter === 'month' && <Col span={24} className="m-t-30">
								<Row gutter={[50, 10]}>
									<Col>
										<Typography>From <FaCalendarAlt color="#0f99a3" /></Typography>
										<DatePicker disabledDate={disabledDate} value={selectedFromMonth?.date} onChange={handleMonthFromSelect} picker="month" />
									</Col>
									<Col>
										<Typography>To <FaCalendarAlt color="#0f99a3" /></Typography>
										<DatePicker disabledDate={disabledDateTo} value={selectedToMonth?.date} onChange={handleMonthToSelect} picker="month" />
									</Col>
								</Row>
							</Col>}
						</Row>

					</Col>

					<Col span={24}>
						<Row className="m-t-20" justify="end" align="stretch">
							<Col>
								<Space>
									<Button type="primary" danger onClick={ () => {
										clearAllFilters();
										handleFilterModalClose();
									}}>Cancel</Button>
									<Button type="primary" disabled={selectedFilter === 'month' && (!selectedFromMonth || !selectedToMonth)} onClick={handleConfirmFilters}>Confirm</Button>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
			</FullScreenModal>

			<Modal
				title="Download Confirmation"
				open={downloadModal}
				onCancel={handleDownloadModalClose}
				footer={[
					<Row>
					<Col span={12} align="middle" justify="center">
					<Button
								type="primary"
								href={downloadUrl}
								onClick={() => downloadFile()}
								size="lg"
							>
								<FaFileDownload size={14} style={{ marginRight: '10px' }} /> Download
							</Button>
							</Col>
							<Col span={12} align="middle" justify="center">
					<Button key="back" onClick={handleDownloadModalClose}>
						Cancel
					</Button>
					</Col>
					</Row>,
				]}
			>
					<Row align="middle" justify="center" wrap gutter={[0, 20]}>
					<Col>
						<Space>
							<Typography.Text className='fs-18px medium dark-green'>Format:</Typography.Text>
						</Space>
					</Col>
						<Col span={24} align="middle" justify="center">
						<Select value={format} onChange={(e) => setState({ format: e})} style={{ minWidth: '200px' }}>
							<Select.Option value='xlsx'>
									Excel
							</Select.Option>
							<Select.Option value="csv">
									CSV
							</Select.Option>
						</Select>
						</Col>
						
					</Row>
			</Modal>

			<Row>
				<Col span={24}>
					<PageDescription title='Payment Reports' />
				</Col>
			</Row>

			<Row className="m-t-30" justify="space-between" gutter={[12, 12]}>
				<Col xxl={8}>
					<Space>
						<Button onClick={handleFilterOpen} type="primary">Filter</Button>
						{renderFilterInfo()}
					</Space>

				</Col>
				<Col>
					<Space wrap>
						<Button
							onClick={handleDownloadClick}
							loading={loading}
							disabled={selectedReports?.length === 0}
						>
							<FaFileDownload size={14} style={{ marginRight: '10px' }} /> Download Selected
						</Button>
					</Space>
				</Col>
			</Row>

			<Row className="m-t-30">
				<Col span={24}>
					<CustomTable
						loading={isLoading || isFetching}
						columns={columns}
						dataSource={rowsData || []}
						className='spaced-rows'
						headerColor='green'
						rowClassName='fs-18px regular'
						styleAllRows
						rowKey="id"
					/>
				</Col>
			</Row>
		</Spin>
	);
}

export default PaymentReports;
