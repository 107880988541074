import { useMutation } from "@tanstack/react-query";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";

const useExpenseAttachmentMutation = () => {
    const apiRequest = useExtendedApiRequest();

    const mutation = useMutation({
        mutationFn: (formData) => {

			return apiRequest({
				path: APIROUTES.post.file_files_store,
				method: 'POST',
				data: formData,
				config: {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Accept': '"/"',
					},
				}
			})
        },
        mutationKey: ['_expense_file_files_store'],
        onMutate: () => {},
        onSuccess: (data) => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useExpenseAttachmentMutation };

