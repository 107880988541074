import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";

const useAccountTrackingCategories = () => {
    const statusId = useConfigurationState(state => state.statusId);
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get_codat_tracking_categories}`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_get_codat_accounting_tracking_categories'],
        queryFn: fetchQuery,
        enabled: statusId === 3,
		onSuccess: (queryData) => ({
			...queryData,
			response: {
				...queryData.response,
				results: queryData?.response?.results?.sort((a, b) => a.name.localeCompare(b.name, 'en', {numeric: true}))
			}
		}),
    });

    return query;
}

export { useAccountTrackingCategories };
