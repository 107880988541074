import { Button, Col, Modal, Row, Typography } from 'antd';
import { FaFileDownload } from "react-icons/fa";

function MT103Modal({ modalOpen, onCancel, description, downloadMt103Pdf, hideMt103Button }) {

  return (
    <>
    <Modal
            destroyOnClose
            footer={false}
            open={modalOpen}
            onCancel={onCancel}
            closable={false}
          >
      <Row justify='space-between'>
        <Col>
          <Typography.Text className="muli bold fs-18px dark-green">MT103</Typography.Text>
        </Col>
        <Col>
        {!hideMt103Button && <Button type="primary" onClick={downloadMt103Pdf} style={{ display: 'flex', alignItems: 'center' }}> 
          <FaFileDownload size={17} style={{ marginRight: '5px' }}/> 
          Download PDF
        </Button>}
        </Col>
      </Row>
      <Row className="m-t-30" justify='flex-start'>
        <Col>
          <Typography.Text className="muli fs-16px semi-bold dark-green">
            {description}
          </Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-20" justify="center">
        <Col>
          <Button type="primary" danger onClick={onCancel}>Close</Button>
        </Col>
      </Row>
      </Modal>
    </>
  )
}

export default MT103Modal;