import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";

const useCreateTrackingCodeMutation = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload) => {
            return apiRequest(`${APIROUTES.post.tracking_code_create}`, 'POST', payload);
        },
        mutationKey: ['_create_tracking_code_mutation'],
        onMutate: () => { },
        onSuccess: (data) => {
            if (data?.success) {
                notification.success({ message: 'Tracking Code Created Successfully', placement: 'top' })
                queryClient.invalidateQueries({ queryKey: ['_get_tracking_code_expense_tag'] });
            } else if (data?.response?.data?.error) {
                const error = data?.response?.data?.error;
                notification.error({ message: error?.message, description: error?.detail?.description, placement: 'top' });
            } else {
                notification.error({ message: "Error", description: "Failed to add Tracking Code", placement: "top" });
            }
        },
    });

    return mutation;
}

export { useCreateTrackingCodeMutation };