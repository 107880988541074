import create from "zustand";
import { OPT_DISABLED_PAYMENT_TYPES } from "Constants/Opts";
import { xor } from "lodash";

const useCardPaymentTypesState = create((set, get) => ({
	editMode: false,
	setState: (data) => set(data),
	initialData: [],
	disabled: [],
	loading: false,
	cardId: null,
	paymentTypeIds: [
		OPT_DISABLED_PAYMENT_TYPES.atmWithdraw,
		OPT_DISABLED_PAYMENT_TYPES.contactless,
		OPT_DISABLED_PAYMENT_TYPES.ecom
	],
	reset: () => set({
		editMode: false,
		initialData: [],
		disabled: [],
		loading: false,
		cardId: null,
	}),
	authPayTypesChanged: () => {
		const initIds = get().initialData.reduce((acc, item) => {
			acc.push(item.opt_disable_type_id);
			return acc;
		}, []);

		const disabledIds = get().disabled.reduce((acc, item) => {
			acc.push(item.opt_disable_type_id);
			return acc;
		}, []);

		return !!xor(initIds, disabledIds).length;
	},
}));

export { useCardPaymentTypesState }
