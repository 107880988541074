import URLS from "Routes/constants"
import { Button, Col, Modal, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"

function CancelFundingReturn({ onCancel, modalOpen }) {
  const navigate = useNavigate()
  return (
    <>
      <Modal
        destroyOnClose
        footer={false}
        open={modalOpen}
        onCancel={onCancel}
        closable={false}
      >
        <Row>
          <Col>
            <Typography.Text className="muli semi-bold fs-18px dark-green">Funding Cancelled</Typography.Text>
          </Col>
        </Row>
        <Row className="m-t-30" justify='center'>
          <Col>
            <Typography.Text className="muli fs-16px semi-bold dark-green">
              Your funding has been cancelled
            </Typography.Text>
          </Col>
        </Row>
        <Row className="m-t-20" justify="space-around">
          <Col>
            <Button type="primary" onClick={() => { onCancel(); navigate(URLS.FundingHistory) }}>Return to Funding History</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() => { onCancel(); navigate(URLS.WalletDashboard) }}>Return to Wallet Dashboard</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default CancelFundingReturn