import { Navigate, Outlet } from "react-router-dom";
import URLS from "Routes/constants";
import { useLoginState, useLoginStore } from "App/Pages/Login/store";
import { USER_ROLES } from "Constants/UserRoles";
import { ErrorView } from "App/Components/ErrorView";

const AdditionalServicesGuard = ({
	redirect = '',
	serviceKey,
}) => {
	const history = useLoginState(s => s.history);
	const config = useLoginStore(s => s.config);
	const roleId = useLoginState(state => state.roleId);

	// Redirect for admins
	if ((roleId === USER_ROLES.primary_admin.roleId || roleId === USER_ROLES.admin.roleId) && !config?.service_statuses?.[serviceKey] && !redirect) {
		if (history?.[1] === URLS.Login && serviceKey === 'company_wallet_status') {
			if (config?.service_statuses?.payments_status)
				redirect = URLS.PaymentsDashboard
			else if (config?.service_statuses?.cards_status) {
				redirect = URLS.CardsDashboard
			}
		}
	}

	// Redirect for business users
	if (roleId === USER_ROLES.business_user.roleId && !config?.service_statuses?.[serviceKey] && !redirect) {
		if (serviceKey === 'payments_status' && config?.service_statuses?.cards_status) {
			redirect = URLS.PersonalCardsDashboard
		} else if (serviceKey === 'payments_status' && config?.service_statuses?.fx_forwards_status) {
			redirect = URLS.FxForwardsDashboard
		} else if (serviceKey === 'cards_status' && config?.service_statuses?.payments_status) {
			redirect = URLS.PaymentsDashboard
		} else if (serviceKey === 'cards_status' && config?.service_statuses?.fx_forwards_status) {
			redirect = URLS.FxForwardsDashboard
		} else if (serviceKey === 'fx_forwards_status' && config?.service_statuses?.cards_status) {
			redirect = URLS.PersonalCardsDashboard
		} else if (serviceKey === 'fx_forwards_status' && config?.service_statuses?.payments_status) {
			redirect = URLS.PaymentsDashboard
		} else {
			redirect = URLS.PersonalProfile
		}
	}

	// Redirect for card users
	if (roleId === USER_ROLES.card_user.roleId && !config?.service_statuses?.[serviceKey] && !redirect) {
		// Redirect to personal profile if cards not active for the client
		redirect = URLS.PersonalProfile
	}

	// Default redirect
	if (!redirect) {
		redirect = URLS.AdditionalServices
	}

	return (
		serviceKey === 'accounting_status' || !!config?.service_statuses?.[serviceKey]
			? (
				(roleId === USER_ROLES.primary_admin.roleId && serviceKey === 'accounting_status') || config?.user_level_access_statuses?.[serviceKey]
					? <Outlet />
					: <ErrorView serviceKey={serviceKey} />
			)
			: <Navigate to={redirect} replace={true} />
	);
}

export default AdditionalServicesGuard;
