import URLS from "Routes/constants"
import { Button, Col, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"

function CancelCardReturn({ onCancel }) {
  const navigate = useNavigate()
  return (
    <>
      <Row>
        <Col>
          <Typography.Text className="muli semi-bold fs-18px dark-green">CARD CANCELLED</Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-30" justify='center'>
        <Col>
          <Typography.Text className="muli fs-16px semi-bold dark-green">
           All funds have been transferred to your compnay wallet. <br/>
           This card will no longer be shown in any dashboard.
          </Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-20" justify="space-around">
        <Col>
          <Button type="primary" onClick={() => navigate(URLS.CardsDashboard)}>Return to Dashboard</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(URLS.RequestCard)}>Order New Card</Button>
        </Col>
      </Row>
    </>
  )
}

export default CancelCardReturn