import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useCardsState } from "App/Pages/Cards/Cards/store";

const usePrepaidCardsQuery = (enabled = true, fetchAll = false) => {
    const apiRequest = useApiRequest();
    const setCardsState = useCardsState(state => state.setState);
    const cardSearchFilter = useCardsState(state => state.cardSearchFilter);
    const currentPage = useCardsState(state => state.currentPage);
    const limit = useCardsState(state => state.limit);
    const skip = fetchAll ? '' : `&$skip=${(currentPage - 1) * limit}`;
    const lim = fetchAll ? '-1' : limit;
    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const data = await apiRequest(`${APIROUTES.get.prepaidcard_list}?$pageCount&$with=All${skip}&$limit=${lim}${cardSearchFilter ? `&search=${cardSearchFilter}` : ``}`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_prepaid_cards', cardSearchFilter, currentPage, limit],
        queryFn: fetchQuery,
        enabled: enabled,
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        onSuccess: (resData) => {
            setCardsState({
                prepaidCardsQueryData: resData?.data
            });
        },
    });

    return query;
}

export { usePrepaidCardsQuery };
