import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useAllCurrencies = ({txnTypeIds = null} = {}) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        let url = `${APIROUTES.get.get_all_currencies}?activeCard=1`;
        const data = await apiRequest(url);
        return data;
    }

    const query = useQuery({
        queryKey: ['_currencies_active_card'],
        queryFn: fetchQuery,
        onSuccess: () => {}
    });

    return query;
}

export { useAllCurrencies };