import {
	Button,
	Col,
	Row,
	Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useManageSpecificCardState } from "../store";
import URLS from "Routes/constants";

function ReturnToDashboardCard() {
    const navigate = useNavigate()
    const returnToDashboardCardHeading = useManageSpecificCardState(state => state.returnToDashboardCardHeading)
    const ReturnToDashboardCardDescription = useManageSpecificCardState(state => state.returnToDashboardCardDescription)
    const ReturnToDashboardCardBold = useManageSpecificCardState(state => state.returnToDashboardCardBold)
    const setState = useManageSpecificCardState(state => state.setState)
	const handleNavigate = () => {
		navigate(URLS.CardsDashboard)
		setState({
			modalContent: null,
			modalOpen: false,
		})
	}
	return (
		<>
			<Row>
				<Col>
					<Typography.Text className="muli semi-bold fs-18px dark-green">
						{returnToDashboardCardHeading}
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20 center-align-text">
				<Col>
					<Typography.Text className="muli fs-16px dark-green">
						{ReturnToDashboardCardDescription} {' '}
						<Typography.Text className="extra-bold">{ReturnToDashboardCardBold}</Typography.Text>
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20" justify="center">
				<Col>
					<Button type="primary" onClick={handleNavigate}>
						Return to Dashboard
					</Button>
				</Col>
			</Row>
		</>
	)
}

export default ReturnToDashboardCard;
