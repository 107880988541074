/* eslint-disable react-hooks/exhaustive-deps */
import logo from "Assets/Images/logo.png";
import { Avatar, Badge, Button, Col, Drawer, Dropdown, Image, Row, Space, Tabs, Grid } from "antd";
import { FaRegBell, FaRegUser } from "react-icons/fa";
import { AiOutlineDown } from "react-icons/ai";
import { useNavigate, Link, useLocation } from "react-router-dom";
import URLS from "Routes/constants";
import { HiMenuAlt4 } from "react-icons/hi";
import squareLogo from "Assets/Images/squareLogo.png";
import { MdLogout } from "react-icons/md"
import ModulesOverlay from "./components/ModulesOverlay";
import NotificationsOverlay from "App/Components/NavBar/components/NotificationsOverlay";
import { ReactComponent as Modules } from "./store/images/modules.svg";
import { useNavbarState } from "./store";
import NavDrawerMenu from "./components/NavDrawerMenu";
import { useLoginState, useLoginStore, useLogout } from "App/Pages/Login/store";
import { useEffect } from "react";
import { useBellNotificationsQuery } from "App/Components/NavBar/hooks/useBellNotificationsQuery";
import { moduleData, moduleItemsData } from "App/Components/NavBar/store/data";
import { useAccountingCompanyMapQuery } from "App/Pages/Accounting/Configuration/query";
import { USER_ROLES } from "Constants/UserRoles";
import { cloneDeep } from "lodash";
import { UserMenu } from "App/Components/NavBar/components/UserMenu";

const { useBreakpoint } = Grid;

function NavBar() {
	const navigate = useNavigate();
	const logout = useLogout();
	const drawerOpen = useNavbarState(state => state.drawerOpen);
	const modulesOpen = useNavbarState(state => state.modulesOpen);
	const notificationsOpen = useNavbarState(state => state.notificationsOpen);
	const modules = useNavbarState(state => state.modules);
	const notificationDot = useNavbarState(state => state.notificationDot);
	const moduleItems = useNavbarState(state => state.moduleItems);
	const activeModuleKey = useNavbarState(state => state.activeModuleKey);
	const activeModuleItemKey = useNavbarState(state => state.activeModuleItemKey);
	const getCurrentModule = useNavbarState(state => state.getCurrentModule);
	const setState = useNavbarState(state => state.setState);
	const notifications = useNavbarState(s => s.notifications);
	const logoUrl = useNavbarState(s => s.logoUrl);
	const { data: accountingConfig, isLoading: accountingConfigLoading } = useAccountingCompanyMapQuery();
	const roleId = useLoginState(state => state.roleId);
	const { data, isLoading, refetch: refetchNotifications } = useBellNotificationsQuery();
	const breakpoint = useBreakpoint();
	const location = useLocation();
	const config = useLoginStore(s => s.config);

	useEffect(() => {
		setState({ notifications: data?.data });
	}, [data])

	// Notifications
	useEffect(() => {
		refetchNotifications();
	}, [notificationsOpen]);

	useEffect(() => {
		const notificationDot = notifications?.some((item => !item.acknowledged))
		setState({ notificationDot })
	}, [notifications])

	// Everytime location path changes, update navbar active key
	useEffect(() => {
		const pathSegments = location.pathname.split('/')?.slice(0, 3);
		setState({
			activeModuleKey: pathSegments[1],
			activeModuleItemKey: pathSegments.join('/'),
		});
	}, [location]);

	useEffect(() => {
		// Filter Accounting module
		const moduleSubItems = cloneDeep(moduleItemsData);
		moduleSubItems.accounting = accountingConfigLoading ? [] : (
			accountingConfig?.[0]?.status_id !== 3 && accountingConfig?.[0]?.status_id !== 2 ? [{
				label: 'Connect Accounting',
				key: URLS.AccountingConnect,
				allowed_roles: [
					USER_ROLES.primary_admin.roleId,
					USER_ROLES.admin.roleId
				],
			}] : moduleItemsData?.accounting
		);

		// Filter modules by user role
		let filteredModuleItems = Object.entries(moduleSubItems).reduce((acc, module) => {
			acc[module[0]] = module[1]?.filter(item => item.allowed_roles.includes(roleId));
			return acc;
		}, {});
		let filteredModules = moduleData.filter(m => m.allowed_roles.includes(roleId));

		// Filter inactive modules for business users
		if (roleId === USER_ROLES.business_user.roleId) {
			if (!config?.service_statuses?.cards_status) {
				filteredModules = filteredModules.filter(m => m?.key !== 'cards');
			}
			if (!config?.service_statuses?.payments_status) {
				filteredModules = filteredModules.filter(m => m?.key !== 'payments');
			}
			if (!config?.service_statuses?.fx_forwards_status) {
				filteredModules = filteredModules.filter(m => m?.key !== 'fx-forwards');
			}
			if (!config?.service_statuses?.collections_status) {
				filteredModules = filteredModules.filter(m => m?.key !== 'collections');
			}
		}

		setState({
			modules: filteredModules,
			moduleItems: filteredModuleItems,
			drawerMenuItems: filteredModules.map(item => ({
				key: item.key,
				label: item.name,
				children: filteredModuleItems[item.key],
			})),
		});
	}, [accountingConfig, roleId]);

	// Update logo url based on user permissions & role for admin users(handled by AdditionalServicesGuard for other roles)
	useEffect(() => {
		let logoUrl;

		if (
			(roleId === USER_ROLES.primary_admin.roleId || roleId === USER_ROLES.admin.roleId) &&
			!config?.service_statuses?.company_wallet_status
		) {
			if (config?.service_statuses?.payments_status)
				logoUrl = URLS.PaymentsDashboard;
			else if (config?.service_statuses?.cards_status)
				logoUrl = URLS.CardsDashboard
		}

		if (!logoUrl)
			logoUrl = URLS.WalletDashboard;

		setState({ logoUrl });
	}, [config?.service_statuses])

	//
	const handleModuleClick = (url) => {
		setState({ modulesOpen: false });
		!!url && navigate(url);
	}

	//
	const handleNavigation = (key) => {
		if (key === URLS.BulkCardControls) {
			navigate(URLS.BulkCardControlsDailyLimits);
		} else {
			navigate(key);
		}
	}

	//
	return (
		<>
			{/* Side menu drawer (small screens)============================== */}
			<Drawer
				open={drawerOpen}
				placement='left'
				closable={false}
				onClose={() => setState({ drawerOpen: !drawerOpen })}
				width="min(378px,100%)"
				className="p-0"
				footer={breakpoint.xs &&
					<Row justify="space-between" align='bottom'>
						<Col>
							<Link className="fs-18px medium" to={URLS.Help} onClick={() => setState({ drawerOpen: !drawerOpen })}>
								Help
							</Link>
						</Col>
						<Col>
							<Button type="link" className="fs-18px medium" onClick={logout}>
								<Space><MdLogout size={24} style={{ marginBottom: '-5px' }} /> Log Out</Space>
							</Button>
						</Col>
					</Row>
				}
				title={
					<Row className="m-t-10">
						<Col span={4}>
							<Button type="link" className="p-0" onClick={() => setState({ drawerOpen: !drawerOpen })}>
								<HiMenuAlt4 size={24} />
							</Button>
						</Col>
						<Col span={20}>
							<Link
								to={getCurrentModule().key}
								className="fs-18px medium">
								{getCurrentModule().label}
							</Link>
						</Col>
					</Row>
				}
			>
				<Row>
					<Col span={24} sm={24} xs={0}>
						{/* Medium screen side menu ==================== */}
						<Tabs
							tabPosition="left"
							items={moduleItems[activeModuleKey]}
							className="full-percent-width fs-18px medium"
							activeKey={activeModuleItemKey}
							size="large"
							onTabClick={key => {
								setState({ drawerOpen: !drawerOpen });
								handleNavigation(key);
							}}
						/>
					</Col>

					{/* Small screen side menu ==================== */}
					<Col span={0} sm={0} xs={24}>
						<NavDrawerMenu
							onSelect={(item) => {
								setState({ drawerOpen: !drawerOpen });
								handleNavigation(item.key);
							}}
						/>
					</Col>
				</Row>
			</Drawer>

			{/* Nav bar ============================== */}
			<Row data-testid="container" gutter={10}>

				{/* Desktop screen logo ================================= */}
				<Col xxl={2} xl={2} lg={0} md={0} sm={0} xs={0} onClick={() => navigate(logoUrl)} className="pointer">
					<Image src={logo} alt="logo" preview={false} width={128} />
				</Col>

				{/* Desktop navbar */}
				<Col xxl={{ span: 13, offset: 1 }} xl={{ span: 17, offset: 1 }} lg={0} md={0} sm={0} xs={0}>
					<Tabs
						activeKey={activeModuleItemKey}
						items={moduleItems[activeModuleKey]}
						size="large"
						className="m-t-4 fs-18px medium"
						onTabClick={(key) => handleNavigation(key)}
					/>
				</Col>
				{/* Outage Alert */}
				{/* <Col xxl={5} xl={0} lg={0} md={0} sm={0} xs={0}>
					{(outageAlert || serviceNotifications?.length > 0) &&
						<Tag className="small-rounded fs-16px p-b-2 p-t-2" color={colors.danger} style={{ maxWidth: '300px' }}>
							{serviceNotifications?.map((notification, key) => `${notification?.service_notification?.content}${(key < serviceNotifications?.length - 1 || outageAlert) ? ", " : ""}`)}
							{outageAlert}
						</Tag>
					}
				</Col> */}

				{/* Side menu drawer toggle */}
				<Col xxl={0} xl={0} lg={1} md={1} sm={2} xs={3} className="m-t-10">
					<Row justify="center">
						<Col>
							<Button type="link" className="p-0" onClick={() => setState({ drawerOpen: !drawerOpen })}>
								<HiMenuAlt4 size={24} />
							</Button>
						</Col>
					</Row>
				</Col>

				{/* Current link ================================= */}
				<Col xxl={0} xl={0} lg={5} md={8} sm={9} xs={21} >
					<Link
						to={getCurrentModule().key}
						className="fs-18px medium">
						{getCurrentModule().label}
					</Link>
				</Col>

				{/* Small screen logo ================================= */}
				<Col xxl={0} xl={0} lg={12} md={6} sm={2} xs={0}>
					<Row justify="center">
						<Col lg={6} md={6} sm={24} onClick={() => navigate(logoUrl)} className="pointer">
							<Image src={breakpoint.lg === true ? logo : squareLogo} alt="logo" preview={false} />
						</Col>
					</Row>
				</Col>

				{/* Right side buttons ================================== */}
				<Col
					xxl={{ span: 3, offset: 5 }} xl={4} lg={{ span: 6, offset: 0 }}
					md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 3 }}
					xs={0}
				>
					<Row justify="end">
						<Col>
							<Space size="large" align="start">
								{/* Modules dropdown menu ================================== */}
								<Dropdown
									overlay={
										<ModulesOverlay moduleData={modules} onClick={(url) => handleModuleClick(url)} />
									}
									trigger={['click']}
									open={modulesOpen}
									onOpenChange={(open) => setState({ modulesOpen: open })}
								>
									<Button type="link" className="m-t-15 grey" icon={<Modules style={{ marginTop: '2px' }} />} />
								</Dropdown>

								{/* Notifications ================================== */}
								<Dropdown
									overlay={<NotificationsOverlay notifications={notifications} loading={isLoading} />}
									trigger={['click']}
									open={notificationsOpen}
									onOpenChange={(open) => setState({ notificationsOpen: open })}
								>
									<Badge count={notificationDot ? " " : ""} size="small" offset={[-5, 23]}>
										<Button type="link" className="m-t-15 grey" icon={<FaRegBell style={{ fontSize: '30px' }} />} />
									</Badge>
								</Dropdown>

								{/* User ================================== */}
								<Dropdown
									overlay={<UserMenu />}
									trigger={['click']}
								>
									<Space size="small">
										<Avatar icon={<FaRegUser />} size={42} />
										<AiOutlineDown style={{ fontSize: '10px' }} />
									</Space>
								</Dropdown>
							</Space>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}
export default NavBar;
