import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";

function useGetPaymentPurposeCode() {
    const apiRequest = useApiRequest();
    const setState = useNewPaymentState(state => state.setState);

    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.get_payment_purpose_codes}?$limit=-1`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_get_payment_purpose_code'],
        queryFn: fetchQuery,
        onSuccess: (data) => {
            if(data && data.length) {
                const PaymentPurposeCodes = data?.reduce((acc, item) => {
                    const { bankCountry, currency, ...rest } = item;
                    const key = bankCountry + '_' +currency;
                    if (!acc[key]) {
                        acc[key] = [];
                      }
                    acc[key].push(rest);
                    return acc;
                  }, {})
                setState({ PaymentPurposeCodes  });
            }
        }
    });

    return query;
}

export { useGetPaymentPurposeCode };
