import { colors } from "Constants/Style";
import { Button, Col, Row, Space, Typography } from "antd";
import { AiFillExclamationCircle } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";



const YapilyCallbackComponent = ({ transactionStatus, returnToDashboard, goToHistory, returnToDashboardText, goToHistoryText }) => {
    return (
        <>
        <Row style={{ height: '20vh'}}>
        </Row>
        <Row className="m-t-10">
            {transactionStatus === 'COMPLETED' ? <Col span={24}>
                <Row justify="center">
                    <Space direction="vertical" style={{ textAlign: 'center'}} size={0}>
                        <FaRegCheckCircle size={150} color={colors.success} />
                        <Typography.Text className="muli semi-bold fs-24px dark-green">Transaction Complete</Typography.Text>
                    </Space>
                </Row>
            </Col> :
            transactionStatus === 'PENDING' ? 
            <Col span={24}>
                <Row justify="center">
                    <Space direction="vertical" style={{ textAlign: 'center'}} size={0}>
                        <GiSandsOfTime size={150} className="purple" />
                        <Typography.Text className="muli semi-bold fs-24px purple">Funds could take up to 2 hours to be posted.</Typography.Text>
                        <Typography.Text className="muli semi-bold fs-24px purple">We will update the status as soon as your funds have been received</Typography.Text>
                    </Space>
                </Row>
            </Col>:
            <Col span={24}>
                <Row justify="center">
                    <Space direction="vertical" style={{ textAlign: 'center'}} size={0}>
                        <AiFillExclamationCircle size={150} color={colors.danger} />
                        <Typography.Text className="muli semi-bold fs-24px error">Unsuccessful Transaction</Typography.Text>
                    </Space>
                </Row>
            </Col> }
        </Row>
        <Row className="m-t-30">
            <Col span={24} className='center-align-text'>
                <Space size={108}>
                    <Button type="primary" onClick={returnToDashboard}>{returnToDashboardText}</Button>
                    <Button type="primary" onClick={goToHistory}>{(transactionStatus === 'COMPLETED' || transactionStatus === 'PENDING') ? goToHistoryText: 'Return to Confirmation Screen'}</Button>
                </Space>
            </Col>
        </Row>
    </>
    )
}

export default YapilyCallbackComponent;