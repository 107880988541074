import axios from "axios";
import { useLoginState } from "App/Pages/Login/store";

//@todo refactor to interceptors
async function apiRequest({
	baseUrl,
	path,
	method,
	data,
	authorization,
	axiosConfig,
	fullResponse,
	onDownloadProgress = () => {}
}) {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Accept': '"/"',
		},
		...axiosConfig
	}

	if (authorization) {
		config.headers.authorization = authorization;
	}

	try {
		const res = await axios({
			baseURL: baseUrl,
			method: method,
			url: path,
			data: data,
			...config,
			onDownloadProgress: e => onDownloadProgress(e)
		});

		if (res?.data) {
			return fullResponse ? res : res.data;
		} else {
			return Error(res);
		}
	} catch (error) {
		// console.log('error', error);
		return error;
	}
}


function useExtendedApiRequest() {
	const getAuthHeader = useLoginState(state => state.getAuthHeader);

	/**
	 * useExtendedApiRequest
	 *
	 * @param {object} apiReqConfig
	 * @param {string} apiReqConfig.baseUrl Base url
	 * @param {string} apiReqConfig.path Url path
	 * @param {string} apiReqConfig.method Request method
	 * @param {any} apiReqConfig.data Request Paylod
	 * @param {boolean} apiReqConfig.fullResponse Get the full response or just data
	 */
	return (async ({
		baseUrl,
		path,
		method,
		data,
		config,
		fullResponse,
		onDownloadProgress = () => {}
	}) => {
		const authorization = await getAuthHeader();

		const apiReqConfig = {
			baseUrl: baseUrl || process.env.REACT_APP_API_BASE_URL,
			// baseUrl: baseUrl || `http://dev.api.volopa.com:4481/VolopaAPI/`,
			path,
			method: method || 'GET',
			data: data || null,
			axiosConfig: config || {},
			fullResponse: fullResponse || false,
			authorization: authorization || null,
			onDownloadProgress: onDownloadProgress
		}

		return await apiRequest(apiReqConfig);
	});
}

export { useExtendedApiRequest, apiRequest };
