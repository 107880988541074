import axios from "axios";
import { useLoginState } from "App/Pages/Login/store";
import { useNavigate } from "react-router";
import URLS from "Routes/constants";

// @todo handle responseType
async function apiRequest(path, method, data, authorization, signal, customHeaders, auxiliary) {
	const headers = {
		'Content-Type': 'application/json',
		'Accept': '"/"'
	}

	if (!!authorization && !customHeaders) {
		headers.authorization = authorization;
	}

	if (!!authorization && !!customHeaders) {
		customHeaders.authorization = authorization;
	}

	try {
		const res = await axios({
			method: method,
			url: (auxiliary ? process.env.REACT_APP_AUX_API_BASE_URL : process.env.REACT_APP_API_BASE_URL) + path,
			data: data,
			headers: customHeaders || headers,
			signal,
		})
		if (res && res.data) {
			return res.data;
		} else {
			throw Error('Invalid response');
		}
	} catch (error) {
		// console.log('error', error);
		return error;
	}
}

function useApiRequest() {
	const refreshToken = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY);
	const getAuthHeader = useLoginState(state => state.getAuthHeader);
	const navigate = useNavigate();

	/**
	 *
	 * @param {string} path the uri path wothout the api base url
	 * @param {'GET'|'POST'|'PUT'|'DELETE'} method http method
	 * @param {stirng|object|number} data post data payload
	 * @param {AbortSignal} signal
	 * @param {object} customHeaders replace default headers if needed
	 * @param {boolean} auxiliary use auxiliary api base url
	 * @returns {Promise}
	 */
	const makeApiRequest = async (path, method = 'GET', data, signal, customHeaders, auxiliary = false) => {
		if (!refreshToken) {
			navigate(URLS.Login);
			return;
		}

		try {
			const authorization = await getAuthHeader();
			const res = await apiRequest(path, method, data, authorization, signal, customHeaders, auxiliary);
			return res;
		} catch (error) {
			useLoginState.getState().clearAuth();
			navigate(URLS.Login);
			return Promise.reject(error);
		}
	}

	return makeApiRequest;
}

export { useApiRequest, apiRequest };
