import create from 'zustand';

const useManageSpecificCardState = create((set) => ({
    fullScreenModalOpen: false,
    passwd: '',
    modalOpen: false,
    modalContent: undefined,
    manageCard: null,
    modalActionBusy: false,
    disabledPaymentTypes: [],
    initDisabledPaymentTypes: [],
    txLimitPosAmount: '',
    txLimitAtmAmount: '',
    txLimitTypeId: null,
    initTxLimitData: null,
    activateLoading: false,
    returnToDashboardCardHeading: '',
    returnToDashboardCardDescription: '',
    returnToDashboardCardBold: '',
    setState: (state) => set(state)
}));

export { useManageSpecificCardState };
