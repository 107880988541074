import { Button, Card, Col, Form, Image, Input, InputNumber, notification, Row, Select, Space, Spin, Typography } from "antd";
import logo from 'Assets/Images/logo.png';
import { useCurrenciesQuery, usePrepaidcardBalanceQuery, usePrepaidCardQuery } from "Hooks/Queries";
import { useParams } from "react-router-dom";
import CardBalanceListing from "App/Components/CardBalanceListing";
import { useApiRequest } from "Hooks/API";
import { useCompanyWalletBreakdownQuery } from "Hooks/Queries";
import { useEffect, useState } from "react";
import { FLAGS } from "Constants/Images";
import PageDescription from "App/Components/PageDescription";
import CardDetails from "App/Components/CardDetails";
import FundWithdrawConfirmSuccessModal from "App/Components/FundWithdrawConfirmModal";
import { useFundWithdrawConfirmModalState } from "App/Components/FundWithdrawConfirmModal/store";
import CompanyWalletBalance from "App/Components/CompanyWalletBalance";
import { accountingFormat, numberFormat } from "Utils";

function NewCardFund() {

    const [form] = Form.useForm();
    const { cardId } = useParams();
    const apiRequest = useApiRequest();
    const { data: card, isLoading } = usePrepaidCardQuery(cardId);
    const { data: currenciesData } = useCurrenciesQuery();
    const { data: breakDownData, refetch: refetchWalletBreakdown } = useCompanyWalletBreakdownQuery();
    const [currencies, setCurrencies] = useState([]);
    const [commonCurrencies, setCommonCurrencies] = useState([]);
    const [processing, setProcessing] = useState(false);
    const setModalState = useFundWithdrawConfirmModalState(state => state.setState);
    const { refetch: refetchCardBreakdown } = usePrepaidcardBalanceQuery(cardId);

    useEffect(() => {
        if (currenciesData) {
            setCurrencies(currenciesData
                .sort((a, b) => a.currency.localeCompare(b.currency))
                .map((val, key) => {
                    val.key = key;
                    val.flag = FLAGS[val.currency];
                    val.label = val.title + ' (' + val.currency + ')';
                    val.name = val.currency;
                    val.value = val.currency;
                    return val;
                }));
            setCommonCurrencies(currenciesData
                .filter((data) => data.sort_order_buy !== 999)
                .sort((a, b) => a.sort_order_buy - b.sort_order_buy));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currenciesData])


    //@todo refactor to mutation
    const createTransferRequest = async () => {
        setProcessing((prev) => !prev)
        const data = {};
        data.prepaidCardId = breakDownData?.breakdown[0]?.companyCardId;
        data.toPrepaidCardId = cardId;
        data.transferCurrency = form.getFieldValue('currency');
        data.transferAmount = form.getFieldValue('amount');
        data.description = form.getFieldValue('description');
        const quoteResponse = await apiRequest("transfer/request", "POST", data);
        if (quoteResponse && quoteResponse.data) {
            const acceptData = {};
            acceptData.prepaidCardId = breakDownData?.breakdown[0]?.companyCardId;
            acceptData.transferTransferId = quoteResponse.data.transfer_id;
            const acceptResponse = await apiRequest("transfer/confirm", "POST", acceptData);
            if (acceptResponse && acceptResponse.data) {
                /* This is used to stop spinner */
                setProcessing((prev) => !prev)
                /* The above code is setting the state of a modal component to show a success message
                with details of a manual fund that has been processed. */
                setModalState({
                    showModal: true,
                    type: 'success',
                    title: 'Funding Complete',
                    message: 'Your card has now been funded',
                    currency: form.getFieldValue('currency'),
                    amount: form.getFieldValue('amount'),
                    description: form.getFieldValue('description'),
                    moreBtnTxt: 'New Fund'
                })
                form.resetFields()
            } else {
                /* This is used to stop spinner */
                setProcessing((prev) => !prev)
                notification.error({ message: acceptResponse?.response?.data?.error?.message, description: 'Amount exceeds your wallet balance in this currency', placement: 'top' });
                form.resetFields()
            }
        } else {
            /* This is used to stop spinner */
            setProcessing((prev) => !prev)
            form.resetFields()
            notification.error({ message: quoteResponse?.response?.data?.error?.message, description: 'Amount exceeds your wallet balance in this currency', placement: 'top' });
        }
        refetchCardBreakdown();
        refetchWalletBreakdown();
    };

    /**
     * The function sets the state of a modal to display a confirmation message with details of a
     * manual fund transfer request.
     */
    const onClickConfirm = () => {
        setModalState({
            showModal: true,
            type: 'confirm',
            title: 'Confirm card funding',
            message: 'Please confirm that you would like to make the following card funding transaction',
            onConfirm: createTransferRequest,
            currency: form.getFieldValue('currency'),
            amount: form.getFieldValue('amount'),
            description: form.getFieldValue('description')
        })
    }

    return (
        <>
            <Spin spinning={processing} size="large">
                <FundWithdrawConfirmSuccessModal />
                <Row>
                    <Col span={24}>
                        <PageDescription title='New Card Fund' text='- Fund Your Card From Your Company Wallet' />
                    </Col>
                </Row>
                <Row className='m-t-20' gutter={[12, 12]}>
                    <Col xxl={12} xl={10} lg={12} md={16} sm={20} xs={24}>
                        <Row>
                            <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <CardDetails
                                    cardHolderName={`${card?.card_holder?.title} ${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`}
                                    cardNumber={`**** **** **** ${card?.card_number}`}
                                    expiryDate={`${card?.expiry_month} ${card?.expiry_year}`}
                                    isLoading={isLoading}
                                    cardType={card?.card_program_id}
                                >
                                    <Row justify='center' className='m-t-40 m-b-40'>
                                        <Col>
                                            <Image src={logo} preview={false} width={180} />
                                        </Col>
                                    </Row>
                                </CardDetails>
                            </Col>
                        </Row>
                        <Row className='m-t-20'>
                            <Col span={24}>
                                <Typography.Text className='dark-green fs-25px medium'>Create New Card Fund</Typography.Text>
                                <Row>
                                    <Col xxl={10} xl={15} lg={15} md={15} sm={15} xs={24}>
                                        <Form layout='vertical' form={form}>
                                            <Row>
                                                <Col>
                                                    <Typography.Text className='dark-green muli semi-bold fs-18px'>Fund Card By Amount</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Row gutter={6} className='m-t-5'>
                                                <Col flex='90px'>
                                                    <Form.Item name='currency'>
                                                        <Select className='full-percent-width'>
                                                            {commonCurrencies && commonCurrencies.length > 0 && (
                                                                <Select.OptGroup label="Common">
                                                                    {commonCurrencies.map((val, key) => (
                                                                        <Select.Option value={val.currency} key={key} className='p-l-10'>
                                                                            <Space size={4} align="center">
                                                                                {val.flag && (
                                                                                    <Image
                                                                                        src={val.flag}
                                                                                        preview={false}
                                                                                        width={20}
                                                                                        alt={val.currency}
                                                                                    />
                                                                                )}
                                                                                {val.currency}
                                                                            </Space>
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select.OptGroup>
                                                            )}
                                                            {currencies && currencies.length > 0 && (
                                                                <Select.OptGroup label="Other">
                                                                    {currencies.map((val, key) => (
                                                                        <Select.Option
                                                                            key={
                                                                                key +
                                                                                (commonCurrencies
                                                                                    ? commonCurrencies.length
                                                                                    : 0)
                                                                            }
                                                                            value={val.currency}
                                                                            className='p-l-10'
                                                                        >
                                                                            <Space size={4} align="center">
                                                                                {val.flag && (
                                                                                    <Image
                                                                                        src={val.flag}
                                                                                        preview={false}
                                                                                        width={20}
                                                                                        alt={val.currency}
                                                                                    />
                                                                                )}
                                                                                {val.currency}
                                                                            </Space>
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select.OptGroup>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col flex='auto'>
                                                    <Form.Item name='amount' rules={[
                                                        { required: true, message: 'Please enter a amount' },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value !== 0) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Amount cannot be zero'));
                                                            }
                                                        }
                                                    ]}>
                                                        <InputNumber
                                                            className='b-g full-percent-width'
                                                            controls={false}
                                                            placeholder='Enter Funding Amount'
                                                            min={0}
                                                            formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                            parser={(val) => numberFormat(val)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Typography.Text className='dark-green muli semi-bold fs-18px'>Description</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-5'>
                                                <Col flex='auto'>
                                                    <Form.Item name='description'>
                                                        <Input className='b-g' placeholder='Enter Description' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Space wrap>
                                                <Button type='primary' onClick={() => form.resetFields()}>Reset</Button>
                                                <Button type='primary' onClick={() => onClickConfirm()} disabled={
                                                    !(form.getFieldValue('currency') &&
                                                        form.getFieldValue('amount') > 0 &&
                                                        form.getFieldValue('description'))
                                                }>Confirm</Button>
                                            </Space>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={12} xl={14} lg={16} md={20} sm={24} xs={24}>
                        <Row>
                            <Col span={24}>
                                <Card hoverable className="b-g hover-no-border">
                                    <Space direction="vertical" size={50} className='full-percent-width'>
                                        <div>
                                            <Typography.Text className="medium fs-18px dark-green">Card Balance</Typography.Text>
                                            <CardBalanceListing cardId={cardId} rowType="filled" />
                                        </div>
                                        <div>
                                            <Typography.Text className="medium fs-18px dark-green m-t-30">Company Wallet Balance</Typography.Text>
                                            <CompanyWalletBalance />
                                        </div>
                                    </Space>


                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </>
    );
}

export default NewCardFund;
