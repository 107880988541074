/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Space, Image, Row, Typography, Button } from "antd";
import { useCompanyWalletBalanceState } from "Hooks/Store";
import { FLAGS } from "Constants/Images";
import { useCompanyWalletBreakdownQuery } from "Hooks/Queries";
import CustomTable from "App/Components/CustomTable";
import { accountingFormat } from "Utils";

function CompanyWalletBalance() {
  const { isLoading } = useCompanyWalletBreakdownQuery();
  const setState = useCompanyWalletBalanceState(state => state.setState);
  const allVisible = useCompanyWalletBalanceState(state => state.allVisible);
  const balancesTotal = useCompanyWalletBalanceState(state => state.total);
  const visibleBalances = useCompanyWalletBalanceState(state => state.visibleBalances());
  const balances = useCompanyWalletBalanceState(state => state.balances);

  let walletCurrency = balances.filter((data) => data.rate === 1);
  //
  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (value, record) => (
        <Space size={3}>
          {record.flag && <Image src={FLAGS[record.flag]} preview={false} alt={value} width={45} />}
          {value}
        </Space>
      )
    },
    {
      title: 'Available to Spend',
      dataIndex: 'availableToSpend',
      key: 'availableToSpend',
      render: (value, record) => (
        <Space size={3}>
          {accountingFormat(value)}
        </Space>
      )
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate'
    },
    {
      title: walletCurrency[0]?.currency + ' Value',
      dataIndex: 'GBPValue',
      key: 'GBPValue',
      render: (value, record) => (
        <Space size={3}>
          {accountingFormat(value)}
        </Space>
      )
    },
  ];

  const toggleShowAll = () => {
    setState({ allVisible: !allVisible });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={visibleBalances}
            headerColor="green"
            headerFont="muli"
            rowType="filled"
            loading={isLoading}
          />
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col span={24}>
          <>
            <Typography.Text className="muli semi-bold fs-18px dark-green">Total {walletCurrency[0]?.currency} Value: {accountingFormat(balancesTotal)}</Typography.Text>
            <Button type="link" className="medium fs-18px right-align-text" onClick={toggleShowAll}>View {allVisible ? 'Less' : 'All'} Currencies</Button>
          </>
        </Col>
      </Row>
    </>
  );
}

export default CompanyWalletBalance;
