/* eslint-disable react-hooks/exhaustive-deps */
import {
    Row, Col, Typography,
    Card, Form, Select,
    Space, Progress, Button,
    Spin, InputNumber, Image,
    notification, Input, Modal,
} from "antd";
import { useEffect } from "react";
import { useApiRequest } from "Hooks/API";
import { useSelectSearch } from "Hooks/Search";
import { useRateCheckerState } from "Hooks/Store";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useConvertBalancesState } from "App/Pages/Wallet/ConvertBalances/store";
import { FLAGS } from "Constants/Images";
import { accountingFormat, numberFormat } from "Utils";
import { useCompanyWalletBreakdownQuery, usePrepaidcardBalanceQuery } from "Hooks/Queries";

function RateCheckerRefactor({
    clear,
    currencies,
    redirectUrl,
    navigateModalBtnLabel,
    modalBtnLabel = 'Close',
    timeout: timeoutProp = 30,
    cardId = null,
    loading = false,
}) {
    const fromCurrencies = useRateCheckerState((state) => state.fromCurrencies);
    const toCurrencies = useRateCheckerState((state) => state.toCurrencies);
    const commonFromCurrencies = useRateCheckerState((state) => state.commonFromCurrencies);
    const commonToCurrencies = useRateCheckerState((state) => state.commonToCurrencies);
    const rate = useRateCheckerState((state) => state.rate);
    const loadingRate = useRateCheckerState((state) => state.loadingRate);
    const loadingConvert = useRateCheckerState((state) => state.loadingConvert);
    const side = useRateCheckerState((state) => state.side);
    const rateUid = useRateCheckerState((state) => state.rateUid);
    const seconds = useRateCheckerState((state) => state.seconds);
    const selectedFromCurrency = useRateCheckerState((state) => state.selectedFromCurrency);
    const selectedToCurrency = useRateCheckerState((state) => state.selectedToCurrency);
    const toValue = useRateCheckerState((state) => state.toValue);
    const fromValue = useRateCheckerState((state) => state.fromValue);
    const rateDone = useRateCheckerState((state) => state.rateDone);
    const setState = useRateCheckerState((state) => state.setState);

    const setConvertBalancesState = useConvertBalancesState((state) => state.setState);
    const modalOpen = useConvertBalancesState((state) => state.modalOpen);
    const message = useConvertBalancesState((state) => state.message);
    const { refetch: refetchCardBalance } = usePrepaidcardBalanceQuery(cardId);
    const { refetch: refetchWalletBalance } = useCompanyWalletBreakdownQuery();


    const navigate = useNavigate();
    const apiRequest = useApiRequest();
    const filterSelect = useSelectSearch();
    const [form] = Form.useForm();
    const timeout = useRef();

    //
    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
            setConvertBalancesState({ modalOpen: false, message: undefined });
            setState({
                selectedFromCurrency: null,
                selectedToCurrency: null,
                toValue: null,
                fromValue: null,
            });
        };
        // eslint-disable-next-line
    }, []);

    //
    useEffect(() => {
        if (clear) {
            setState({
                selectedFromCurrency: null,
                selectedToCurrency: null,
                toValue: null,
                fromValue: null
            });

            form.resetFields();
        } else {
            form.setFieldsValue({ convertToValue: toValue });
            form.setFieldsValue({ convertFromValue: fromValue });
            form.setFieldsValue({ convertToCurrency: selectedToCurrency });
            form.setFieldsValue({ convertFromCurrency: selectedFromCurrency });
            if (
                selectedToCurrency === null &&
                selectedFromCurrency === null
            ) {
                setState({ rate: null });
                form.resetFields();
            }
        }
    }, [clear]);

    //
    useEffect(() => {
        // @todo Should we filter card currecnies with no amount ?
        if (currencies) {
            let commonFrom = null;
            let commonTo = null;
            let otherFrom = null;
            let otherTo = null;

            commonFrom = currencies
                .filter((data) => data.sort_order_sell !== 999)
                .sort((a, b) => a.sort_order_sell - b.sort_order_sell);

            otherFrom = currencies.filter((data) => data.sort_order_sell === 999);

            commonTo = currencies
                .filter((data) => data.sort_order_buy !== 999)
                .sort((a, b) => a.sort_order_buy - b.sort_order_buy);

            otherTo = currencies.filter((data) => data.sort_order_buy === 999);

            setState({
                fromCurrencies: otherFrom,
                toCurrencies: otherTo,
                commonFromCurrencies: commonFrom,
                commonToCurrencies: commonTo,
            });
        }
    }, [currencies]);

    //
    useEffect(() => {
        if (seconds > 0) {
            timeout.current = setTimeout(() => setState({ seconds: seconds - 1 }), 1000);
        }

        if (!redirectUrl && seconds === 0) {
            getExchangeRate();
        }
    }, [seconds]);

    //
    useEffect(() => {
        calculateRate("from");
    }, [rate]);


    //
    const onToCurrencyChange = (value) => {
        let cur = currencies
            .filter((data) => data.sort_order_sell === 999)
            .filter((val) => val.currency !== value);

        let commonCur = currencies
            .filter((data) => data.sort_order_sell !== 999)
            .sort((a, b) => a.sort_order_sell - b.sort_order_sell)
            .filter((val) => val.currency !== value);

        setState({
            rate: null,
            fromCurrencies: cur,
            commonFromCurrencies: commonCur,
            selectedToCurrency: form.getFieldValue("convertToCurrency"),
        });

        if (
            form.getFieldValue("convertFromCurrency") &&
            form.getFieldValue("convertToCurrency")
        ) {
            getExchangeRate();
        }
    };

    //
    const onFromCurrencyChange = (value) => {
        let cur = currencies
            .filter((data) => data.sort_order_buy === 999)
            .filter((val) => val.currency !== value);

        let commonCur = currencies
            .filter((data) => data.sort_order_buy !== 999)
            .sort((a, b) => a.sort_order_buy - b.sort_order_buy)
            .filter((val) => val.currency !== value);

        setState({
            rate: null,
            toCurrencies: cur,
            commonToCurrencies: commonCur,
            selectedFromCurrency: form.getFieldValue("convertFromCurrency"),
        });
        if (
            form.getFieldValue("convertToCurrency") &&
            form.getFieldValue("convertFromCurrency")
        ) {
            getExchangeRate();
        }
    };

    //
    const getExchangeRate = async () => {
        const from = form.getFieldValue("convertFromCurrency");
        const to = form.getFieldValue("convertToCurrency");

        clearTimeout(timeout.current);

        setState({
            loadingRate: true,
            rate: null,
            seconds: 0,
        });

        const res = await apiRequest("fxrate/conversion/current?currencyPair=" + from + to);

        if (res && res.data && res.data.length > 0) {
            setState({
                rate: res.data[0].rate,
                rateUid: res.data[0].uid,
                seconds: timeoutProp ? timeoutProp : 30,
                loadingRate: false,
            });
        }
    };

    //
    const calculateRate = (type) => {
        if (
            form.getFieldValue("convertToCurrency") &&
            form.getFieldValue("convertFromCurrency") &&
            !loadingRate &&
            seconds > 0 &&
            rate
        ) {
            const from = numberFormat(form.getFieldValue("convertFromValue"));
            const to = numberFormat(form.getFieldValue("convertToValue"));
            if (type === "from") {
                if (form.getFieldValue("convertFromValue")) {
                    form.setFieldsValue({
                        convertToValue: (from * rate) && accountingFormat(from * rate),
                    });
                } else {
                    //form.setFieldsValue({ convertToValue: null });
                }

                setState({
                    side: "sell",
                });
            } else {
                if (form.getFieldValue("convertToValue")) {
                    form.setFieldsValue({
                        convertFromValue: (to / rate) && accountingFormat(to / rate),
                    });
                } else {
                    //form.setFieldsValue({ convertFromValue: null });
                }

                setState({
                    side: "buy",
                });
            }

            setState({
                fromValue: numberFormat(form.getFieldValue("convertFromValue")),
                toValue: numberFormat(form.getFieldValue("convertToValue")),
            });
        }
    };

    //
    const createQuote = async () => {
        setState({ loadingConvert: true, rateDone: rate });

        const quoteResponse = await apiRequest("exchange/quote", "POST", {
            prepaidCardId: cardId,
            ccySell: selectedFromCurrency,
            ccyBuy: selectedToCurrency,
            dealSide: side,
            dealAmount: side === "buy" ? parseFloat(Number(toValue).toFixed(4)) : parseFloat(Number(fromValue).toFixed(4)),
            rateUid: rateUid,
        });

        if (quoteResponse.response && quoteResponse.response.data.error) {
            if (quoteResponse.response.status === 400) {
                setConvertBalancesState({ message: quoteResponse.response.data.error.message });
                setConvertBalancesState({ modalOpen: true });
            } else {
                notification.error({ message: 'Error Code: ' + quoteResponse.response.data.error.error_number, description: quoteResponse.response.data.error.message, placement: 'top' });
            }
        }
        if (quoteResponse.data && quoteResponse.data.success === true) {
            setConvertBalancesState({ message: 'success' });
            setConvertBalancesState({ modalOpen: true });
        }

        setState({ loadingConvert: false });
        form.resetFields();
        refetchCardBalance();
        refetchWalletBalance();
    };

    //
    const handleOnFinish = () => {
        redirectUrl ? navigate(redirectUrl) : createQuote();
    };

    //
    const handleCloseConversionModal = () => {
        setConvertBalancesState({ modalOpen: false, message: undefined });
        let commonFrom = null;
        let commonTo = null;
        let otherFrom = null;
        let otherTo = null;

        commonFrom = currencies
            .filter((data) => data.sort_order_sell !== 999)
            .sort((a, b) => a.sort_order_sell - b.sort_order_sell);

        otherFrom = currencies.filter((data) => data.sort_order_sell === 999);

        commonTo = currencies
            .filter((data) => data.sort_order_buy !== 999)
            .sort((a, b) => a.sort_order_buy - b.sort_order_buy);

        otherTo = currencies.filter((data) => data.sort_order_buy === 999);
        setState({
            timeout: null,
            rate: null,
            rateUid: null,
            loadingRate: false,
            loadingConvert: false,
            seconds: null,
            selectedFromCurrency: null,
            selectedToCurrency: null,
            toValue: null,
            fromValue: null,
            side: null,
            fromCurrencies: otherFrom,
            toCurrencies: otherTo,
            commonFromCurrencies: commonFrom,
            commonToCurrencies: commonTo,
        });
        form.resetFields();
        clearTimeout(timeout.current);
    }

    //
    return (
        <>
            <Modal
                destroyOnClose
                open={modalOpen}
                footer={false}
                closable={false}
                className="b-g"
            >
                <Row justify="center">
                    <Col>
                        {message === 'success' &&
                            <Typography.Text type="success" className="fs-18px medium">
                                Conversion Complete
                            </Typography.Text>}

                        {(message !== 'success' && message !== undefined) &&
                            <Typography.Text type="danger" className="fs-18px medium">
                                {message}
                            </Typography.Text>}

                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Typography.Text className="muli fs-15px semi-bold light-green">
                            Fx Rate 1 {selectedFromCurrency} = {rateDone} {selectedToCurrency}
                        </Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col flex="auto">
                        <Row>
                            <Col>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">
                                    Converted To
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col flex='90px'>
                                <Input
                                    readOnly
                                    value={selectedToCurrency}
                                    prefix={
                                        <Image
                                            src={FLAGS[selectedToCurrency]}
                                            preview={false}
                                            alt={selectedToCurrency}
                                            width={20}
                                        />
                                    }
                                    className="default-cursor"
                                />
                            </Col>
                            <Col span={18}>
                                <Input readOnly value={toValue} className="default-cursor" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-20">
                    <Col flex="auto">
                        <Row>
                            <Col>
                                <Typography.Text className="muli semi-bold fs-18px dark-green">
                                    Converted From
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            <Col flex='90px'>
                                <Input
                                    readOnly
                                    value={selectedFromCurrency}
                                    prefix={
                                        <Image
                                            src={FLAGS[selectedFromCurrency]}
                                            preview={false}
                                            alt={selectedFromCurrency}
                                            width={20}
                                        />
                                    }
                                    className="default-cursor"
                                />
                            </Col>
                            <Col span={18}>
                                <Input readOnly value={fromValue} className="default-cursor" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-t-40" justify="space-around" gutter={[8, 8]}>
                    <Col>
                        <Space size={96}>
                            {!!redirectUrl && <Button type="primary" onClick={() => navigate(redirectUrl)}>
                                {navigateModalBtnLabel}
                            </Button>}

                            <Button type="primary" onClick={handleCloseConversionModal}>
                                {modalBtnLabel}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Modal>

            <Spin size="large" spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Card>
                            <Card.Grid className="full-percent-width rounded b-g hover-no-border">
                                <Form layout="vertical" form={form} onFinish={handleOnFinish}>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">
                                        Convert To
                                    </Typography.Text>
                                    <Row gutter={8} className="m-t-5">
                                        <Col flex='90px'>
                                            <Form.Item name="convertToCurrency">
                                                <Select
                                                    className="dark-green"
                                                    onChange={onToCurrencyChange}
                                                    showSearch
                                                    allowClear
                                                    filterOption={filterSelect}
                                                >
                                                    {commonToCurrencies && commonToCurrencies.length > 0 && (
                                                        <Select.OptGroup label="Common">
                                                            {commonToCurrencies.map((val, key) => (
                                                                <Select.Option value={val.currency} key={key} className='p-l-10'>
                                                                    <Space size={4} align="center">
                                                                        {val.flag && (
                                                                            <Image
                                                                                src={val.flag}
                                                                                preview={false}
                                                                                width={20}
                                                                                alt={val.currency}
                                                                            />
                                                                        )}
                                                                        {val.currency}
                                                                    </Space>
                                                                </Select.Option>
                                                            ))}
                                                        </Select.OptGroup>
                                                    )}
                                                    {toCurrencies && toCurrencies.length > 0 && (
                                                        <Select.OptGroup label="Other">
                                                            {toCurrencies.map((val, key) => (
                                                                <Select.Option
                                                                    key={
                                                                        key +
                                                                        (commonToCurrencies
                                                                            ? commonToCurrencies.length
                                                                            : 0)
                                                                    }
                                                                    value={val.currency}
                                                                    className='p-l-10'
                                                                >
                                                                    <Space size={4} align="center">
                                                                        {val.flag && (
                                                                            <Image
                                                                                src={val.flag}
                                                                                preview={false}
                                                                                width={20}
                                                                                alt={val.currency}
                                                                            />
                                                                        )}
                                                                        {val.currency}
                                                                    </Space>
                                                                </Select.Option>
                                                            ))}
                                                        </Select.OptGroup>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col flex='auto'>
                                            <Form.Item name="convertToValue">
                                                <InputNumber
                                                    className="full-percent-width"
                                                    placeholder="Enter Amount"
                                                    onChange={() => calculateRate("to")}
                                                    controls={false}
                                                    formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                    parser={(val) => numberFormat(val)}
                                                    disabled={!form.getFieldValue('convertToCurrency') || !form.getFieldValue('convertFromCurrency')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Typography.Text className="muli semi-bold fs-18px dark-green">
                                        Convert From
                                    </Typography.Text>
                                    <Row gutter={8} className="m-t-5">
                                        <Col flex='90px'>
                                            <Form.Item name="convertFromCurrency">
                                                <Select
                                                    className="dark-green"
                                                    onChange={onFromCurrencyChange}
                                                    showSearch
                                                    allowClear
                                                    filterOption={filterSelect}
                                                >
                                                    {commonFromCurrencies &&
                                                        commonFromCurrencies.length > 0 && (
                                                            <Select.OptGroup label="Common">
                                                                {commonFromCurrencies.map((val, key) => (
                                                                    <Select.Option
                                                                        value={val.currency}
                                                                        key={key}
                                                                        className='p-l-10'
                                                                    >
                                                                        <Space size={4} align="center">
                                                                            {val.flag && (
                                                                                <Image
                                                                                    src={val.flag}
                                                                                    preview={false}
                                                                                    width={20}
                                                                                    alt={val.currency}
                                                                                />
                                                                            )}
                                                                            {val.currency}
                                                                        </Space>
                                                                    </Select.Option>
                                                                ))}
                                                            </Select.OptGroup>
                                                        )}
                                                    {fromCurrencies && fromCurrencies.length > 0 && (
                                                        <Select.OptGroup label="Other">
                                                            {fromCurrencies.map((val, key) => (
                                                                <Select.Option
                                                                    key={
                                                                        key +
                                                                        (commonFromCurrencies
                                                                            ? commonFromCurrencies.length
                                                                            : 0)
                                                                    }
                                                                    value={val.currency}
                                                                    className='p-l-10'
                                                                >
                                                                    <Space size={4} align="center">
                                                                        {val.flag && (
                                                                            <Image
                                                                                src={val.flag}
                                                                                preview={false}
                                                                                width={20}
                                                                                alt={val.currency}
                                                                            />
                                                                        )}
                                                                        {val.currency}
                                                                    </Space>
                                                                </Select.Option>
                                                            ))}
                                                        </Select.OptGroup>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col flex='auto'>
                                            <Form.Item name="convertFromValue">
                                                <InputNumber
                                                    className="full-percent-width"
                                                    placeholder="Enter Amount"
                                                    onChange={() => calculateRate("from")}
                                                    controls={false}
                                                    disabled={!form.getFieldValue('convertToCurrency') || !form.getFieldValue('convertFromCurrency')}
                                                    formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                    parser={(val) => numberFormat(val)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row align="bottom">
                                        <Col span={12}>
                                            <Spin spinning={loadingRate} />
                                            {rate !== null && (
                                                <Space>
                                                    <Progress
                                                        type="circle"
                                                        percent={(seconds / (+timeoutProp + 0.01 || 30)) * 100}
                                                        width={40}
                                                        format={() => `${seconds}s`}
                                                    />
                                                    <Space direction="vertical" size={0}>
                                                        {seconds > 0 ? (
                                                            <>
                                                                <Typography.Text className="muli semi-bold light-green">
                                                                    FX Rate
                                                                </Typography.Text>
                                                                <Typography.Text className="muli semi-bold light-green">
                                                                    1 {form.getFieldValue("convertFromCurrency")} ={" "}
                                                                    {rate} {form.getFieldValue("convertToCurrency")}
                                                                </Typography.Text>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography.Text
                                                                    className="muli semi-bold light-green pointer"
                                                                    onClick={() => getExchangeRate()}
                                                                >
                                                                    Refresh FX Rate
                                                                </Typography.Text>
                                                            </>
                                                        )}
                                                    </Space>
                                                </Space>
                                            )}
                                        </Col>
                                        <Col span={12} className="right-align-text">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={loadingConvert || !cardId}
                                                disabled={!fromValue || !toValue || !form.getFieldValue("convertToCurrency") || !form.getFieldValue("convertFromCurrency")}
                                            >
                                                Convert
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Grid>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </>
    );
}

export default RateCheckerRefactor;
